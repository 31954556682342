/* TODO: either remove global colors at all or update all used colors to use them */

:root {
  font-size: 14px;
  --main-text-color: rgba(0, 0, 0, 0.6);
  --main-text-hover: rgba(0, 0, 0, 1);
  --main-text-active: rgba(255, 255, 255, 1);
  --main-text-active-background: rgba(37, 34, 49, 0.9);
  --label-text-color: rgba(51, 51, 51, 0.87);
  --hightlight-text-color: #363b3d;
  --info-text-color: #939ea8;
  --additional-info-color: #566879;
  --cta-color: #007db5;
  --cta-color-active: #00aff5;
  --icon-color: #4281d1;
  --link-color: #007db5;
  --button-color: #2d74cc;
  --button-color-light: rgba(0, 0, 0, 0);
  --button-color-light-hover: #eeeeee;
  --border-color: #e9eaec;
  --input-border-color: #e6e6e6;
  --select-color: #56697a;
  --popover-text-color: #5d687a;
  --background-dark-color: #fcfcfc;
  --background-light-color: #ffffff;

  --rangepicker-weekday-caption: #333333;
  --rangepicker-cell-end: #4f02a4;
  --rangepicker-cell-end-text: rgba(255, 255, 255, 0.8);
  --rangepicker-cell-span: #a593c8;
  --rangepicker-cell-disabled: #eeeeee;
  --rangepicker-cell-disabled-text: #b2b2b2;
}

html:focus-within {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  color: var(--main-text-color);
  margin: 0;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.27px;
}

h1 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 96px;
  line-height: 96px;
  font-weight: 300;
  text-align: left;
  letter-spacing: -1.5px;
}

h2 {
  margin-top: 16px;
  margin-bottom: 32px;
  opacity: 1;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 60px;
  line-height: 56px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

h3 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 48px;
  line-height: 40px;
  font-weight: 400;
}

h4 {
  max-width: 770px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(73, 73, 73, 0.87);
  font-size: 34px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

h5 {
  margin-top: 8px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(73, 73, 73, 0.87);
  font-size: 24px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -1px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  font-family: Roboto, sans-serif;
  color: #494949;
  font-size: 20px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-transform: none;
}

p {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 28px;
}

a {
  display: inline;
  color: #007db5;
  line-height: 24px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-left: 36px;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}

blockquote {
  margin-bottom: 16px;
  padding: 16px;
  border-left: 4px solid #00aff5;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.modal-open .modal {
  padding: 0 !important;
}

.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button {
  border: 0;
}

.header_area .navbar-brand {
  margin-right: 0 !important;
}

.material_select_hiddenSelect__2Agso {
  display: none !important;
}
.material_select_hiddenSelect__1wQ__ {
  display: none !important;
}

.material_select_hiddenSelect__1wQ__ {
  display: none !important;
}
.br-50 {
  border-radius: 50px !important;
}

@media (max-width: 991px) {
  .header_area .navbar {
    background: transparent !important;
  }

  .navbar-toggler {
    background: transparent !important;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 64px;
    line-height: 60px;
    font-weight: 400;
  }
  h2 {
    font-size: 52px;
    line-height: 30px;
  }
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
  h4 {
    font-size: 28px;
    line-height: 28px;
  }
}

@media screen and (max-width: 600px) {
  /* .CalendarMonthGrid */
  /* .CalendarMonthGrid_1 */
  /* .CalendarMonthGrid__horizontal  */
  .DayPicker_portal__horizontal_3,
  .DayPicker__withBorder_24 {
    margin-top: -300px !important;
    margin-left: -160px !important;
  }

  .DayPicker_portal__horizontal_3 div:first-child {
    /* margin-top: -350px !important; */
    /* margin-left: -150px !important; */
    width: 310px !important;
    padding-right: 50px;
  }

  /* .DayPicker_weekHeaders */
  .DayPicker_weekHeaders__horizontal_2 div:nth-child(2) {
    margin-left: 500px !important;
  }

  /* .DayPicker_portal__horizontal_3 div:first-child */

  .DayPicker_transitionContainer__horizontal_2 {
    width: 300px !important;
    height: 800px !important;
  }

  .DayPicker_transitionContainer__horizontal_2 div {
    width: 300px !important;
  }

  .CalendarMonthGrid__horizontal_2 {
    display: flex !important;
    flex-direction: column !important;
  }
}
