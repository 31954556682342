.checkbox {
  display: none !important;
}

.checkboxLabel {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
  margin-bottom: 0;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkIcon {
  font-size: 16px;
}

.group {
  display: flex;
  margin: 0;
}

.label {
  margin-bottom: 0;
}
