.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.wrapper :global .DateRangePicker_picker {
  z-index: 999;
}

.form_wrapper {
  /* width: px; */
}

.mob_ver {
  margin: auto;
  text-align: center;
  cursor: pointer;
}

.filter {
  margin: 10px;
}

.searchSection {
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  width: 400px;
  margin: auto;
  border-color: var(--border-color);
}

.search_form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.toShow {
  display: flex;
  flex-direction: row;
}

.inner {
  display: flex;
  flex-direction: row;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.wrapper :global .DateRangePickerInput {
  height: 100%;
  border: 0;
  border-radius: 28px 0 0 28px;
  overflow: hidden;
}

.wrapper :global .DateRangePicker {
  height: 100%;
}

.wrapper :global .DateRangePicker > div {
  height: 100%;
}

.wrapper :global .DateInput_input {
  /* height: 54px; */
  padding: 11px 24px 9px;
}

.wrapper :global .DateInput_input__focused {
  border-color: transparent;
}

.rangePicker {
  margin-bottom: 0;
  height: 100%;
}

.wrapper .rangePicker__error :global .DateInput_input::placeholder {
  color: #ff4d4f;
}

.occupancyDropDown {
  margin-bottom: 0;
}

.occupancyDropDown :global .dropdown {
  height: 100%;
}

.occupancyDropDown :global .dropdown-toggle {
  height: 100%;
  border: none;
  border-radius: 0;
  padding-right: 16px;
}

.occupancyDropDown :global .btn-primary:not(:disabled):not(.disabled):focus {
  border: none;
}

.occupancyDropDown :global .btn-primary.dropdown-toggle {
  width: 195px;
}

.occupancyDropDown :global .show > .btn-primary.dropdown-toggle {
  border: none;
}

.occupancyDropDown :global .dropdown-menu.show {
  width: 305px;
  max-height: 535px;
  overflow: auto;
  z-index: 999;
}

.searchBtn:global.btn-primary:hover,
.searchBtn:global.btn-primary:focus,
.searchBtn:global.btn-primary:active {
  transition: all 200ms ease;
  background: rgb(0 190 10 / 90%);
}

.searchBtn:global.btn-primary:not(:disabled):not(.disabled):active {
  background: rgb(0 190 10 / 90%);
}

.buttonStyle {
  display: none;
  color: white;
  border: none;
  border-radius: 20px;
  background-color: #00d100;
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
}

.drop {
  display: none;
}

.text_color {
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

.name {
  display: flex;
}

.logo {
  display: none;
}

.logo_size {
  height: 45px;
  width: 150px;
}

@media screen and (max-width: 1060px) {
  .inner {
    display: none;
  }

  .drop {
    display: block;
  }

  .occupancyDropDown :global .dropdown-toggle {
    border: 1px solid;
    border-color: gray;
    padding: 10px;
  }
}

@media (max-width: 960px) {
  .toShow {
    display: none;
  }

  .buttonStyle {
    display: block;
  }
}

@media screen and (max-width: 570px) {
  .inner {
    display: none;
  }

  .logo {
    display: flex;
  }

  .name {
    display: none;
  }
}
