@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
.navbar_model__1gEFO {
  display: none;
}

.navbar_name_show__2HExY {
  display: flex;
  margin-bottom: 15px;
}

.navbar_logo_show__35Rtm {
  display: none !important;
}

.navbar_text_color__1SjJY {
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

.navbar_btn_link__LXz8h {
  color: #fff !important;
  font-size: 1.2rem !important;
}

.navbar_btn_active__1KV7Z {
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  border-width: 0.1rem !important;
  border-color: #fff !important;
}

.navbar_btn_link__LXz8h:hover {
  background-color: #dcdcdc !important;
  color: #333 !important;
}

.navbar_navbar_height__Yl18c {
  height: 80px !important;
}

.navbar_helping_logo__1b494 {
  width: 160px;
  height: 25px;
}

.navbar_helping_logo_2__EUXVo {
  width: 190px;
  height: 50px;
}

.navbar_name_margin__1X-aF {
  margin-bottom: 0;
}

@media screen and (max-width: 1070px) {
  .navbar_nav_margin__lM72k {
    margin-right: -50%;
  }
}

@media screen and (max-width: 960px) {
  .navbar_model__1gEFO {
    display: block !important;
    padding-right: 16px;
    width: 100%;
  }

  .navbar_navbar_height__Yl18c {
    height: 60px !important;
  }

  .navbar_logo_padding__168KD {
    padding-bottom: 10px !important;
  }

  .navbar_name_margin__1X-aF {
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .navbar_show__3bkP1 {
    display: none !important;
  }

  .navbar_model__1gEFO {
    display: block !important;
    padding-right: 16px;
    width: 100%;
  }

  .navbar_back__vDGko {
    background-color: #fff;
    height: 100%;
  }
}

.navbar_navbar_container__3xs94 {
  width: 100%;
  background-color: #fff;
  margin: 0%;
}

@media screen and (max-width: 480px) {
  .navbar_name_show__2HExY {
    display: none !important;
  }

  .navbar_logo_show__35Rtm {
    display: block !important;
    margin-bottom: 20px;
  }

  .navbar_helping_logo__1b494 {
    width: 150px;
    height: 25px;
  }

  .navbar_helping_logo_2__EUXVo {
    width: 170px;
    height: 50px;
  }
}

.accordion {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.3em;
}

.heading {
  display: flex;
  justify-content: center;
}

.card-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.head_color {
  color: #fff;
}

.Faq {
  background-color: #fff;
  /* padding-top: 2em;
  padding-bottom: 2em; */
}

.card {
  border-radius: 0px !important;
}
.footer_fa-globe__2jFFI {
  color: black;
}

u {
  color: black;
}

.footer_text_color__3tBGK {
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

.footer_logo__36yhs {
  height: 25px;
  width: 150px;
}

@media screen and (max-width: 500px) {
  .footer_logo__36yhs {
    height: 25px;
  }
}


.select_dropdown_menu__3SgOu.dropdown-menu {
  max-height: 400px;
  display: flex;
  flex-direction: column;
  min-width: 100%;
  padding: 10px;
}

.select_dropdown_menu__3SgOu {
  background-color: #fff;
  display: none !important;
  z-index: 6;
  margin-top: 2px;
  margin-left: 0px;
  padding: 16px;
  align-self: flex-start;
  border-radius: 2px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.14);
}

.select_dropdown_menu__3SgOu.show {
  display: flex !important;
}

.select_dropdown_srollableOptions__31Tcg {
  height: 100%;
  overflow: auto;
}

.select_dropdown_searchInput__2mHSX {
  margin-bottom: 8px;
  background-color: white;
  color: var(--main-text-color);
  box-shadow: none;
  display: flex;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--input-border-color);
  border-radius: 2px;
  
}

.select_dropdown_searchInput__2mHSX:focus {
  outline: none;
  box-shadow: none;
}

.select_dropdown_menuItem__1GmD_ {
  background-color: #fff;
  padding: 4px 10px;
}

.select_dropdown_menuItem__1GmD_.active {
  background-color: var(--main-text-active-background);
  color: var(--main-text-active);
}

.select_dropdown_menuItem__1GmD_:hover {
  text-decoration: underline;
  background-color: #f4f4f5;
  color: var(--main-text-hover);
}

.select_toggle__HCNrW {
  z-index: 1;
  display: flex;
  height: 40px;
  padding: 0px 32px 0px 15px;
  align-items: center;
  color: var(--main-text-color);
}

.select_toggle__HCNrW:hover {
  color: var(--main-text-hover);
}

.select_toggle__HCNrW.btn:focus {
  box-shadow: none;
}

.select_dropdown__2kuKD .dropdown-menu {
  z-index: 999;
}
.select_separator_separator__30uKL {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
  line-height: 24px;
  padding: 3px 10px;
  color: var(--select-color);
  border-bottom: 2px solid var(--border-color);
}
.faq_page_footer__4XJ1K {
    margin-top: 100px !important;
}

.faq_page_slider_small__yKyDA {
    /* height: -50%; */
}

.faq_page_innerimage__uMrmj {
    width: 100%;
    height: 400px;
}

.faq_page_fag_container__EpSoO {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
 
 .faq_page_margin__3FxoD {
    margin-top: -10%;
} 
.slider_banner_margin_bott__2vE-q {
    padding-bottom: 0%;
}

@media screen and (max-width: 750px) {
    .slider_banner_margin_bott__2vE-q {
        margin-bottom: 5px;
    }

}
.button_button__oLLve {
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  /* background-color: var(--cta-color); */
  background-color: #4f02a4;
  color: #fff;
  transition: all 200ms ease;
  border: none;
  letter-spacing: 1.17px;
}

.button_disable_class__1QmKx {
  cursor: not-allowed;
}

.button_button__oLLve.btn-primary:active,
.button_button__oLLve.btn-primary:hover,
.button_button__oLLve.btn-primary:not(:disabled):not(.disabled):active {
  /* background-color: var(--cta-color-active); */
  background-color: #a593c8;
  outline: 0;
  color: #fff;
  box-shadow: none;
}

.button_button__oLLve.btn-primary:focus {
  box-shadow: none;
}

.button_button__oLLve.btn-primary:disabled {
  /* background-color: var(--cta-color-active);  */
  background-color: #00d100;
  opacity: 1;
}

.button_buttonCaption__3iyBZ {
  margin-right: 4px;
}

@media screen and (max-width: 991px) {
  .button_button__oLLve {
    height: 40px;
  }
}

.button_spinner__356hc {
  width: 14px;
  height: 14px;
  margin-left: -18px;
  margin-right: 4px;
}

.label_label__21lcP {
  opacity: 0.75;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--label-text-color);
}

.dropdown_dropdownMenu__QCQo2 {
  z-index: 2;
  width: 100%;
  padding: 24px !important;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

.dropdown_dropdownToggle__3tBCx {
  text-align: left !important;
  display: flex;
  width: 100%;
  height: 48px;
  padding-right: 8px !important;
  padding-left: 8px !important;
  justify-content: space-between;
  /* align-items: center; */
  border: 1px solid var(--input-border-color);
  border-radius: 2px;
  transition: all 200ms ease;
  background-color: white !important;
  color: var(--main-text-color) !important;
  font-size: 1rem;
}

.dropdown_dropdownToggle__3tBCx:hover,
.dropdown_dropdownToggle__3tBCx:focus {
  background-color: #fff;
  color: var(--main-text-color) !important;
  /* color: #000 !important; */
  outline: none;
  box-shadow: none;
}

/* .dropdownToggle:hover {
  color: #4F02A4;
} */

.dropdown_dropdown__2Z72V > .btn-primary.dropdown-toggle,
.dropdown_dropdownToggle__3tBCx.btn-primary:not(:disabled):not(.disabled):focus,
.dropdown_dropdownToggle__3tBCx.btn-primary:not(:disabled):not(.disabled):active {
  background-color: white;
  color: var(--main-text-color) !important;
  box-shadow: none;
  outline: none;
  border-color: #dbdbdb;
}

.dropdown_dropdown__2Z72V.show > .btn-primary.dropdown-toggle,
.dropdown_dropdownToggle__3tBCx.btn-primary:not(:disabled):not(.disabled):focus {
  border-bottom: 2px solid #008489;
  margin-bottom: -1px;
}

.dropdown_containerHorizontal__KSGXu,
.dropdown_containerVertical__3e9C9 {
  display: flex;
  margin-bottom: 8px;
}

.dropdown_containerVertical__3e9C9 {
  flex-direction: column;
}

.dropdown_label__1x44k {
  padding-right: 0.5rem;
  color: var(--info-text-color);
}

.dropdown_dropdown__2Z72V,
.dropdown_dropdownToggle__3tBCx {
  width: 100%;
}

.dropdown_guests-picker__1Y_kl:hover {
  border-style: solid;
  border-color: #00aff5;
}

.dropdown_label_name__36ELE {
  font-weight: bold;
}

@media screen and (max-width: 575px) {
  .dropdown_dropdownMenu__QCQo2 {
    max-width: 100%;
  }
}

.day_cell_dayCell__1gmIU {
  width: 39px;
  height: 38px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.day_cell_dayCellValid__1JE6G {

}

.day_cell_dayCellValid__1JE6G:hover {
  color: var(--main-text-color);
  background: var(--rangepicker-cell-span);
}

.day_cell_dayCellMinStayRestricted__3jahj {
  color: var(--rangepicker-cell-disabled-text);
  background: #fff;
}

.day_cell_dayCellBlocked__18N0Q {
  color: var(--rangepicker-cell-disabled-text);
  background: var(--rangepicker-cell-disabled);
}

.day_cell_dayCellMinNightsRestricted__1uHgo,
.day_cell_onDatesChange__3GVV1.day_cell_dayCellMinNightsRestricted__1uHgo {
  color: var(--rangepicker-cell-disabled-text);
  background: #fff;
}

.day_cell_dayCellBlockedOutOfRange__1It1f {
  background: #fff;
  opacity: 0.5;
}

.day_cell_dayCellHoveredSpan__HQJcu,
.day_cell_dayCellHoveredSpan__HQJcu:hover {
    background: #a593c8; 
    color: #fff;
}

.day_cell_dayCellSelectedStart__1RI21 {
  border-radius: 2px 0 0 2px;
}

.day_cell_dayCellSelectedEnd__2iBzp {
  border-radius: 0 2px 2px 0;
}

.day_cell_dayCellSelectedStart__1RI21,
.day_cell_dayCellSelectedStart__1RI21:hover,
.day_cell_dayCellSelectedStart__1RI21:active,
.day_cell_dayCellSelectedEnd__2iBzp,
.day_cell_dayCellSelectedEnd__2iBzp:hover,
.day_cell_dayCellSelectedEnd__2iBzp:active {
  color: var(--rangepicker-cell-end-text);
  background: var(--rangepicker-cell-end);
  text-decoration: none;
}

.day_cell_dayCellSelectedSpan__staJ- {
  color: var(--main-text-color);
  background: var(--rangepicker-cell-span);
}

.day_cell_dayCellSelectedSpan__staJ-:hover,
.day_cell_dayCellSelectedSpan__staJ-:active {
  color: var(--main-text-color);
  background: var(--rangepicker-cell-span);
}
.info_section_infoContainer__3myBX {
  margin: 0px 22px 0 20px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;
}

.info_section_infoContainer__3myBX:not(:last-child) {
  margin-right: 8px;
}

.info_section_button__k0EQa {
  width: auto;
  letter-spacing: 1.17px;
  text-transform: uppercase;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 500;
  background-color: var(--button-color-light);
}

.info_section_button__k0EQa.btn-light:hover,
.info_section_button__k0EQa.btn-light:active,
.info_section_button__k0EQa.btn-light:not(:disabled):not(.disabled):active {
  background-color: var(--button-color-light-hover);
}

.rangepicker_rangepicker__28JuI .DateInput_input {
  font-size: 14px;
  color: var(--main-text-color);
  font-weight: 400;
}

.rangepicker_rangepicker__28JuI .DateInput_input::-webkit-input-placeholder {
  color: var(--info-text-color);
}

.rangepicker_rangepicker__28JuI .DateInput_input::placeholder {
  color: var(--info-text-color);
}

.DateRangePicker_picker {
  z-index: 99;
}

.rangepicker_rangepicker__28JuI .DateInput_fang {
  z-index: 6;
}

.rangepicker_rangepicker__28JuI .DateRangePicker {
  width: 100%;
}

.rangepicker_rangepicker__28JuI .DateRangePickerInput {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rangepicker_rangepicker__28JuI .CalendarMonth_table {
  border-collapse: separate;
  border-spacing: 0 2px;
}

.rangepicker_rangepicker__28JuI .CalendarMonth_caption {
  margin: 0 0 0 58px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  padding-top: 27px;
  padding-bottom: 37px;
}

.rangepicker_rangepicker__28JuI .DayPicker_weekHeader_li {
  color: var(--rangepicker-weekday-caption);
}

.rangepicker_labelContainer__2J5TE {
  width: 100%;
  justify-content: space-between;
  display: flex;
}

.rangepicker_labelContainer__2J5TE div {
  width: 130px;
}

.rangepicker_rangepicker__28JuI {
  margin-bottom: 8px;
}

.rangepicker_navPrev__2KJZ8,
.rangepicker_navNext__2irrs {
  position: absolute;
  font-size: 12px;
  top: 32px;
}

.rangepicker_navPrev__2KJZ8 {
  left: 32px;
}

.rangepicker_navNext__2irrs {
  right: 32px;
}

.rangepicker_label_name__1Ajp0 {
  font-weight: bold;
}

/* @media screen and (max-width: 770px) {
  .label_name {
    margin-left: -8%;
    margin-right: 8%;
    font-weight: bold;
}
}

@media screen and (max-width: 500px) {
  .label_name {
    margin-left: -12%;
    margin-right: 10%;
    font-weight: bold;
}
} */

.link_button_button__2XtF6.btn-link {
  color: var(--main-text-color);
  text-decoration: underline;
  border: none;
  padding: 0;
  line-height: 20px;
}

.link_button_button__2XtF6.btn-link:hover,
.link_button_button__2XtF6.btn-link:active,
.link_button_button__2XtF6.btn-link:focus {
  color: var(--main-text-color-active);
  box-shadow: none;
  border: none;
}
.integer_input_input__1sDf6 {
  display: flex;
  border: 1px solid var(--input-border-color);
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
  margin: auto;
  width: 100%;
  height: 40px;
}

.integer_input_input__1sDf6 button {
  height: 100%;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  color: var(--main-text-color) !important;
  border: none;
  border-radius: 0;
  border: none !important;
  box-shadow: none !important;
}

.integer_input_input__1sDf6 button:hover,
.integer_input_input__1sDf6 button:focus {
  background-color: var(--background-dark-color) !important;
  color: var(--main-text-color-hover) !important;
}

.integer_input_input__1sDf6 button:active {
  background-color: var(--main-text-active-background) !important;
  color: var(--main-text-active) !important;
}

.integer_input_input__1sDf6 button:disabled {
  background-color: var(--input-border-color) !important;
  color: var(--main-text-color) !important;
}

.integer_input_toggleIcon__1_RlH {
  vertical-align: middle;
}

.integer_input_label__r7sEX {
  color: var(--label-text-color);
  padding-right: 4px;
  width: 100%;
}

.integer_input_container__2AO5i {
  display: flex;
  align-items: center;
}

.cell_cell__yiaTN {
  position: relative;
  display: block;
  width: 100%;
  min-height: 40px;
  padding: 8px 8px 8px 0px;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid rgba(36, 46, 66, 0.12);
}

.cell_cellNoLine__2LevW {
  border-bottom-style: none;
}

.cell_cellNoPadding__1Bsio {
  padding-right: 5px;
}

@media screen and (max-width: 760px) {
  .cell_cell__yiaTN {
    padding-bottom: 30px !important;
  }
}

.field_wrapper_fieldWrapper__3Xi0I {
  width: 100%;
  margin-bottom: 20px;
  padding-right: 8px;
  padding-left: 8px;
}
.material_select_dropdownToggle__2-hEv {
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 8px;
  padding-left: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  transition: all 200ms ease;
  background-color: white !important;
  color: var(--main-text-color);
}

.material_select_hiddenSelect__1wQ__ {
  visibility: hidden;
  display: none;
}

.material_select_dropdownButton__oYX4B {
  border: none;
}

.material_select_dropdownToggle__2-hEv,
.material_select_dropdownButton__oYX4B {
  background-color: white !important;
  color: var(--main-text-color);
}

.material_select_dropdownToggle__2-hEv:hover,
.material_select_dropdownToggle__2-hEv:focus,
.material_select_dropdownButton__oYX4B:hover,
.material_select_dropdownButton__oYX4B:focus {
  background-color: white !important;
  color: var(--main-text-color) !important;
  outline: none;
  box-shadow: none;
}

.material_select_dropdown__3iGy0 > .btn-primary.dropdown-toggle,
.material_select_dropdownToggle__2-hEv.btn-primary:not(:disabled):not(.disabled):focus,
.material_select_dropdownToggle__2-hEv.btn-primary:not(:disabled):not(.disabled):active,
.material_select_dropdownButton__oYX4B.btn-primary:not(:disabled):not(.disabled):focus,
.material_select_dropdownButton__oYX4B.btn-primary:not(:disabled):not(.disabled):active {
  background-color: white;
  color: var(--main-text-color) !important;
  box-shadow: none;
  outline: none;
  border-color: rgba(0, 0, 0, 0.25);
}

.material_select_dropdown__3iGy0.show > .btn-primary.dropdown-toggle,
.material_select_dropdownToggle__2-hEv.btn-primary:not(:disabled):not(.disabled):focus {
  border-bottom: 2px solid #008489;
  margin-bottom: -1px;
}

.material_select_containerHorizontal__2hcIv,
.material_select_containerVertical__33rYb {
  display: flex;
  align-items: center;
}

.material_select_containerVertical__33rYb {
  flex-direction: column;
}

.material_select_dropdown__3iGy0,
.material_select_dropdownToggle__2-hEv {
  width: 100%;
}

.material_select_dropdown__3iGy0 > .material_select_dropdownToggleInvalid__3hqx7.btn-primary.dropdown-toggle,
.material_select_dropdownToggleInvalid__3hqx7.btn-primary:not(:disabled):not(.disabled):focus,
.material_select_dropdownToggleInvalid__3hqx7.btn-primary:not(:disabled):not(.disabled):active,
.material_select_dropdownToggleInvalid__3hqx7.btn-primary:not(:disabled):not(.disabled):focus,
.material_select_dropdownToggleInvalid__3hqx7.btn-primary:not(:disabled):not(.disabled):active {
  border-color: #dc3545;
}

.material_select_dropdownToggle__2-hEv:empty:after {
  margin-right: 0;
  margin-left: auto;
}

.children_age_input_inputContainer__13V40 {
  padding: 1rem 0.5rem 0 0;
  flex-basis: 50%;
}

.children_age_settings_settingsWrapper__2H0Mk {
  margin-top: .5rem;
}

.children_age_settings_settingsTitle__284zj {
  color: var(--info-text-color); 
}

.children_age_settings_settingsContainer__1c3uw {
  margin-right: -0.5rem;
  margin-top: -0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.occupancy_settings_form_closeSection__3TeRR {
  padding-top: 8px;
  margin-bottom: -12px;
  display: flex;
  justify-content: flex-end;
}
.main_search_wrapper__3t8pi {
  display: flex;
  justify-content: center;
  height: 100%;
}

.main_search_main_search_width__1pobo {
  max-width: 900px;
  margin: auto;
}

.main_search_wrapper__3t8pi .DateRangePickerInput {
  height: 100%;
  border: 0;
  border-radius: 0;
  overflow: hidden;
}

.main_search_wrapper__3t8pi .DateRangePicker {
  height: 100%;
}

.main_search_wrapper__3t8pi .DateRangePicker > div {
  height: 100%;
}

.main_search_wrapper__3t8pi .DateInput_input {
  height: 40px;
  padding: 11px 10px 9px;
  /* height: 20px; */
}

.main_search_wrapper__3t8pi .DateInput_input__focused {
  border-color: transparent;
}

.main_search_rangePicker__3f-de {
  margin-bottom: 0;
  height: 100%;
}

.main_search_wrapper__3t8pi .main_search_rangePicker__error__29F-6 .DateInput_input::-webkit-input-placeholder {
  color: #ff4d4f;
}

.main_search_wrapper__3t8pi .main_search_rangePicker__error__29F-6 .DateInput_input::placeholder {
  color: #ff4d4f;
}

.main_search_margin_top__1wI-Q {
  margin-top: 8%;
}

.main_search_margin_top2__3EIBX {
  margin-top: 15%;
}

.main_search_occupancyDropDown__3i0l6 {
  margin-bottom: 0;
}

.main_search_occupancyDropDown__3i0l6 .dropdown {
  height: 100%;
  margin-top: 0px;
}

.main_search_occupancyDropDown__3i0l6 .dropdown-toggle {
  height: 100%;
  border: none;
  border-radius: 0px;
  padding-right: 5%;
}

.main_search_occupancyDropDown__3i0l6 .btn-primary:not(:disabled):not(.disabled):focus {
  border: none;
}

.main_search_occupancyDropDown__3i0l6 .btn-primary.dropdown-toggle {
  width: 195px;
}
.main_search_occupancyDropDown__3i0l6 .btn-primary.dropdown-toggle:hover {
  width: 195px;
}

.main_search_occupancyDropDown__3i0l6 .show > .btn-primary.dropdown-toggle {
  border: none;
}

.main_search_occupancyDropDown__3i0l6 .dropdown-menu.show {
  width: 305px;
  max-height: 305px;
  overflow: auto;
}

.main_search_fa-search__1jrJf {
  color: #fff;
}

.main_search_searchBtn__2WhD6.btn-primary:hover,
.main_search_searchBtn__2WhD6.btn-primary:focus,
.main_search_searchBtn__2WhD6.btn-primary:active {
  transition: all 200ms ease;
  background: rgb(0 190 10 / 90%);
}

.main_search_searchBtn__2WhD6.btn-primary:not(:disabled):not(.disabled):active {
  background: rgb(0 190 10 / 90%);
}

.main_search_margin_scroll__IMwO9 {
  margin-bottom: 0;
}

.main_search_search_control__2VBtj {
  /* margin-left: 14%; */
  margin-top: 1.2%;
}

.main_search_modal_1__llXmB {
  position: absolute !important;
  left: 11% !important;
  top: 100% !important;
  z-index: 1 !important;
  background: #ffffff !important;
  border-radius: 32px !important;
  box-shadow: 0px 6px 20px rgb(0 0 0 / 20%) !important;
  margin-top: 12px !important;
  max-height: calc(100vh - 220px) !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  padding: 16px 32px !important;
}

.main_search_modal_2__3UcDP {
  width: 70%;
}

.main_search_modal_3__10T3b {
  padding: 8px 0px !important;
  margin: 0px -32px -8px !important;
  width: 500px !important;
}

.main_search_modal_4__28jq7 {
  margin: 0px 0px 16px !important;
  padding: 0px !important;
}

.main_search_modal_5___zpdD {
  list-style-type: none !important;
  margin: 0px !important;
  padding: 8px 32px 2px !important;
}

.main_search_modal_6__2oFw1 {
  text-transform: uppercase !important;
  font-size: 12px !important;
  line-height: 12px !important;
  font-weight: 800 !important;
}

.main_search_modal_7__AsKBL {
  list-style-type: none !important;
  cursor: pointer !important;
  padding: 8px 32px !important;
}

.main_search_modal_8__-2Dtc {
  text-decoration: none !important;
  -webkit-box-align: center !important;
  display: flex !important;
  width: 100% !important;
  flex-direction: row-reverse !important;
  align-items: center !important;
  padding: 8px 24px !important;
  margin-top: 8px !important;
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px !important;
  border-radius: 32px !important;
  height: 58px !important;
  border: 1px solid rgb(221, 221, 221);
}

.main_search_modal_8__-2Dtc:hover {
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 16px !important;
}

.main_search_modal_9__3_sy5 {
  -webkit-box-pack: center !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}

.main_search_modal_10__2-4c2 {
  overflow: clip !important;
  overflow-x: clip !important;
  overflow-y: clip !important;
}
.main_search_modal_10__2-4c2 {
  /* -webkit-text-fill-color: transparent !important; */
  background: linear-gradient(
      90deg,
      rgb(111, 1, 156) 0%,
      rgb(198, 1, 126) 135.12%
    )
    text !important;
}
.main_search_modal_10__2-4c2 {
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 800 !important;
  color: rgb(34, 34, 34) !important;
  max-height: 120px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
}

/* .modal_10 { */
/* overflow:auto; */
/* -webkit-text-fill-color: transparent !important; */
/* background: linear-gradient(90deg, rgb(111, 1, 156) 0%, rgb(198, 1, 126) 135.12%) text !important;
  font-size: 18px !important;
  line-height: 20px !important;
  font-weight: 800 !important;
  color: #4F02A4 !important;
  max-height: 120px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
} */

@media (max-width: 1200px) {
  .main_search_search_control__2VBtj {
    margin-left: 0%;
    margin-right: 0%;
  }

  .main_search_rangePicker__3f-de {
    width: 30%;
    margin-right: 0;
    margin-left: 0;
  }

  .main_search_occupancyDropDown__3i0l6 {
    margin-right: 0%;
  }

  .main_search_occupancyDropDown__3i0l6 .dropdown-toggle {
    height: 100%;
    border: none;
    border-radius: 0px;
    padding-right: 20%;
  }
}

@media screen and (max-width: 980px) {
  .main_search_main_search_width__1pobo {
    max-width: 700px !important;
    margin: auto;
  }
}

@media screen and (max-width: 770px) {
  .main_search_main_search_width__1pobo {
    max-width: 80% !important;
  }

  .main_search_wrapper__3t8pi {
    margin-top: -200px;
  }

  .main_search_search_index__24v9O {
    z-index: 20 !important;
  }

  .main_search_rangePicker__3f-de {
    width: 100%;
    margin: 0 7%;
  }

  .main_search_occupancyDropDown__3i0l6 {
    margin-bottom: 20px;
  }

  .main_search_occupancyDropDown__3i0l6 .dropdown {
    height: 100%;
    margin-top: -15px;
    margin-left: 15%;
  }

  .main_search_occupancyDropDown__3i0l6 .dropdown-toggle {
    height: 100%;
    border: 1px solid;
    border-color: gray;
    border-radius: 0px;
    padding-right: 5%;
  }

  .main_search_search_control__2VBtj {
    margin-left: 50%;
  }

  .main_search_margin_top__1wI-Q {
    margin-top: 5%;
  }

  .main_search_margin_top2__3EIBX {
    margin-top: 0%;
  }
}

@media screen and (max-width: 570px) {
  .main_search_modal_1__llXmB {
    position: absolute !important;
    left: 5% !important;
    top: 100% !important;
    z-index: 1 !important;
    background: #ffffff !important;
    border-radius: 32px !important;
    box-shadow: 0px 6px 20px rgb(0 0 0 / 20%) !important;
    margin-top: 12px !important;
    max-height: calc(100vh - 220px) !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding: 16px 32px !important;
  }
  .main_search_modal_3__10T3b {
    padding: 8px 0px !important;
    margin: 0px -32px -8px !important;
    width: 400px !important;
  }

  .main_search_main_search_width__1pobo {
    max-width: 95% !important;
  }

  .main_search_rangePicker__3f-de {
    width: 100%;
    /* padding: 10px 15px; */
    margin: 1% 7%;
  }

  .main_search_occupancyDropDown__3i0l6 {
    /* margin-bottom: 0; */
    margin-top: 2% !important;
    margin-left: 2% !important;
  }
}

@media screen and (max-width: 450px) {
  .main_search_modal_3__10T3b {
    padding: 8px 0px !important;
    margin: 0px -32px -8px !important;
    width: 300px !important;
  }

  .main_search_occupancyDropDown__3i0l6 {
    margin-bottom: 0;
    margin-left: 1%;
    /* margin-left: 7% !important; */
  }

  .main_search_occupancyDropDown__3i0l6 .dropdown {
    margin-top: -10px;
    /* padding-left: 10px; */
    /* margin-left: 10%; */
  }
}

@media screen and (max-width: 400px) {
  .main_search_margin_scroll__IMwO9 {
    margin-bottom: 30%;
  }

  .main_search_occupancyDropDown__3i0l6 {
    align-self: center;
    width: auto;
  }
}

.search_margins__Y28XZ {
  margin-left: 20px !important;
  width: 100%;
}

@media screen and (max-width: 770px) {
  .search_margins__Y28XZ {
    /* margin-right: 5% !important; */
    padding: 0 !important;
    margin: 0 7% !important;
  }

  .search_label_name__3uns1 {
    float: left;
    font-weight: bold;
  }
}

@media screen and (max-width: 400px) {
  .search_margins__Y28XZ {
    /* margin-left: -1% !important; */
    /* margin-right: 0% !important; */
  }

  .search_margins__Y28XZ input {
    width: 100% !important;
    margin: auto;
  }
}

.home_page_cookie__2VbyH {
  color: #ffffff;
  text-decoration: underline;
  padding-right: 5px;
}

.home_page_emptyWrapper__2rhJI {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.home_page_title__36qib {
  font-family: Helvetica;
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 40%;
}

.home_page_subTitle__3rmVr {
  font-family: Helvetica;
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
  margin-bottom: 48px;
  text-align: center;
}

.home_page_headerContainer__3KfAS {
  position: relative;
  width: 100%;
}

.home_page_headerContainer__3KfAS .btn-link {
  color: white;
}

@media screen and (max-width:950px) {
  .home_page_title__36qib {
    line-height: 50px;
  }
}

@media screen and (max-width: 750px) {

  .home_page_title__36qib {
    display: none;
  }

  .home_page_subTitle__3rmVr {
    display: none;
  }
}

.banner_banner3__fZF8T {
  display: grid;
  grid-template-columns: 48.5% 48.5%;

  grid-column-gap: 3%;

  -webkit-column-gap: 3%;

          column-gap: 3%;
}

.banner_banner_wrapper__HOGYH {
  width: 90% !important;
  margin: auto;
}

.banner_heading__3MvRh {
  margin-top: 5rem;
  color: #222222;
  font-size: 42px;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 47px;
}

@media screen and (max-width: 1280px) {
  .banner_heading__3MvRh {
    color: #222222;
    font-size: 37px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 40px;
  }
}

@media screen and (max-width: 1120px) {
  .banner_heading__3MvRh {
    color: #222222;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 31px;
  }
}

@media screen and (max-width: 760px) {
  .banner_banner3__fZF8T {
    display: grid;
    grid-template-columns: 100%;
  }
}

.banner1_celebrate__3foqr {
  background-image: url(/static/media/banner1.2990c756.jpg);
  color: #fff;
  /* padding: 10rem 5rem; */
  height: 30rem;
  border-radius: 15px;
  background-size: cover;
  position: relative;
  /* background-color: #1c1c1c; */
  background-repeat: no-repeat;
  background-position: right;
}

.banner1_celebrate_back__1Jor_ {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4) 100%,
    rgba(0, 0, 0, 0.4) 100%
  );
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 3rem;
  padding-left: 3rem !important;
  border-radius: 15px;
}

.banner1_celebrate__3foqr h2 {
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: #fff;
}

.banner1_celebrate__3foqr p {
  line-height: 25px;
  font-size: 1.3rem;
}

@media screen and (max-width: 767px) {
  .banner1_celebrate__3foqr:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    z-index: -1;
  }

  .banner1_celebrate__3foqr {
    position: relative;
    height: 35rem;
    z-index: -1 !important;
  }

  .banner1_celebrate__3foqr h2 {
    font-size: 2.2rem;
    letter-spacing: 1px;
    color: #fff;
  }

  .banner1_celebrate__3foqr p {
    line-height: 25px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .banner1_celebrate__3foqr {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }
}

.banner2_tryhosting__22cy4 {
  background-image: url(/static/media/banner2.78bcba5c.jpg);
  color: #fff;
  /* padding: 10rem 5rem; */
  height: 30rem;
  border-radius: 15px;
  background-size: cover;
  position: relative;
  /* background-color: #1c1c1c; */
  background-repeat: no-repeat;
  background-position: right;
}

.banner2_tryhosting__22cy4 h2 {
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: #fff;
}

.banner2_tryhosting__22cy4 p {
  line-height: 25px;
  font-size: 1.3rem;
}

.banner2_back__1FsbG {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4) 100%,
    rgba(0, 0, 0, 0.4) 100%
  );
  left: 0%;
  width: 50%;
  height: 100%;
  color: #fff;
  padding-top: 5rem;
  border-radius: 15px;
  padding-left: 3rem !important;
}

@media screen and (max-width: 1400px) {
  .banner2_tryhosting__22cy4 {
    position: relative;
    height: 30rem;
    /* z-index:-1; */
  }
}

@media screen and (max-width: 1200px) {
  .banner2_tryhosting__22cy4 {
    position: relative;
    height: 35rem;
    /* z-index:-1; */
  }
}

@media screen and (max-width: 767px) {
  .banner2_tryhosting__22cy4:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    z-index: -1 !important;
  }
  .banner2_tryhosting__22cy4 {
    position: relative;
    height: 35rem;
    /* z-index:-1; */
  }

  .banner2_back__1FsbG {
    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6) 100%,
      rgba(0, 0, 0, 0.6) 50%
    );
    left: 0%;
    width: 100%;
    height: 100%;
    color: #fff;
    padding-top: 3rem;
    padding-left: 2rem;
    border-radius: 15px;
  }

  .banner2_tryhosting__22cy4 h2 {
    font-size: 2.2rem;
    letter-spacing: 1px;
    color: #fff;
  }

  .banner2_tryhosting__22cy4 p {
    line-height: 25px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .banner2_tryhosting__22cy4 {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }
}

@media screen and (max-width: 500px) {
  .banner2_tryhosting__22cy4 {
    position: relative;
    height: 50rem;
    /* z-index:-1; */
  }
}

.banner3_celebrate__2E64j {
  background-image: url(/static/media/banner3.3bfc323a.jpg);
  color: #fff;
  /* padding: 10rem 5rem; */
  height: 35rem;
  border-radius: 15px;
  background-size: cover;
  position: relative;
  /* background-color: #1c1c1c; */
  background-repeat: no-repeat;
  background-position: right;
}

.banner3_back2__CzjI0 {
  background-image: url(/static/media/banner4.b5030658.jpg);
  color: #fff;
  /* padding: 10rem 5rem; */
  height: 35rem;
  border-radius: 15px;
  background-size: cover;
  position: relative;
  /* background-color: #1c1c1c; */
  background-repeat: no-repeat;
  background-position: right;
}

.banner3_celebrate_back__2FXex {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4) 100%,
    rgba(0, 0, 0, 0.4) 100%
  );
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 3rem;
  padding-left: 3rem !important;
  border-radius: 15px;
}

.banner3_celebrate__2E64j h2 {
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: #fff;
}

.banner3_celebrate__2E64j p {
  line-height: 25px;
  font-size: 1.3rem;
}

.banner3_back2__CzjI0 h2 {
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: #fff;
}

.banner3_celebrate__2E64j p {
  line-height: 25px;
  font-size: 1.3rem;
}

.banner3_banner2__y7hOG {
  margin-left: 1%;
}

@media screen and (max-width: 1020px) {
  .banner3_celebrate__2E64j {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }

  .banner3_back2__CzjI0 {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }

  .banner3_celebrate__2E64j h2 {
    font-size: 2.2rem;
    letter-spacing: 1px;
    color: #fff;
  }

  .banner3_back2__CzjI0 h2 {
    font-size: 2.2rem;
    letter-spacing: 1px;
    color: #fff;
  }

  .banner3_celebrate__2E64j p {
    line-height: 25px;
    font-size: 1.2rem;
  }

  .banner3_back2__CzjI0 p {
    line-height: 25px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 880px) {
  .banner3_celebrate__2E64j {
    position: relative;
    height: 45rem;
  }

  .banner3_back2__CzjI0 {
    position: relative;
    height: 45rem;
  }
}

@media screen and (max-width: 760px) {
  .banner3_celebrate__2E64j:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    z-index: -1;
  }

  .banner3_celebrate__2E64j {
    position: relative;
    height: 30rem;
    /* z-index:-1; */
  }

  .banner3_back2__CzjI0 {
    position: relative;
    height: 30rem;
    /* z-index:-1; */
  }

  .banner3_banner2__y7hOG {
    margin-top: 2%;
    margin-left: 0%;
  }
}

@media screen and (max-width: 585px) {
  .banner3_celebrate__2E64j {
    position: relative;
    height: 30rem;
    /* z-index:-1; */
  }

  .banner3_back2__CzjI0 {
    position: relative;
    height: 30rem;
    /* z-index:-1; */
  }
}

@media screen and (max-width: 440px) {
  .banner3_celebrate__2E64j {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }
  .banner3_back2__CzjI0 {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }
}

.banner4_celebrate__13YR3 {
  background-image: url(/static/media/banner4.b5030658.jpg);
  color: #fff;
  /* padding: 10rem 5rem; */
  height: 30rem;
  border-radius: 15px;
  background-size: cover;
  position: relative;
  /* background-color: #1c1c1c; */
  background-repeat: no-repeat;
  background-position: right;
}

.banner4_celebrate_back__3iOVN {
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0.4) 100%,
    rgba(0, 0, 0, 0.4) 100%
  );
  width: 100%;
  height: 100%;
  color: #fff;
  padding-top: 3rem;
  padding-left: 3rem !important;
  border-radius: 15px;
}

.banner4_celebrate__13YR3 h2 {
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: #fff;
}

.banner4_celebrate__13YR3 p {
  line-height: 25px;
  font-size: 1.3rem;
}

@media screen and (max-width: 910px) {
  .banner4_celebrate__13YR3 {
    position: relative;
    height: 35rem;
    /* z-index:-1; */
  }
}

@media screen and (max-width: 767px) {
  .banner4_celebrate__13YR3:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 15px;
    z-index: -1;
  }

  .banner4_celebrate__13YR3 {
    position: relative;
    height: 32rem;
    /* z-index:-1; */
  }

  .banner4_celebrate__13YR3 h2 {
    font-size: 2.2rem;
    letter-spacing: 1px;
    color: #fff;
  }

  .banner4_celebrate__13YR3 p {
    line-height: 25px;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 520px) {
  .banner4_celebrate__13YR3 {
    position: relative;
    height: 40rem;
    /* z-index:-1; */
  }
}

.about_content__XH2JJ {
    text-align: justify;
    text-justify: inter-word;
}
.about_page_footer__v-3KT {
    margin-top: 100px;
}

.link_link__1A_GF {
  display: flex;
  align-items: flex-start;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--main-text-color);
}

.link_link__1A_GF:hover {
  color: var(--main-text-hover);
}

.link_link__1A_GF[disabled] {
  color: var(--main-text-color);
  text-decoration: none;
  pointer-events: none;
}

.link_icon__2T14B {
  margin-right: 8px;
}

.link_linkContent__BRWNI {
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
  font-family: inherit;
  color: var(--main-text-color);
}
.section_title_sectionTitle__1EASd {

}
.footer_footer__1lZIc {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.footer_footer__1lZIc > * {
  flex: 1 3 33%;
  padding: 0 10px 24px;
}

.footer_footerWrapper__3ls1o {
  display: block;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: stretch;
  margin: 0 auto;
  padding-top: 56px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 20px;
  /* padding: 56px 16px; */
}

@media screen and (max-width: 991px) {
  .footer_footer__1lZIc {
    display: block;
  }

  .footer_footerWrapper__3ls1o {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 760px) {
  .footer_footer__1lZIc {
    display: block;
  }

  .footer_footerWrapper__3ls1o {
    margin-bottom: -50px;
  }
}
.hotel_logo_logo__vXJk- {
  width: 56px;
  height: 56px;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  object-fit: contain;
}

.hotel_logo_logoContainer__XQ_O_ {
  margin: 12px 12px 12px 0;
  display: flex;
  align-items: center;
}
.hotel_title_hotelTitle__UGv-J { 
  flex: 1 1;
  font-size: 32px;
  line-height: 32px;
  font-weight: bold;
  /* color: var(--link-color); */
  color: #000;
}
.header_header__v9FKw {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.header_logo__1X7aW {
  width: 30%;
  height: 30%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  object-fit: contain;
}

.header_titleSection__J90Ki {
  display: flex;
  min-height: 80px;
  align-items: center;
  max-width: 100%;
}

.header_selectSection__1R6Q3 {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

@media screen and (max-width: 575px) {
  .header_header__v9FKw {
    flex-direction: column;
  }

  .header_selectSection__1R6Q3,
  .header_titleSection__J90Ki {
    justify-content: center;
  }

  .header_selectSection__1R6Q3 {
    height: 40px;
  }
}
.section_wrapper_containerLight__1XzBc {
  background-color: var(--background-light-color);
}

.section_wrapper_containerDark__WOB_4 {
  background-color: var(--background-dark-color);
}

.section_wrapper_containerDark__WOB_4,
.section_wrapper_containerLight__1XzBc {
  padding: 0 0;
}

.section_wrapper_containerPadded__3zszt {
  padding: 56px 0;
}

.section_wrapper_containerInner__3Cb-2 {
  display: block;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  align-items: stretch;
  margin: auto;
}

@media screen and (max-width: 575px) {
  .section_wrapper_containerDark__WOB_4,
  .section_wrapper_containerLight__1XzBc {
    padding: 24px 0;
  }
}
.info_entry_container__2QXis {
  display: flex;
  padding-bottom: 16px;
}

.info_entry_label__1AyPE {
  padding-right: 16px;
  flex: 1 2 33%;
}

.info_entry_content__UBRxs {
  flex: 2 1 66%;
}

@media screen and (max-width: 991px) {
  .info_entry_container__2QXis {
    flex-direction: column;
  }
}
.facility_facility__5i--I {
  margin-top: 2px;
  margin-bottom: 2px;
  padding-left: 27px;
  background-image: url(/static/media/icons-list.219537c9.svg);
  background-position: 1px 7px;
  background-repeat: no-repeat;
}
.facility_category_facilityCategory__3-bau {
  padding-bottom: 8px;
  width: 25%;
}

.facility_category_facilityCategoryTitle__3Qr32 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.44px;
  color: #494949;
}

@media screen and (max-width: 767px) {
  .facility_category_facilityCategory__3-bau {
    width: 50%;
  }
}
.hotel_facilities_section_hotelFacilitiesContainer__3C_kf {
  display: flex;
  flex-wrap: wrap;
}
.hotel_location_locationContainer__1A6V3 {
  display: flex;
}

.hotel_location_icon__2vhQR {
  margin-right: 4px;
}
.hotel_info_section_hotelDescription__2jSMJ {
  margin: 0;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: justify;
  font-family: inherit;
  color: var(--main-text-color);
}

.hotel_info_section_hotelTitleSection__1kBEg { 
  padding-bottom: 1.5rem;
}
.overlay_overlay__XF7RF {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  height: 100%;
  background-color: rgba(73, 209, 207, 0.5);
  display: flex;
  /* align-items: center; */
  justify-content: center;
  z-index: 3;
  transition: ease-in 300ms;
  pointer-events: initial;
}

.overlay_hiddenOverlay__2mU5w {
  pointer-events: none;
  opacity: 0;
}

.overlay_overlayContent__2y_L7 {
  text-align: center;
  color: var(--main-text-color);
}

.loading_container_container__3kYN5 {
  position: relative;
}

.loading_container_overlayText__2uQta {
  line-height: 2rem;
}
.placeholder_container__3nFgU {
  text-align: center;
  padding: 32px;
  margin: auto;
}

.placeholder_icon__3g-U4 {
  font-size: 64px;
  margin-bottom: 16px;
}
.rates_table_header_column_column__2GOej {
  top: 0;
  box-sizing: content-box;
  padding: 0 !important;
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  height: 40px;
  flex-grow: 2;
}

.rates_table_header_column_column__2GOej::after {
  content: '';
  position: absolute;
  background-color: #dee2e6;
}

.rates_table_header_column_columnContent__OUNLG {
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 8px;
  align-items: center;
  border-bottom: 2px solid rgba(36, 46, 66, 0.12);
  background-color: #111112c4 !important;
  display: block;
  min-height: 40px;
}

.rates_table_header_column_columnLabel__1I34a {
  color: #fff;
  opacity: 1;
}

.rates_table_header_columnNarrow__1N8Jy {
  flex-grow: 1;
}

.rates_table_header_tableHeaderWrapper__3WVNV {
  display: flex;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .rates_table_header_tableHeaderWrapper__3WVNV {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .action_button_buttonContainer__24PIu {
    width: 100%;
    margin-left: 0;
  }
}

.action_button_btn_color__1BS6I {
  background-color: #000 !important;
}

.action_button_btn_color__1BS6I:hover {
  background-color: #111112c4 !important;
}

.reload_container_container__17ROc {
  position: relative;
}

.reload_container_content__3QjHd {
  min-width: 200px;
  margin-top: 50px;
}

.caption_caption__1qjt7 {
  margin-top: 8px;
  opacity: 0.85;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.caption_captionRight__3UGVu {
  text-align: right;
}

.caption_captionCenter__3oajn {
  text-align: center;
}

.caption_captionGreen__3tZAw {
  color: #359733;
}
.no_rates_placeholder_placeholderContainer__3KKFD {
  display: flex;
  height: 100%;
  align-items: center;
}

.no_rates_placeholder_placeholderContent__IhmTL {
  margin: auto;
  max-width: 300px;
  display: flex;
  padding-bottom: 8px;
}

.no_rates_placeholder_icon__1hswI {
  width: 24px;
  margin-right: 8px;
}
.occupancy_section_occupancyContainer__zR6YL {
  display: flex;
  align-items: flex-end;
}

.occupancy_section_occupancyCaption__nw0-V {
  /* color: #000; */
}

.occupancy_section_iconActive__D4AQ5 {
  color: var(--icon-color);
  /* color: #000; */

}

.occupancy_section_iconInactive__1Twkk {
  color: var(--info-text-color);
  /* color: #000; */

}
.rate_plan_occupancy_occupanciesContainer__3zi_Z {
  display: flex;
  flex-wrap: wrap;
}

.rate_plan_occupancy_occupancyCell__3Q6vX {
  flex: 1 3 17%
}
.rate_plan_occupancy_select_occupancySelectContainer__1totK {
  display: flex;
  border: 1px solid lightgray;
  border-radius: 6px;
  justify-content: space-between;
  align-items: center;
  min-width: 130px;
  max-width: 200px;
  margin: auto;
}

.rate_plan_occupancy_select_occupancySelectContainer__1totK button {
  padding: 0.25rem 0.5rem;
  background-color: #111112c4 !important;
}

.rate_plan_occupancy_select_toggleIcon__KhcyM {
  vertical-align: middle;
}

.rate_plan_occupancy_select_occupancyDecreaseButton__3Neid {
  border-radius: 5px 0 0 5px;
}

.rate_plan_occupancy_select_occupancyIncreaseButton__1Hd8u {
  border-radius: 0 5px 5px 0;
}

.rate_plan_occupancy_select_occupancySelectCell__2kIVQ {
  flex: 1 3 17%;
}

.alert_alert__31now {
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px 8px 8px 24px;
  border-radius: 2px;
  color: #333;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.alert_error__13pYD {
  background-color: rgba(244, 108, 96, 0.2);
  background-image: url(/static/media/icons-error.49d6dd39.svg);
  background-position: 4px 6px;
  background-size: auto;
  background-repeat: no-repeat;
}

.alert_success__21H50 {
  background-color: rgba(66, 189, 64, 0.2);
  background-image: url(/static/media/icons-success.83a88eac.svg);
  background-position: 4px 6px;
  background-size: auto;
  background-repeat: no-repeat;
}

.alert_info__1IuHc {
  background-color: rgba(85, 119, 252, 0.2);
  background-image: url(/static/media/icons-info.d52be8e5.svg);
  background-position: 4px 6px;
  background-size: auto;
  background-repeat: no-repeat;
}
.meal_policy_policyHightlighted__1HXkQ {
  color: #359733;
}
.tooltip_tooltipContainer__2ACfg {
  line-height: 1rem;
}

.tooltip_tooltipIcon__3Q8os {
  position: relative;
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  margin-top: 4px;
  margin-left: 2px;
  background-image: url(/static/media/icons-help.1391483e.svg);
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 200ms ease;
  font-size: 16px;
  line-height: 16px;
  cursor: help;
}

.tooltip_tooltipIcon__3Q8os:hover {
  background-image: url(/static/media/icons-help-active.d1442b0f.svg);
}
.policy_section_sectionContainer__3rws0 {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
}
.policies_breakdown_popoverContent__M1FeJ {
  padding: 0;
}

.policies_breakdown_popover__3duXG {
  min-width: 310px;
  z-index: 3;
  width: 210px;
  padding: 14px;
  border: none;
  box-shadow: 0 1px 4px 0 hsla(0,0%,77.7%,.5);
  font-size: 12px;
}

.rate_plan_policies_policyContainer__1jIi_ {
  position: relative;
}

.rate_plan_policies_policyCell__vxG0r {
  flex: 2 1 50%
}

.rate_plan_policies_mealContainer__3_FkM {
  display: flex;
}
.rate_plan_price_breakdown_popover__PuBkt {
  min-width: 310px;
  z-index: 3;
  width: 210px;
  padding: 14px;
  border: none;
  box-shadow: 0 1px 4px 0 hsla(0,0%,77.7%,.5);
  font-size: 12px;
}

.rate_plan_price_breakdown_priceRow__2IzKJ {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
}

.rate_plan_price_breakdown_totalContainer__1SmtQ {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 14px;
  padding: 0;
}

.rate_plan_price_breakdown_totalLabel__3vJca {
  opacity: .85;
  font-size: 12px;
}

.rate_plan_price_breakdown_totalPrice__3Lj8r {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}
.rate_plan_price_ratePlanPriceContainer__32MMI {
  display: flex;
  flex-direction: row;
}

.rate_plan_price_ratePlanTaxes__2ICGt {
  font-size: 0.8rem;
}

.rate_plan_price_ratePlanPrice__2eyR6 {
  white-space: nowrap;
}

.rate_plan_price_ratePlanPriceCell__3zwiD {
  flex: 2 1 50%;
}
.rate_plan_rate__2esnW {
  display: flex; 
}

.rate_plan_rate__2esnW:last-child {
  height: 100%;
}

.rate_plan_flexibleContainer__3G313 {
  flex: 3 2 66%;
  display: flex;
}

@media screen and (max-width: 767px) {
  .rate_plan_flexibleContainer__3G313 {
    flex-direction: column;
  }
}
.bed_option_bed__3kr6M {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  margin-left: 0;
  white-space: nowrap;
}

.bed_option_roomTitle__1eKRX {
  font-weight: bold;
}

.bed_option_bedIcon__2CXCw {
  margin-left: 0.5rem;
}
.bed_options_bedOptionsContainer__2byNU {
  font-size: 0.8rem;
}
.room_additional_facilities_container__UvLpH {
  border-top: 1px solid #dee2e6;
}

.room_additional_facilities_containerExpanded__1qMcc {
  border-color: rgba(0, 0, 0, 0);
}

.room_additional_facilities_facilities__3hrKZ > div {
  margin: 0;
} 

.room_additional_facilities_toggleButton__2d9Ck {
  padding: 0;
  font-size: 12px;
}

.room_additional_facilities_toggleButton__2d9Ck:active, .room_additional_facilities_toggleButton__2d9Ck:focus {
  box-shadow: none;
}


.separator_separator__2_djs {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: rgba(36,46,66,.12);
}
.room_details_section_roomDetailsContainer__1Rcxj {
  width: 100%;
  padding-bottom: 8px;
}

.room_details_section_roomDetailsContent__2Pnf1 {
  display: flex;
  flex-wrap: wrap;
}

.room_details_section_roomDetailsHeader__3x5yK {
  margin: 8px 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.photo_slider_carousel__1diqS {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1;
}

.photo_slider_carousel__1diqS .carousel-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.photo_slider_carouselItem__37iIb {
  height: 100%;
}

.photo_slider_carouselItem__37iIb img {
  object-fit: cover;
  height: 100%;
}

.photo_slider_carousel__1diqS .carousel-indicators {
  z-index: 1;
}

.photo_slider_carousel__1diqS .carousel-indicators li {
  width: 7px;
  height: 7px;
  background-color: rgba(255,255,255,.4);
  border-radius: 50%;
}

.photo_slider_carousel__1diqS .carousel-indicators li.active {
  background-color: #fff;
}

.photo_slider_carousel__1diqS .carousel-control-next,
.photo_slider_carousel__1diqS .carousel-control-prev {
  width: 56px;
  opacity: 1;
}

.photo_slider_carousel__1diqS .carousel-control-prev-icon {
  background-image: url(/static/media/icons-arrowleft.db716542.svg);
  
}

.photo_slider_carousel__1diqS .carousel-control-next-icon {
  background-image: url(/static/media/icons-arrowright.fe7a7322.svg);
}

.photo_slider_boxArrows__1LWKC .carousel-control-next,
.photo_slider_boxArrows__1LWKC .carousel-control-prev {
  background: var(--main-text-active-background);
  height: 56px;
  top: auto;
}

.photo_slider_boxArrows__1LWKC .carousel-control-prev {
  left: 0;
}

.photo_slider_boxArrows__1LWKC .carousel-control-next {
  left: 56px;
}

.photo_slider_carousel__1diqS .carousel-control-prev-icon,
.photo_slider_carousel__1diqS .carousel-control-next-icon {
  height: 24px;
  width: 24px;
}

@media screen and (max-width: 991px) {
  .photo_slider_boxArrows__1LWKC .carousel-control-next,
  .photo_slider_boxArrows__1LWKC .carousel-control-prev {
    background: transparent;
    height: auto;
    top: 0;
  }
  
  .photo_slider_boxArrows__1LWKC .carousel-control-prev {
    left: 0;
  }
  
  .photo_slider_boxArrows__1LWKC .carousel-control-next {
    left: auto;
  }
}
.room_photos_section_container__1nTtH {
  width: 100%;
  height: inherit;
  display: flex;
  margin-right: 16px;
}

@media screen and (max-width: 991px) {
  .room_photos_section_container__1nTtH {
    height: 40vh;
    margin-bottom: 8px;
  }
}
.room_details_modal_modal__3PpC9.modal-dialog {
  max-width: 1140px;
}

.room_details_modal_modal__3PpC9 .modal-content {
  min-height: 600px;
  height: 100%;
}

.room_details_modal_modalBody__3pFDt {
  display: flex;
  flex-direction: row;
}

.room_details_modal_modalHeader__rJLkc.modal-header {  
  padding: 0;
  border: none;
}

.room_details_modal_modalHeader__rJLkc h5 {
  line-height: 30px;
  margin-bottom: 8px;
}

.room_details_modal_contentContainer__113ib {
  width: 100%;
}

.room_details_modal_priceLabel__1DOqP {
  text-transform: capitalize;
  color: var(--label-text-color);
}

.room_details_modal_roomPrice__2puBN {
  font-size: 34px;
  line-height: 34px;
}

.room_details_modal_pricePostfix__1b-cM {
  margin-left: 8px;
}

.room_details_modal_modalFooter__1aA8U {
  justify-content: start;
}

.room_details_modal_description__2CETC {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: 0.44px;
  color: #333333;
  margin-bottom: 32px;
}

.room_details_modal_facilities__qQnOs {
  color: var(--main-text-color);
  line-height: 1.43;
  letter-spacing: 0.27px;
}

.room_details_modal_photosDesktop__3G26L {
  display: block;
}

.room_details_modal_photosMobile__1eJzq {
  display: none;
}

@media screen and (max-width: 575px) {
  .room_details_modal_modal__3PpC9 {
    margin: 0;
  }

  .room_details_modal_modalHeader__rJLkc, .room_details_modal_modalFooter__1aA8U {
    border-radius: 0;
  }

  .room_details_modal_modal__3PpC9 .modal-content {
    border-radius: 0;
    min-height: 100vh;
  }
}

@media screen and (max-width: 991px) {
  .room_details_modal_modalBody__3pFDt {
    flex-direction: column;
  }

  .room_details_modal_photosDesktop__3G26L {
    display: none;
  }

  .room_details_modal_photosMobile__1eJzq {
    display: block;
  }
}
.room_title_title__10cTL {
  cursor: pointer;
  color: #000;
  text-decoration: none;
  padding: 0;
  border: none;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
}

.room_title_title__10cTL.btn.btn-link:active,
.room_title_title__10cTL.btn.btn-link:hover,
.room_title_title__10cTL.btn.btn-link:focus {
  outline: none;
  box-shadow: none;
  color: #a593c8;
  text-decoration: none;
}

.room_title_room_photo__1HzLj {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 770px) {
  .room_title_room_photo__1HzLj {
    width: 100%;
    min-width: 100%;
    height: auto;
  }
}

.room_info_roomContainer__2xUtP {
  flex: 1 3 25%;
  width: auto;
}
.room_type_roomContainer__3BlKg {
  display: flex;
}

.room_type_ratesList__2OmXz {
  flex: 3 1 75%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .room_type_roomContainer__3BlKg {
    flex-direction: column;
    padding-bottom: 20px;
  }
}

.rates_table_tableContainer__TOt26 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

@media screen and (max-width: 767px) {
  .rates_table_tableContainer__TOt26 {
    flex-direction: column;
  }
}

.loading_spinnerContainer__3Rsv8 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
}

.loading_spinner__M9sfO {
  margin: auto;
}
.map_section_contactsSection__3sQb4 {
  position: relative;
}
.photo_gallery_wrapper__2YWe1 {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.photo_gallery_list__2kceC {
  --grid-gap: 10px;
  display: grid;
  grid-gap: var(--grid-gap);
  height: 454px;
  max-width: 100%;
}

.photo_gallery_list__oneOrTwo__30JEX {
  grid-template-columns: 100%;
}

.photo_gallery_list__threeOrFour__3C3qx {
  grid-template-columns: 75% calc(25% - var(--grid-gap));
}

.photo_gallery_list__fiveOrMore__mn4LN {
  grid-template-columns: 50% calc(25% - var(--grid-gap)) calc(25% - var(--grid-gap));
}

.photo_gallery_item__1Dsqn img {
  object-fit: cover;
  cursor: pointer;
  height: 222px;
}

.photo_gallery_item__1Dsqn:first-child img {
  height: 454px;
}

.photo_gallery_list__oneOrTwo__30JEX .photo_gallery_item--1__1GcyH {
  grid-column: 1;
  grid-row: 1;
}

.photo_gallery_list__oneOrTwo__30JEX .photo_gallery_item--2__3-xXV {
  display: none;
}

.photo_gallery_list__threeOrFour__3C3qx .photo_gallery_item--1__1GcyH {
  grid-column: 1;
  grid-row: 1 / 3;
}

.photo_gallery_list__threeOrFour__3C3qx .photo_gallery_item--2__3-xXV {
  grid-column: 2;
  grid-row: 1 / 2;
}

.photo_gallery_list__threeOrFour__3C3qx .photo_gallery_item--3__3bUzr {
  grid-column: 2;
  grid-row: 2;
}

.photo_gallery_list__threeOrFour__3C3qx .photo_gallery_item--4__3ND6F {
  display: none;
}

.photo_gallery_list__fiveOrMore__mn4LN .photo_gallery_item--1__1GcyH {
  grid-column: 1;
  grid-row: 1 / 3;
}

.photo_gallery_list__fiveOrMore__mn4LN .photo_gallery_item--2__3-xXV {
  grid-column: 2;
  grid-row: 1 / 2;
}

.photo_gallery_list__fiveOrMore__mn4LN .photo_gallery_item--3__3bUzr {
  grid-column: 3;
  grid-row: 1 / 2;
}

.photo_gallery_list__fiveOrMore__mn4LN .photo_gallery_item--4__3ND6F {
  grid-column: 2;
  grid-row: 2;
}

.photo_gallery_list__fiveOrMore__mn4LN .photo_gallery_item--5__1tWz7 {
  grid-column: 3;
  grid-row: 2;
}

@media screen and (max-width: 991px) {
  .photo_gallery_list__2kceC {
    grid-template-columns: 100%;
  }
}

.min_price_panel_minPriceContainer__3flhX {
  position: relative;
  z-index: 1;
  display: flex;
  height: 56px;
  padding-right: 24px;
  margin-top: -56px;
  padding-bottom: 12px;
  padding-left: 24px;
  justify-content: center;
  align-items: flex-end;
  /* background-color: var(--main-text-active-background); */
  background-color: #111112c4;
}

.min_price_panel_minPriceContainer__3flhX:hover {
  text-decoration: none;
}

.min_price_panel_minPriceContent__3yWAU {
  color: var(--main-text-active);
}

.min_price_panel_minPrice__17_Kh {
  padding: 0 4px;
  font-size: 24px;
}

.expand_button_expandButton__19wjM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -8px;
  padding: 0 0 8px;
  color: var(--cta-color);
  border-bottom: 1px solid rgba(36, 46, 66, 0.12);
}

.expand_button_expandButton__19wjM.btn:focus {
  box-shadow: none;
}

.expand_button_expandButton__19wjM.btn:hover {
  color: var(--cta-color);
}

.expand_button_arrowIcon__3jJjP {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: 350ms ease-in-out;
}

.expand_button_arrowIconInverted__33cpg {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.mobile_summary_container_container__5DwoE {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 6;
  display: block;
  width: 100%;
  margin-bottom: 0px;
  padding: 16px 16px 16px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
}
.book_button_buttonCaption__HveFc {
  margin-right: 4px;
  color: #fff;
}

.book_button_btn_color__ndExA {
  background-color: #000 !important;
  color: #fff;
}

.book_button_btn_color__ndExA:hover {
  background-color: #111112c4 !important;
}

.room_price_breakdown_title__DFBe2 {
  display: flex;
  justify-content: space-between;
}
.tax_tax__1JEg9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.total_price_total__gUFnQ {
  display: flex;
  width: 100%;
  line-height: 40px;
  justify-content: space-between;
}
.search_section_stickyContainer__1SYqw {
  position: -webkit-sticky;
  position: sticky;
  top: 56px;
  z-index: 5;
  display: flex;
  width: 100%;
  max-width: 1140px;
  padding-right: 15px;
  padding-left: 15px;
  pointer-events: none;
  justify-content: flex-end;
  align-items: flex-start;
  margin: auto;
}

.search_section_wrapper__xLqBc {
  position: absolute;
  height: 100%;
  width: 100%;
}

.search_section_searchPanelWrapper__27yz9 {
  width: 32%;
  pointer-events: all;
  margin-top: 0px;
  background-color: #fff;
}

.search_section_searchPanelNoOffset__1g7SQ {
  margin-top: 56px;
}

.search_section_searchSection__1k8u3 {
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
}

.search_section_searchRow__3IrbH {
  display: flex;
}

@media screen and (max-width: 991px) {
  .search_section_stickyContainer__1SYqw {
    position: static;
    height: auto;
  }
  
  .search_section_wrapper__xLqBc {
    position: relative;
    height: auto;
    width: auto;
  }

  .search_section_searchPanelWrapper__27yz9 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .search_section_stickyContainer__1SYqw {
    padding-right: 0px;
    padding-left: 0px;
  }

  .search_section_searchPanelWrapper__27yz9 {
    margin-top: 0px; 
  }
}

@media screen and (max-width: 575px) {
  .search_section_searchSection__1k8u3 {
    position: relative;
    min-width: auto;
    max-width: auto;
    left: auto;
    -webkit-transform: none;
            transform: none;
    bottom: auto;
  }

  .search_section_searchRow__3IrbH {
    flex-direction: column;
  }
}

.hotel_page_sliderContainer__1DMYj {
  height: 540px;
  max-height: 50vh;
}

.hotel_page_floatingSearchContainer__1FBsd {
  position: relative;
}

@media screen and (max-width: 991px) {
  .hotel_page_sliderContainer__1DMYj {
    height: 480px;
  }
}

@media screen and (max-width: 767px) {
  .hotel_page_sliderContainer__1DMYj {
    height: 340px;
  }
}

@media screen and (max-width: 575px) {
  .hotel_page_sliderContainer__1DMYj {
    height: 200px;
  }
}
.header_search_wrapper__2O51Z {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.header_search_wrapper__2O51Z .DateRangePicker_picker {
  z-index: 999;
}

.header_search_form_wrapper__3v7Fb {
  /* width: px; */
}

.header_search_mob_ver__Bvwnn {
  margin: auto;
  text-align: center;
  cursor: pointer;
}

.header_search_filter__1q9uP {
  margin: 10px;
}

.header_search_searchSection__2xyBe {
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  width: 400px;
  margin: auto;
  border-color: var(--border-color);
}

.header_search_search_form__2wxK9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}

.header_search_toShow__fSRCS {
  display: flex;
  flex-direction: row;
}

.header_search_inner__ZOgsJ {
  display: flex;
  flex-direction: row;
}

.header_search_title__23glN {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.header_search_wrapper__2O51Z .DateRangePickerInput {
  height: 100%;
  border: 0;
  border-radius: 28px 0 0 28px;
  overflow: hidden;
}

.header_search_wrapper__2O51Z .DateRangePicker {
  height: 100%;
}

.header_search_wrapper__2O51Z .DateRangePicker > div {
  height: 100%;
}

.header_search_wrapper__2O51Z .DateInput_input {
  /* height: 54px; */
  padding: 11px 24px 9px;
}

.header_search_wrapper__2O51Z .DateInput_input__focused {
  border-color: transparent;
}

.header_search_rangePicker__7nBC0 {
  margin-bottom: 0;
  height: 100%;
}

.header_search_wrapper__2O51Z .header_search_rangePicker__error__25Hcv .DateInput_input::-webkit-input-placeholder {
  color: #ff4d4f;
}

.header_search_wrapper__2O51Z .header_search_rangePicker__error__25Hcv .DateInput_input::placeholder {
  color: #ff4d4f;
}

.header_search_occupancyDropDown__2opxl {
  margin-bottom: 0;
}

.header_search_occupancyDropDown__2opxl .dropdown {
  height: 100%;
}

.header_search_occupancyDropDown__2opxl .dropdown-toggle {
  height: 100%;
  border: none;
  border-radius: 0;
  padding-right: 16px;
}

.header_search_occupancyDropDown__2opxl .btn-primary:not(:disabled):not(.disabled):focus {
  border: none;
}

.header_search_occupancyDropDown__2opxl .btn-primary.dropdown-toggle {
  width: 195px;
}

.header_search_occupancyDropDown__2opxl .show > .btn-primary.dropdown-toggle {
  border: none;
}

.header_search_occupancyDropDown__2opxl .dropdown-menu.show {
  width: 305px;
  max-height: 535px;
  overflow: auto;
  z-index: 999;
}

.header_search_searchBtn__d8gYP.btn-primary:hover,
.header_search_searchBtn__d8gYP.btn-primary:focus,
.header_search_searchBtn__d8gYP.btn-primary:active {
  transition: all 200ms ease;
  background: rgb(0 190 10 / 90%);
}

.header_search_searchBtn__d8gYP.btn-primary:not(:disabled):not(.disabled):active {
  background: rgb(0 190 10 / 90%);
}

.header_search_buttonStyle__1PlVi {
  display: none;
  color: white;
  border: none;
  border-radius: 20px;
  background-color: #00d100;
  text-decoration: none;
  text-align: center;
  padding: 10px 20px;
}

.header_search_drop__bbO3r {
  display: none;
}

.header_search_text_color__3vWst {
  color: #606060;
  font-family: 'Roboto', sans-serif;
}

.header_search_name__KZDEx {
  display: flex;
}

.header_search_logo__ym_Ns {
  display: none;
}

.header_search_logo_size__hKitv {
  height: 45px;
  width: 150px;
}

@media screen and (max-width: 1060px) {
  .header_search_inner__ZOgsJ {
    display: none;
  }

  .header_search_drop__bbO3r {
    display: block;
  }

  .header_search_occupancyDropDown__2opxl .dropdown-toggle {
    border: 1px solid;
    border-color: gray;
    padding: 10px;
  }
}

@media (max-width: 960px) {
  .header_search_toShow__fSRCS {
    display: none;
  }

  .header_search_buttonStyle__1PlVi {
    display: block;
  }
}

@media screen and (max-width: 570px) {
  .header_search_inner__ZOgsJ {
    display: none;
  }

  .header_search_logo__ym_Ns {
    display: flex;
  }

  .header_search_name__KZDEx {
    display: none;
  }
}

.best_offer_bestOffer__3g1VI {
  font-size: 16px;
  color: var(--hightlight-text-color);
}

.best_offer_bestOffer__3g1VI > *{
  margin-left: 8px;
}


.properties_wrapper__2pOu5 {
  flex: 1 1;
  background: #f9fbfd;
  height: calc(100vh - 56px);
}

.properties_list__1TNMY {
  padding: 20px;
  overflow: auto;
  max-height: 100%;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
}

.properties_listGrid__dvCeO {
  display: grid;
}

.properties_item__1XP3d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: #ffffff;
  box-shadow: 0 5px 20px 0 rgba(231, 238, 245, 0.77);
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  align-self: center;
  flex: 1 1;

  min-height: 458px;
}

.properties_item__1XP3d:hover .properties_overlay__1Go_m,
.properties_itemHighlighted__2fl1o .properties_overlay__1Go_m {
  display: block;
}

.properties_overlay__1Go_m {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  /* background-color: #0d70cd93; */
  opacity: 0.5;
  background-color: #a593c8;
  transition: all 200ms ease;
  pointer-events: none;
  z-index: 1;
}

.properties_previewBtnWrapper__IyZZ9 {
  height: 220px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.properties_imageWrapper__3aIBV {
  border-bottom: 1px solid #f1f8fe;
  height: 220px;
  position: relative;
  box-sizing: border-box;
}

.properties_previewButton__2tFgB.btn {
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 100px;
  background-color: transparent;
  padding: 6px 27px;
}

.properties_previewButton__2tFgB.btn:focus,
.properties_previewButton__2tFgB.btn:active,
.properties_previewButton__2tFgB.btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none;
  background-color: transparent;
  border-color: #fff;
}

.properties_image__duY2g {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.properties_emptyImage__3U364 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.properties_info__22Phk {
  padding: 25px 20px 20px 20px;
}

.properties_title__1aB0o {
  font-family: Helvetica;
  font-size: 20px;
  color: #232331;
}

.properties_description__9JqBq {
  font-family: Helvetica;
  font-size: 13px;
  color: #566879;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 56px;
  height: 56px;
}

.properties_footer__3qqGW {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f1f8fe;
  padding: 20px;
  box-sizing: border-box;
}

.properties_seeMoreLink__VvElf {
  font-family: Helvetica;
  font-size: 14px;
  color: #00d100;
  text-align: right;
}

.properties_seeMoreLink__VvElf:hover {
  text-decoration: none;
}

.properties_spinner__1NCwE {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 76px);
}

.properties_emptyWrapper__WtjFb {
  display: flex;
  width: 100%;
  height: calc(100vh - 76px);
  align-items: center;
  justify-content: center;
}

.properties_empty__1nMvJ {
  color: #232331;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.667;
}

.properties_search_no_res_list__3yKPK {
  list-style-type: disc;
}

@media screen and (max-width: 960px) {
  .properties_overlay__1Go_m {
    position: absolute;
    opacity: 0;
    background-color: #a593c8;
  }

  .properties_previewBtnWrapper__IyZZ9 {
    display: none;
  }
}

@media screen and (max-width: 560px) {
  .properties_listGrid__dvCeO {
    display: grid;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.properties_search_map_marker__3wRSB {
  color: #fff;
  background: #06bb24;
  border: 1px solid #45d45d;
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 14px;
  color: #ffffff;
  min-width: 80px;
  padding: 7px 4px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.properties_search_map_marker__3wRSB:hover,
.properties_search_map_markerHighlited__2tlkc {
  background: #45d45d;
  border: 1px solid #62dd77;
}

.properties_search_map_marker__3wRSB:hover .properties_search_map_markerContent__2c6sM {
  display: none;
}

.properties_search_map_marker__3wRSB:hover .properties_search_map_markerOverlay__3SH7w {
  display: initial;
}

.properties_search_map_markerOverlay__3SH7w {
  display: none;
}
.property_preview_wrapper__3RQ_r {
  overflow: auto;
  max-height: 100vh;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: #fff;
}

.property_preview_imageWrapper__33SdK {
  height: 400px;
  position: relative;
}

.property_preview_image__3q7du {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.property_preview_emptyImage__2cKXp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.property_preview_closeWrapper__1CuaH {
  display: flex;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #007bff;
  border-color: #007bff;
  padding: 6px 14px;
  border-radius: 0.25rem;
  align-items: center;
}

.property_preview_closeWrapper__1CuaH:hover {
  background-color: #0069d9;
  cursor: pointer;
}

.property_preview_closeIcon__2PTJw {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.property_preview_closeLabel__1FUcS {
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  margin: 0 0 0 5px;
  font-weight: 400;
}

.property_preview_header__3o5Ji {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #f7f9fc;
  border-bottom: 1px solid #f7f9fc;
  box-sizing: border-box;
}

.property_preview_headerInner__W54Ug {
  padding: 25px 30px;
}

.property_preview_title__S4FBJ {
  font-family: Helvetica;
  font-size: 28px;
  color: #232331;
  margin-bottom: 10px;
  line-height: 1.3;
}

.property_preview_address__1psGo {
  font-family: Helvetica;
  font-size: 12px;
  color: #939ea8;
  margin: 0;
  line-height: 1.5;
} 

.property_preview_seeMoreBtnWrapper__1NEDu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  border-left: 1px solid #f7f9fc;
}

.property_preview_seeMoreBtnWrapper__1NEDu .btn {
  padding: 7px 23px;
  margin-top: 8px;
  border-color: #fff;
  color: #fff;
  background-color: #00D100;
}


.property_preview_seeMoreBtnWrapper__1NEDu .btn:focus,
.property_preview_seeMoreBtnWrapper__1NEDu .btn:active,
.property_preview_seeMoreBtnWrapper__1NEDu .btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: none;
}

.property_preview_description__3y206 {
  font-family: Helvetica;
  font-size: 14px;
  color: #424242;
  text-align: left;
  line-height: 24px;
  padding: 25px 30px;
}

.search_page_wrapper__12-EH {
  display: flex;
  flex-direction: row;
  /* min-width: 1080px; */
}

.search_page_left__10YCV {
  width: 100%;
  height: 100%;
  max-width: 1400px;
}
 
.search_page_right__2sfp2 {
  width: 100%;
  position: relative;
}

@media (max-width: 960px){
  /* .right { 
    display: none;
  } */

  .search_page_wrapper__12-EH {
    display: flex;
    flex-direction: column;
  }
}
.checkbox_checkbox__2XyAT {
  display: none !important;
}

.checkbox_checkboxLabel__3iJJm {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;
  margin-bottom: 0;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox_checkIcon__2EK7_ {
  font-size: 16px;
}

.checkbox_group__FAo1c {
  display: flex;
  margin: 0;
}

.checkbox_label__250GW {
  margin-bottom: 0;
}

.policy_info_section_container__1X38x {
  padding-bottom: 8px;
}

.policy_info_section_title__1VXgd {
  line-height: 20px;
  font-weight: bold;
}
.policy_checkbox_checkboxContainer__1rRtb {
  margin: 8px 0;
}

.policy_checkbox_policyText__-maly a {
  text-decoration: underline;
}

.policy_checkbox_policyText__-maly * {
  text-transform: uppercase;
  line-height: inherit;
  font-size: inherit;
}

.policy_checkbox_policyText__-maly > * {
  display: inline-flex;
  margin-left: 4px;
}
.date_datesFromTo__UOhMg {
  width: 100%;
}

.date_dateTo__2kyOq,
.date_dateFrom__3dXid {
  height: 48px;
  padding-right: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.date_dateTo__2kyOq {
  padding-left: 8px;
}

.date_dateFrom__3dXid {
  border-right: 1px solid #e6e6e6;
}

.date_icon__1Hm76 {
  margin-top: -2px;
  padding-right: 8px;
}
.dates_datesWrapper__2bgl7 {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.property_photo_photoContainer__3BjXF {
  height: 200px;
}

.property_photo_picture__2B3SE {
  width: 100%;
  height: 100%;
  display: flex;
}

.property_photo_picture__2B3SE img {
  object-fit: cover; 
  height: auto;
  width:100%;
}
.summary_summaryMainWrapper__2PJ6E {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-color: var(--border-color);
  background-color: var(--background-light-color);
}

.summary_title__1d0XU {
  display: flex;
  height: 48px;
  padding: 6px 24px 0 24px;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid rgba(36,46,66,.12);
}

.summary_summaryWrapper__2Wxga {
  padding: 0 24px;
}

@media screen and (max-width: 991px) {
  .summary_summaryMainWrapper__2PJ6E {
      display: none;
  }
}
.booking_summary_summaryPoliciesWrapper__3tngD {
  padding: 0 24px 24px;
}
.nav_item_navItem__1-Gcq:not(:last-of-type)::after {
  content: '>';
}

.nav_item_navItem__1-Gcq:not(:last-of-type) {
  display: flex;
  align-items: center;
}

.nav_item_link__8QBul {
  padding: 4px;
  color: #000;
}

.nav_item_link__8QBul:hover {
  color: #a593c8 !important;
}

.nav_item_link__8QBul.nav-link.active {
  color: var(--button-color) !important;
  /* color: #000; */
  font-weight: bold;
}

.nav_item_link__8QBul.nav-link.active {
  /* color: var(--link-color); */
  color: #000 !important;
  background-color: initial !important;
}

.navigation_navigation__unRcd {
  padding: 16px 0;
}
.field_error_message__3xMIc {
  display: block;
  margin-top: -20px;
  padding: 0 8px;
  color: #f8412d;
  font-size: 11px;
  font-style: italic;
  text-align: right;
}
.formal_field_container__1_jIf {
  width: 100%;
}
.input_input__1-NLc {
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  padding-right: 8px;
  padding-left: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.25);
  border-radius: 2px;
  background-color: #fff;
  transition: all .2s ease;
}
.field_row_fieldRow__3RJDA {
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
  padding-left: 0;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 479px) {
  .field_row_fieldRow__3RJDA {
    flex-direction: column;
  }
}
.panel_panelTitle__3MgDz {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid rgba(36, 46, 66, 0.12);
}

.panel_panelContainer__xknV6 {
  margin-bottom: 24px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-radius: 2px;
  background-color: #fff;
}

.panel_children__1tFhd {
  padding-top: 16px;
}
.text_area_textArea__2D33X {
  width: 100%;
  height: auto;
  margin-bottom: 0;
  padding-right: 8px;
  padding-left: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0,0,0,.25);
  border-radius: 2px;
  background-color: #fff;
  transition: all .2s ease;
}
.guest_button__1W7XT {
  margin-top: 25px;
  width: 40px;
  height: 40px;
}

.guest_deleteIcon__3GWpe {
  font-size: 20px;
}
.submit_section_container__f19Te {
  padding-top: 16px;
  display: none;
}

@media screen and (min-width: 992px) {
  .submit_section_container__f19Te {
    display: block;
  }
}
.banner1_list__1qDis {
    list-style-type:square;
    font-size: 1.2rem;
    line-height: 25px;
}

.banner1_span_text__1PNJX {
    text-decoration: underline;
    font-weight: bold;
}

.banner1_margins__3sm8n {

}

@media screen and (max-width: 767px) {
    .banner1_margins__3sm8n {
        margin-top: 20rem;
    }
}
.banner1_page_footer__lY5QT {
    margin-top: 100px; 
}

.banner1_page_image__3UDt8 {
    height: 5rem;
}

.banner1_page_main_text__3u-Q- {
    margin-top: 5rem;
    width: 70%;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.banner1_page_heading__3Qo1j {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2rem;
}

.banner1_page_paragraph__1yytD {
    padding-top: 2rem;
    color: #fff;
    font-size: 1.5rem;
}

.banner2_list__177MR {
    list-style-type:square;
    font-size: 1.2rem;
    line-height: 25px;
}

.banner2_span_text__1gHns {
    text-decoration: underline;
    font-weight: bold;
}

.banner2_text_color__2ickx {
    color: 	#606060;
    font-family: 'Roboto', sans-serif;
    text-decoration: underline; 
}

.banner2_margins__258xo {

}

@media screen and (max-width: 767px) {
    .banner2_margins__258xo {
        margin-top: 20rem;
    }
}
.banner2_page_footer__1UjST {
    margin-top: 100px;
}

.not_found_message_container__1Mz7D {
  text-align: center;
}

.not_found_message_notFound__bruAo {
  font-size: 100px;
  line-height: 100px;
  font-weight: bold;
  margin-bottom: 36px;
}
.banner4_list__14uiV {
    list-style-type:square;
    font-size: 1.2rem;
    line-height: 25px;
}

.banner4_span_text__3zutE {
    text-decoration: underline;
    font-weight: bold;
}

.banner4_text_color__2CoLq {
    color: 	#606060;
    font-family: 'Roboto', sans-serif;
    text-decoration: underline; 
} 


.banner4_margins__1WFjw {

}

@media screen and (max-width: 767px) {
    .banner4_margins__1WFjw {
        margin-top: 20rem;
    }
}
.banner4_page_footer__3IFPy {
    margin-top: 100px;
}
 
.banner5_list__2eZ4c {
    list-style-type:square;
    font-size: 1.2rem;
    line-height: 25px;
}

.banner5_span_text__1nP67 {
    text-decoration: underline;
    font-weight: bold;
}

.banner5_text_color__nSlbB {
    color: 	#606060;
    font-family: 'Roboto', sans-serif;
    text-decoration: underline; 
} 

.banner5_margins___y2ij {

}

@media screen and (max-width: 767px) {
    .banner5_margins___y2ij {
        margin-top: 20rem;
    }
}
.banner5_page_footer__1CJWn {
    margin-top: 100px;
}
 
.banner3_list__2ordr {
    list-style-type:square;
    font-size: 1.2rem;
    line-height: 25px;
}

.banner3_span_text__1lYno {
    text-decoration: underline;
    font-weight: bold;
}

.banner3_text_color__MvLU1 {
    color: 	#606060;
    font-family: 'Roboto', sans-serif;
    text-decoration: underline; 
}
 
.banner3_line_height__2WKzJ {
    line-height: 20px;
}

.banner3_margins__U5e9L {

}

@media screen and (max-width: 767px) {
    .banner3_margins__U5e9L {
        margin-top: 20rem;
    }
}
.banner3_page_footer__1UWbs {
    margin-top: 100px;
}

.booking_container__1cFpF {
  display: flex;
  flex-direction: column;
  justify-content: center;
} 
.new_booking_link_container__23xoA {
  display: flex;
  justify-content: center;
} 
.thank_panel_confirmationNumber__20-Wg {
  font-family: Roboto,sans-serif;
  color: #8ac44a;
  font-size: 24px;
  line-height: 48px;
  font-weight: 400;
  letter-spacing: .27px;
  text-transform: uppercase;
} 

.thank_panel_container__3SnU3 {
  display: block;
  max-width: 1140px;
  padding-right: 16px;
  padding-left: 16px;
  flex-direction: column;
  align-items: stretch;
  margin: auto;
}

.thank_panel_panel__34NSF {
  display: flex;
  margin-bottom: 24px;
  padding: 24px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  background-color: #fff;
}
.routing_printButton__4DnzI {
    display: grid;
    margin: auto;
}
@-webkit-keyframes circleGrow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(5);
            transform: scale(5);
    opacity: 0;
  }
}
@keyframes circleGrow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(5);
            transform: scale(5);
    opacity: 0;
  }
}
@font-face {
  font-family: sans-serif;
  src: url(/static/media/Quicksand-Regular.6cbafd2c.ttf);
  src: url(/static/media/Quicksand-Light.10ce81d2.ttf);
  src: url(/static/media/Quicksand-Medium.db0ad2fc.ttf);
  src: url(/static/media/Quicksand-Regular.6cbafd2c.ttf);
  src: url(/static/media/Quicksand-SemiBold.9e775703.ttf);
}

html {
  font-size: 62.5%;
}

body {
  color: #2b2b2b;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.667;
  background: #ffffff;
  overflow-x: hidden;
}

.btcpay-form {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btcpay-form--inline {
  flex-direction: row;
}
.btcpay-form--block {
  flex-direction: column;
}
.btcpay-form--inline .submit {
  margin-left: 15px;
}
.btcpay-form--block select {
  margin-bottom: 10px;
}
.btcpay-form .btcpay-custom-container {
  text-align: center;
}
.btcpay-custom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btcpay-form .plus-minus {
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  background: #dfe0e1;
  height: 30px;
  width: 45px;
  border: none;
  border-radius: 60px;
  margin: auto 5px;
  display: inline-flex;
  justify-content: center;
}
.btcpay-form select {
  -webkit-appearance: none;
  appearance: none;
  color: currentColor;
  background: transparent;
  border: 1px solid transparent;
  display: block;
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
  font-size: 11px;
  cursor: pointer;
}
.btcpay-form select:hover {
  border-color: #ccc;
}
#btcpay-input-price {
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  text-align: center;
  font-size: 25px;
  margin: auto;
  border-radius: 5px;
  line-height: 35px;
  background: #fff;
}
#btcpay-input-price::-webkit-outer-spin-button,
#btcpay-input-price::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='range'].btcpay-input-range {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}
input[type='range'].btcpay-input-range:focus {
  outline: 0;
}
input[type='range'].btcpay-input-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 3.1px;
  cursor: pointer;
  box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
  background: #f3f3f3;
  border-radius: 1px;
  border: 0;
}
input[type='range'].btcpay-input-range::-webkit-slider-thumb {
  box-shadow: none;
  border: 2.5px solid #cedc21;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #0f3723;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9.45px;
}
input[type='range'].btcpay-input-range:focus::-webkit-slider-runnable-track {
  background: #fff;
}
input[type='range'].btcpay-input-range::-moz-range-track {
  width: 100%;
  height: 3.1px;
  cursor: pointer;
  box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
  background: #f3f3f3;
  border-radius: 1px;
  border: 0;
}
input[type='range'].btcpay-input-range::-moz-range-thumb {
  box-shadow: none;
  border: 2.5px solid #cedc21;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #0f3723;
  cursor: pointer;
}
input[type='range'].btcpay-input-range::-ms-track {
  width: 100%;
  height: 3.1px;
  cursor: pointer;
  background: 0 0;
  border-color: transparent;
  color: transparent;
}
input[type='range'].btcpay-input-range::-ms-fill-lower {
  background: #e6e6e6;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
}
input[type='range'].btcpay-input-range::-ms-fill-upper {
  background: #f3f3f3;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 0 1.7px #020, 0 0 0 #003c00;
}
input[type='range'].btcpay-input-range::-ms-thumb {
  box-shadow: none;
  border: 2.5px solid #cedc21;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #0f3723;
  cursor: pointer;
  height: 3.1px;
}
input[type='range'].btcpay-input-range:focus::-ms-fill-lower {
  background: #f3f3f3;
}
input[type='range'].btcpay-input-range:focus::-ms-fill-upper {
  background: #fff;
}

.modal-body-filter::-webkit-scrollbar {
  width: 8px;
}

ul.list-group.list-group-flush.pl-3 {
  padding: 0px !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  color: #222325;
}

a:hover {
  text-decoration: none;
  color: #4f02a4 !important;
}

.main-banner {
  min-height: 40rem;
  width: 100%;
  background-color: #fff;
  position: relative;
}
.main-banner img {
  height: 40rem;
  object-fit: cover;
  width: 100%;
  object-position: center;
}

.main-banner .carousel-caption {
  top: -5%;
}
/* global style start
============================================================================================ */
.vbg-default {
  background: #f5f5f5;
}

.vbg-default-hover:hover {
  background: #efefef;
  color: #000;
}

.h6 a {
  list-style: none;
  text-decoration: none;
  color: #333;
}

.mt-m-15 {
  margin-top: -15px !important;
}
.minus-mt-20 {
  margin-top: -20px;
}

.mt-m-30 {
  margin-top: -30px;
}

.minus-mt-6 {
  margin-top: -6px;
}

.mt-70 {
  margin-top: 7rem;
}

.mt-25 {
  margin-top: 25px;
}

/* padding */
.pt-70 {
  padding-top: 25px;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.filter-h {
  height: 100vh !important;
}

.min-w-250 {
  min-width: 250px;
}

.min-w-320 {
  min-width: 320px;
}

.max-w-450 {
  max-width: 500px;
}

.w-188 {
  width: 188px;
}
.intl-tel-input {
  width: 100% !important;
}
.pl-7,
.px-7 {
  padding-left: 4rem !important;
}

.border-ddd {
  border: 1px solid #ddd;
}

/************* footer icon css **********************/
.currency:hover,
.language-list:hover {
  background: #eeeeee8c;
  border-radius: 6px;
}
.currency-active {
  border-radius: 6px;
  border: 1px solid #000 !important;
}

.social {
  position: relative;
}

.social ul {
  text-align: center;
  z-index: 99;
}
.social li {
  display: inline-block;
  margin: 5px;
}
.active-sidebar {
  font-size: 14px;
  color: #4f02a4;
  font-weight: 700;
}

.active-sidebar:hover {
  color: #4f02a4;
}
/* z-index */
.z-index-high {
  z-index: 100000000;
}
.z-index-medium {
  z-index: 1000;
}
.z-index-low {
  z-index: 100;
}
.text-8 {
  font-size: 0.8rem;
}

.text-9 {
  font-size: 0.9rem;
}

.text-10 {
  font-size: 1rem;
}

.text-11 {
  font-size: 1.1rem;
}

.text-12 {
  font-size: 1.2rem;
}

.text-13 {
  font-size: 1.3rem;
}

.text-14 {
  font-size: 1.4rem;
}

.text-15 {
  font-size: 1.5rem;
}

.text-16 {
  font-size: 1.6rem;
}

.text-17 {
  font-size: 1.7rem;
}

.text-18 {
  font-size: 1.8rem;
}

.text-19 {
  font-size: 1.9rem;
}

.text-20 {
  font-size: 2rem;
}

.text-21 {
  font-size: 2.1rem;
}

.text-22 {
  font-size: 2.2rem;
}

.text-23 {
  font-size: 2.3rem;
}

.text-24 {
  font-size: 2.4rem;
}

.text-25 {
  font-size: 2.5rem;
}

.text-26 {
  font-size: 2.6rem;
}

.text-27 {
  font-size: 2.7rem;
}

.text-28 {
  font-size: 2.8rem;
}

.text-29 {
  font-size: 2.9rem;
}

.text-30 {
  font-size: 3rem;
}

.text-31 {
  font-size: 3.1rem;
}

.text-32 {
  font-size: 3.2rem;
}

.text-33 {
  font-size: 3.3rem;
}

.text-34 {
  font-size: 3.4rem;
}

.text-35 {
  font-size: 3.5rem;
}

.text-36 {
  font-size: 3.6rem;
}

.text-52 {
  font-size: 3.6rem;
}

.text {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-height-1-1 {
  line-height: 1 !important;
}
.line-height-1-4 {
  line-height: 1.4;
}

.line-height-1-5 {
  line-height: 1.5;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.text-color {
  color: #222325;
}

.secondary-text-color {
  color: #4f02a4;
}

.secondary-text-color-hover:hover {
  color: #fff !important;
}

.text-color-hover:hover {
  color: #4f02a4;
}

.primary-text {
  color: #007bff;
}

.primary-text-hover {
  color: #fff;
}

.warning-text {
  color: #ffc107;
}

.warning-text-hover:hover {
  color: #fff;
}

.success-text {
  color: #4f02a4;
}

.step-inactive {
  background: #dfdbd2;
}

.listingbg {
  margin-bottom: 15px;
  margin-top: 15px;
}

.printareabg p {
  margin: 0;
  font-size: 14px;
}

.printareabg a {
  font-size: 14px;
}

.success-text-hover {
  color: #fff;
}

.danger-text {
  color: #dc3545;
}

.danger-text-hover {
  color: #fff;
}

.dark-text {
  color: #333333;
}

.dark-text-hover {
  color: #fff;
}

.light-text {
  color: #f8f6f7;
}

.light-text-hover {
  color: #333333;
}

.gray-text {
  color: #707070;
}

.gray-text-hover {
  color: #c0c0c0;
}

.text-muted {
  color: #4f02a4 !important;
}

.vbadge-success {
  background: #4f02a4;
  color: #fff;
}

/* background color */
.secondary-bg {
  background: #4f02a4;
  color: #fff;
}

.btn-photo {
  background: #4f02a4;
  color: #fff;
}

.btn-photo:hover {
  background: #671bbc;
  color: #fff;
}

/* status color */
.Pending {
  background: #62646a;
  color: #fff;
}

.Expired {
  background: #f92525;
  color: #fff;
}

.Accepted {
  background: #28a745;
  color: #fff;
}

.sangvish-button {
  cursor: pointer;
  border: 0;
  padding: 0.8rem;
  position: relative;
  overflow: hidden;
}
.ui-datepicker .ui-datepicker-next {
  right: 15px;
}
.ui-datepicker .ui-datepicker-left {
  left: 20px;
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 3px;
  width: 1.8em;
  height: 1.8em;
}
button:focus {
  border: 0;
  outline-color: transparent;
}

.sangvish-button span {
  display: block;
  position: relative;
  z-index: 1;
}

.sangvish-button span::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: calc(50% - 1.5rem);
  left: calc(50% - 1.5rem);
  background: rgba(255, 255, 255, 0.7);
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
}

button.vbtn-default,
.btn.vbtn-default {
  background: #4f02a4;
  color: #fff !important;
  font-weight: 700;
}
.btn.vbtn-default:hover {
  color: #fff !important;
}
button.vbtn-default:hover {
  background: #a593c8;
  color: #fff;
}

button.vbtn-primary {
  background: #4f02a4;
  color: #fff;
}

button.vbtn-primary:hover {
  background: #a593c8;
  color: #fff;
}

button.vbtn-warning {
  background: #ffc107;
  color: #fff;
}

button.vbtn-warning:hover {
  background: #e0a800;
  color: #c7c2c2;
}

button.vbtn-success {
  background: #4f02a4;
  color: #fff;
}

button.vbtn-success:hover {
  background: #4f02a4;
  color: #c7c2c2;
}

button.vbtn-danger {
  background: #dc3545;
  color: #fff;
}

button.vbtn-danger:hover {
  background: #c82333;
  color: #c7c2c2;
}

button.vbtn-dark {
  background: #333333;
  color: #fff;
}

button.vbtn-dark:hover {
  background: #23272b;
  color: #c7c2c2;
}

button.vbtn-light {
  background: #f8f6f7;
  color: #3a3a3a;
}

button.vbtn-light:hover {
  background: #e0e0e0;
  color: #333333;
}

.button-default {
  border-radius: 0.5rem;
}

.button-reactangular {
  border-radius: 0rem;
}

.button-rounded {
  border-radius: 5rem;
}

.button-rounded-4 {
  border-radius: 0.4rem;
}

.vbtn-outline-success {
  color: #4f02a4;
  border-color: #4f02a4;
}

.vbtn-outline-success:hover {
  color: #ffffff;
  border-color: #4f02a4;
  background: #4f02a4;
}

a.vbtn-outline-success:hover {
  color: #ffffff !important;
  border-color: #4f02a4;
  background: #4f02a4;
}

.btn-outline-secondary:hover {
  color: #ffffff !important;
}

.border-r-10 {
  border-radius: 10px;
}

.border-r-25 {
  border-radius: 25px;
}
ul.customlisting {
  margin-top: 8px;
}

ul.customlisting li a {
  width: 100%;
}

ul.customlisting li {
  line-height: 54px;
}

.rounded-4 {
  border-radius: 0.4rem;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.sangvish-button:focus:not(:active) span::after {
  -webkit-animation: circleGrow 0.3s linear;
          animation: circleGrow 0.3s linear;
}

.list-background {
  background: #fff;
}

.p-15 {
  padding: 5rem;
}
.mt-80 {
  margin-top: 8rem;
}
.intl-tel-input input,
.intl-tel-input input[type='text'],
.intl-tel-input input[type='tel'] {
  padding-left: 9.8rem !important;
}

/******************** Toggle Switch ****************/

.main-toggle-switch span {
  font-family: 'Quicksand', sans-serif;
  font-size: 12px;
}

/*  Toggle Switch  */

.toggleSwitch span span {
  display: none;
}

@media only screen {
  .toggleSwitch {
    display: inline-block;
    height: 18px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 50px;
    cursor: pointer;
    width: 80px;
  }

  .toggleSwitch * {
    box-sizing: border-box;
  }

  .toggleSwitch label,
  .toggleSwitch > span {
    line-height: 20px;
    height: 20px;
    vertical-align: middle;
  }

  .toggleSwitch input:focus ~ a,
  .toggleSwitch input:focus + label {
    outline: none;
  }

  .toggleSwitch label {
    position: relative;
    z-index: 3;
    display: block;
    width: 100%;
  }

  .toggleSwitch input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }

  .toggleSwitch > span {
    position: absolute;
    left: -50px;
    width: 100%;
    margin: 0;
    padding-right: 50px;
    text-align: left;
    white-space: nowrap;
  }

  .toggleSwitch > span span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: block;
    width: 100%;
    margin-left: 50px;
    text-align: left;
    font-size: 0.9em;
    width: 100%;
    left: -55%;
    top: 1px;
    opacity: 0;
  }

  .toggleSwitch a {
    position: absolute;
    right: 50%;
    z-index: 4;
    display: block;
    height: 100%;
    padding: 0;
    top: 2px;
    left: -45px;
    width: 18px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    transition: all 0.2s ease-out;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .toggleSwitch > span span:first-of-type {
    color: #888888;
    opacity: 1;
    left: 45%;
  }

  .toggleSwitch > span:before {
    content: '';
    display: block;
    width: 120px;
    height: 35px;
    position: absolute;
    left: 0px;
    top: -2px;
    background-color: #efefef;
    border: 1px solid #efefef;
    border-radius: 30px;
    transition: all 0.2s ease-out;
  }

  .toggleSwitch input:checked ~ a {
    border-color: #fff;
    left: 100%;
    margin-left: -8px;
  }

  .toggleSwitch input:checked ~ span:before {
    border-color: #4f02a4;
    box-shadow: inset 0 0 0 30px #25a402;
  }

  .toggleSwitch input:checked ~ span span:first-of-type {
    opacity: 0;
  }

  .toggleSwitch input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }

  /* Switch Sizes */
  .toggleSwitch.large {
    width: 60px;
    height: 27px;
  }

  .toggleSwitch.large a {
    width: 27px;
  }

  .toggleSwitch.large > span {
    height: 29px;
    line-height: 28px;
  }

  .toggleSwitch.large input:checked ~ a {
    left: 47px;
    top: 2px;
  }

  .toggleSwitch.large > span span {
    font-size: 1.1em;
    font-weight: 600;
  }

  .toggleSwitch.large > span span:first-of-type {
    left: 0%;
  }

  .toggleSwitch.xlarge {
    width: 80px;
    height: 36px;
  }

  .toggleSwitch.xlarge a {
    width: 36px;
  }

  .toggleSwitch.xlarge > span {
    height: 38px;
    line-height: 37px;
  }

  .toggleSwitch.xlarge input:checked ~ a {
    left: 52px;
  }

  .toggleSwitch.xlarge > span span {
    font-size: 1.4em;
  }

  .toggleSwitch.xlarge > span span:first-of-type {
    left: 50%;
  }
}

/*  End Toggle Switch  */

/* global style end
============================================================================================ */
/* header style start
============================================================================================ */
.header_area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: background 0.4s, all 0.3s linear;
  z-index: 900;
}

.header_area .navbar-right {
  min-width: 20rem;
  text-align: right;
}

.header_area .navbar-brand {
  margin-right: 3rem;
  padding: 0;
}

.header_area .navbar-social {
  min-width: 9.5rem;
  float: right;
}

.header_area .navbar-social li {
  margin-right: 1.2rem;
}

.header_area .navbar-social li i,
.header_area .navbar-social li span {
  font-size: 1.3rem;
}

.header_area .navbar-social li:last-child {
  margin-right: 0;
}

.header_area .navbar-social li a:hover i,
.header_area .navbar-social li span {
  color: #4f02a4;
}

.header_area .menu_nav {
  width: 100%;
}

.header_area .navbar {
  background: transparent;
  border: 0px;
  border-radius: 0px;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.header_area .navbar .nav-right {
  min-width: 20rem;
}

.header_area .navbar .nav .nav-item {
  margin-right: 3.5rem;
}
.header_area .navbar .nav .nav-item .nav-link {
  color: #fff;
  padding: 3rem 0;
  display: inline-block;
  font-size: 1.5rem;
}

.header_area .navbar .nav .nav-item:hover .nav-link,
.header_area .navbar .nav .nav-item.active .nav-link {
  color: #4f02a4;
  opacity: 1;
}

.header_area .navbar .nav .nav-item.submenu {
  position: relative;
}

.header_area .navbar .nav .nav-item.submenu ul {
  border: none;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  background: #fff;
}

.header_area .navbar .nav .nav-item.submenu ul:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1rem 1rem 0 1rem;
  border-color: #eeeeee transparent transparent transparent;
  position: absolute;
  right: 2.4rem;
  top: 4.5rem;
  z-index: 3;
  opacity: 0;
  transition: all 400ms linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
  display: block;
  float: none;
  margin-right: 0px;
  border-bottom: 0.1rem solid #ededed;
  margin-left: 0px;
  transition: all 0.4s linear;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
  line-height: 4.5rem;
  color: #6059f6 !important;
  padding: 0px 3rem;
  transition: all 150ms linear;
  display: block;
  margin-right: 0px;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
  border-bottom: none;
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
  margin-top: 0px;
}

.header_area .navbar .nav .nav-item:last-child {
  margin-right: 0px;
}

.header_area .navbar .search {
  font-size: 1.2rem;
  line-height: 6rem;
  display: inline-block;
  margin-left: 8rem;
}

.header_area .navbar .search i {
  font-weight: 600;
}

.header_area.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -7rem;
  left: 0;
  right: 0;
  z-index: 99;
  -webkit-transform: translateY(7rem);
          transform: translateY(7rem);
  transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease, -webkit-transform 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0rem 0.3rem 1.6rem 0rem rgba(0, 0, 0, 0.1);
  background: #fff;
}

.header_area.navbar_fixed .main_menu .navbar {
  background: #ffffff;
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
  color: #000;
}

.header_area.white_menu .navbar .nav .nav-item .nav-link {
  color: #fff;
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
  display: inline-block;
}

.header_area.white_menu.navbar_fixed
  .main_menu
  .navbar
  .navbar-brand
  img
  + img {
  display: none;
}

.header_area.white_menu.navbar_fixed
  .main_menu
  .navbar
  .nav
  .nav-item
  .nav-link {
  line-height: 7rem;
}

.top_menu {
  background: #ec9d5f;
}

.top_menu .float-left a {
  line-height: 4rem;
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  margin-right: 5rem;
  transition: all 0.3s ease 0s;
}

.top_menu .float-left a:last-child {
  margin-right: 0px;
}

.top_menu .float-right .pur_btn {
  color: #fff;
  line-height: 4rem;
  display: block;
  padding: 0px 4rem;
  font-weight: 500;
  font-size: 1.2rem;
}

/* Image size */
.list-photo {
  width: 100%;
  max-height: 180px;
}

.user-photo {
  width: 60px;
  height: 60px;
}

.img-40x40 {
  width: 40px;
  height: 40px;
}

.img-130x32 {
  width: 130px;
  height: 40px;
}

.img-60x60 {
  width: 60px;
  height: 60px;
}

.border-radius-50p {
  border-radius: 50%;
}

.img-70x70 {
  width: 70px;
  height: 70px;
}

.img-90x90 {
  width: 90px;
  height: 90px;
}

.img-100x100 {
  width: 100px;
  height: 100px;
}

.img-tpl {
  max-height: 150px;
  width: 100%;
}

.w-450 {
  width: 450px;
}

.min-h-300 {
  min-height: 300px;
}

.v-h-100 {
  height: 100vh;
}

/* header style end*/
@media (max-width: 1619px) {
  /* Main Menu Area css */
  .header_area .navbar .search {
    margin-left: 4rem;
  }

  /* End Main Menu Area css*/
}

@media (max-width: 1199px) {
  /* Main Menu Area css*/
  .header_area .navbar .nav .nav-item {
    margin-right: 2.8rem;
  }

  /* End Main Menu Area css */
  /* Home Banner Area css*/
  .home_banner_area .banner_inner {
    padding: 10rem 0rem;
  }

  /* End Home Banner Area css */
  .header_area .navbar .tickets_btn {
    margin-left: 4rem;
  }

  .home_banner_area .banner_inner .banner_content h2 br {
    display: none;
    font-size: 3.6rem;
  }

  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 3.6rem;
    line-height: 2rem;
  }
}

@media (max-width: 991px) {
  /* Main Menu Area css */
  .navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 12px;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 2.3rem;
    background: #f7f7f7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }

  .header_area .navbar {
    background: #ffffff;
  }

  .header_area .navbar .nav-item.active .nav-link {
    color: #6059f6 !important;
  }

  .navbar-toggler[aria-expanded='false'] span:nth-child(2) {
    opacity: 1;
  }

  .navbar-toggler[aria-expanded='true'] span:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggler[aria-expanded='true'] span:first-child {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    position: relative;
    top: 0.75rem;
  }

  .navbar-toggler[aria-expanded='true'] span:last-child {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    bottom: 0.6rem;
    position: relative;
  }

  .navbar-toggler span {
    display: block;
    width: 1.7rem;
    height: 0.2rem;
    background: #000000;
    margin: auto;
    margin-bottom: 0.4rem;
    transition: all 400ms linear;
    cursor: pointer;
  }

  .navbar .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .nav {
    padding: 0px 0px;
  }

  .header_top .nav {
    padding: 0px;
  }

  .header_area .navbar .nav .nav-item .nav-link {
    padding: 1rem 0;
    margin-right: 0px;
    display: block;
    border-bottom: 0.1rem solid #ededed33;
    border-radius: 0px;
  }

  .header_area .navbar .search {
    margin-left: 0px;
  }

  .header_area .navbar-collapse {
    max-height: 34rem;
    overflow-y: scroll;
  }

  .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    padding: 0px 1.5rem;
  }

  .header_area .navbar .nav .nav-item {
    margin-right: 0px;
  }

  .home_banner_area .banner_inner .banner_content .banner_map_img {
    display: none;
  }

  .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 4rem;
  }

  .header_area.white_menu.navbar_fixed
    .main_menu
    .navbar
    .nav
    .nav-item
    .nav-link {
    line-height: 4rem;
  }

  .header_area.white_menu .navbar .nav .nav-item .nav-link {
    color: #2b2b2b;
  }

  /* End Main Menu Area css */
  /* Blog page Area css */
  .categories_post img {
    width: 100%;
  }

  .categories_post {
    max-width: 36rem;
    margin: 0 auto;
  }

  .blog_categorie_area .col-lg-4 {
    margin-top: 3rem;
  }

  .blog_area {
    padding-bottom: 8rem;
  }

  .single-post-area .blog_right_sidebar {
    margin-top: 3rem;
  }

  /* End Blog page Area css */
  /* Contact Page Area css */
  .contact_info {
    margin-bottom: 5rem;
  }

  /* End Contact page Area css*/
  .home_banner_area .donation_inner {
    margin-bottom: -3rem;
  }

  .home_banner_area .dontation_item {
    max-width: 35rem;
    margin: auto;
  }

  .home_banner_area .banner_inner .home_left_img {
    display: none;
  }

  .header_area .navbar .tickets_btn {
    display: none;
  }

  .home_banner_area .banner_inner {
    padding: 7rem 0rem 10rem 0rem;
  }

  .left_side_text {
    margin-bottom: 5rem;
  }

  .price_item {
    max-width: 36rem;
    margin: 0rem auto 3rem;
  }

  .price_inner {
    margin-bottom: -3rem;
  }

  /* .main-banner .carousel-caption{
        top:12%;
    } */

  .banner-title {
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    text-shadow: 3px 0px 0px #403e3e;
  }
}

@media (max-width: 767px) {
  .sv_mob_map {
    visibility: hidden;
  }
  body {
    padding-bottom: 6rem;
  }
  .business-sec .card-1 {
    height: 250px !important;
  }
  /* #footer{
        display:none;
    } */
  #map-view .gmnoprint {
    bottom: 311px;
    left: 0;
  }
  .modal-full {
    min-width: 100%;
    margin: 0;
  }
  .modal-full .modal-content {
    min-height: 100vh;
  }
  #map-btn,
  #res-btn {
    position: fixed;
    bottom: 38px;
    white-space: nowrap;
    left: 50%;
    transform: translateX(-50%) !important;
    -webkit-transform: translateX(-50%) !important;
    -moz-transform: translateX(-50%) !important;
    transition: -webkit-transform 0.2s ease-in-out 0s, transform 0.2s ease-in-out 0s !important;
    -webkit-transition: -ms-transform 0.2s ease-in-out 0s,
      -webkit-transform 0.2s ease-in-out 0s, transform 0.2s ease-in-out 0s !important;
    -moz-transition: -ms-transform 0.2s ease-in-out 0s,
      -webkit-transform 0.2s ease-in-out 0s, transform 0.2s ease-in-out 0s !important;
    padding: 0.7rem 3rem !important;
    background: #222;
    color: #fff;
    outline: none;
    border: none;
    border-radius: 24px;
    font-size: 15px;
    z-index: 15;
    font-weight: 600;
  }

  .map-switch {
    display: none !important;
  }

  .modal-backdrop {
    background: rgba(255, 255, 255, 0.1) !important;
  }
  .user-login-bg {
    display: none;
  }
  .user-login {
    grid-template-columns: 100% !important;
  }
  .foot-padding {
    padding-bottom: 60px !important;
  }
  .modal-backdrop {
    z-index: auto;
  }
  .header_area {
    z-index: auto;
  }
  .property-title .svwishlist:hover,
  .share-option:hover {
    background: none !important;
  }
  .property-title .svwishlist,
  .share-option {
    padding: 5px 7px !important;
  }
  .mob-hide {
    display: none;
  }
  .property-title .pull-right i {
    color: #fff;
    font-weight: 700;
  }
  .home_banner_area {
    min-height: 50rem;
  }

  .home_banner_area .banner_inner {
    min-height: 50rem;
  }

  .home_banner_area .banner_inner .banner_content {
    margin-top: 0rem;
  }
  .modal.left .modal-dialog {
    width: 100% !important;
  }
  .sv_third_col {
    width: 40% !important;
  }
  /* Blog Page Area css*/
  .blog_banner .banner_inner .blog_b_text h2 {
    font-size: 4rem;
    line-height: 2rem;
  }

  .blog_info.text-right {
    text-align: left !important;
    margin-bottom: 1rem;
  }

  /* End Blog Page Area css*/
  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 3rem;
  }

  .home_banner_area .banner_inner .banner_content p br {
    display: none;
  }

  .home_banner_area .banner_inner .banner_content h3 span {
    line-height: 4.5rem;
    padding-bottom: 0rem;
    padding-top: 0rem;
  }

  .made_life_inner .nav.nav-tabs li {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 1.5rem;
  }

  .made_life_inner .nav.nav-tabs {
    margin-bottom: -1.5rem;
  }

  .made_life_area.made_white .left_side_text {
    margin-bottom: 0rem;
    margin-top: 3rem;
  }
  .main-banner .carousel-caption {
    top: 12%;
  }
}

@media (max-width: 575px) {
  .top_menu {
    display: none;
  }

  /* Home Banner Area css */
  .home_banner_area .banner_inner .banner_content h2 {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }

  .home_banner_area {
    min-height: 45rem;
  }

  .home_banner_area .banner_inner {
    min-height: 45rem;
  }

  .blog_banner .banner_inner .blog_b_text {
    margin-top: 0rem;
  }

  .home_banner_area .banner_inner .banner_content img {
    display: none;
  }

  .home_banner_area .banner_inner .banner_content h5 {
    margin-top: 0rem;
  }

  /* End Home Banner Area css*/
  .p_120 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .main_title h2 {
    font-size: 2.5rem;
  }

  /* Elements Area css */
  .sample-text-area {
    padding: 7rem 0 7rem 0;
  }

  .generic-blockquote {
    padding: 3rem 1.5rem 3rem 3rem;
  }

  /* End Elements Area css*/
  /* Blog Page Area css */
  .blog_details h2 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .blog_banner .banner_inner .blog_b_text h2 {
    font-size: 2.8rem;
    line-height: 3.8rem;
  }

  /* End Blog Page Area css */

  .pad_top {
    padding-top: 7rem;
  }

  .pad_btm {
    padding-bottom: 7rem;
  }

  .main-banner .carousel-caption {
    top: 12%;
  }
}

@media (max-width: 480px) {
  /* Main Menu Area css
    ============================================================================================ */
  .header_area .navbar-collapse {
    max-height: 25rem;
  }

  /* End Main Menu Area css
    ============================================================================================ */
  /* Home Banner Area css
    ============================================================================================ */
  .home_banner_area .banner_inner .banner_content {
    padding: 3rem 1.5rem;
    margin-top: 0px;
  }

  .banner_content .white_btn {
    display: block;
  }

  .home_banner_area .banner_inner .banner_content h3 {
    font-size: 2.4rem;
  }

  /* End Home Banner Area css
    ============================================================================================ */
  .banner_area .banner_inner .banner_content h2 {
    font-size: 3.2rem;
  }

  /* Blog Page Area css
    ============================================================================================ */
  .comments-area .thumb {
    margin-right: 1rem;
  }

  /* End Blog Page Area css
    ============================================================================================ */
  .testi_item .media .d-flex {
    padding-right: 0rem;
  }

  .testi_item .media .d-flex img {
    width: auto;
    margin-bottom: 1.5rem;
  }

  .testi_item .media {
    display: block;
  }

  .banner_btn {
    margin-bottom: 2rem;
  }

  .main-banner .carousel-caption {
    top: 5%;
  }
}

/* banner section start
============================================================================================ */
.hero-banner h1 {
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
}
@media (max-width: 1200px) {
  .hero-banner h1 {
    font-size: 2.8rem;
  }
}

@media (max-width: 1080px) {
  .header_area .navbar .nav .nav-item {
    margin-right: 1rem;
  }
}
@media (max-width: 991px) {
  .hero-banner h1 {
    font-size: 2.8rem;
  }
}
.hero-banner-sm {
  /* background: url(../img/banner/banner.png) left center no-repeat; */
  background-size: cover;
  padding-top: 14rem;
  padding-bottom: 9rem;
}

@media (min-width: 992px) {
  .hero-banner-sm {
    padding-top: 24rem;
    padding-bottom: 14rem;
  }
}

.hero-banner-sm h1 {
  text-transform: uppercase;
  color: #6059f6;
}

@media (max-width: 991px) {
  .hero-banner-sm h1 {
    font-size: 3.8rem;
  }
}

@media (max-width: 768px) {
  .hero-banner-sm h1 {
    font-size: 2.8rem;
  }
}

@media (min-width: 768px) {
  .addi-guest {
    margin-top: -32px;
  }

  .modal-body-filter {
    max-height: calc(100vh - 20.5rem) !important;
    overflow-y: auto;
  }

  .homenav.navbar_fixed #front-search-form1 {
    display: block;
  }
  .homenav #front-search-form1 {
    display: none;
  }
  .desk-search-form {
    position: absolute;
    width: 100%;
    top: 12%;
    z-index: 2;
    left: 0;
    right: 0;
  }
  #photos div:hover {
    cursor: pointer;
  }
  #front-search-form .form-control,
  #front-search-form1 .form-control {
    padding: 0 !important;
  }
  #start-sec .operators-page__how__item[data-v-3601cef9]:nth-child(2) {
    flex-direction: row;
  }
  #start-sec .operators-page__how__index {
    display: none;
  }
}

.hero-banner-sm p {
  margin-bottom: 0;
}

.hero-banner-sm-content {
  max-width: 40rem;
}

.bg-shape {
  position: relative;
}

@media (max-width: 620px) {
  .bg-shape::before {
    display: none;
  }
}

@media (max-width: 620px) {
  .bg-shape::after {
    display: none;
  }
}

/* banner section end
============================================================================================ */
/* breadcrumb section start
============================================================================================ */
.banner-breadcrumb {
  display: inline-block;
}

.banner-breadcrumb .breadcrumb {
  background: transparent;
  padding: 0;
}

.banner-breadcrumb .breadcrumb-item {
  padding: 0.1rem;
}

.banner-breadcrumb .breadcrumb-item a {
  text-transform: capitalize;
  color: #fff;
}

.banner-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  padding-left: 0.2rem;
  padding-right: 0.4rem;
}

.banner-breadcrumb .breadcrumb-item.active {
  color: #fff;
}

/*=================== custom button rule start ====================*/
.button {
  display: inline-block;
  background-color: #4f02a4;
  color: #fff;
  /*border-radius: 30px;*/
  padding: 0.8rem 2.5rem;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  border-radius: 3px;
}

.button:hover {
  background-color: #4f02a4;
  color: #fff;
}

.button-hero {
  font-weight: 500;
}

.button-contactForm {
  border-radius: 0.5rem;
}

.button-shadow {
  box-shadow: 0rem 1rem 2rem 0rem rgba(181, 176, 255, 0.2);
}

button.button {
  border: 0;
}

/*********************** Image Hover CSS *****************/
.content {
  margin: 0 auto;
  max-width: 100rem;
}

.content > h2 {
  clear: both;
  margin: 0;
  padding: 4em 1% 0;
  color: #484b54;
  font-weight: 700;
  font-size: 1.5em;
}

.content > h2:first-child {
  padding-top: 0em;
}

/* Header */
.codrops-header {
  margin: 0 auto;
  padding: 4em 1em;
  text-align: center;
}

.codrops-header h1 {
  margin: 0;
  font-weight: 700;
  font-size: 4em;
  line-height: 1.3;
}

.codrops-header h1 span {
  display: block;
  padding: 0 0 0.6em 0.1em;
  color: #74777b;
  font-weight: 300;
  font-size: 45%;
}

/* Demo links */
/* To Navigation Style */
/* Related demos */
@media screen and (max-width: 25em) {
  .codrops-header {
    font-size: 75%;
  }

  .codrops-icon span {
    display: none;
  }
}

/* Common style */
.grid figure img {
  max-width: 50%;
  float: left;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 15px;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}
/* For some effects it will show as a button */
.grid figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  word-spacing: -0.15em;
  font-weight: 300;
}
.grid figure h2 span {
  font-weight: 700;
}
.grid figure h2,
.grid figure p {
  margin: 0;
}
.grid figure p {
  line-height: 70px;
}
.section-intro {
  max-width: 64rem;
  margin-right: auto;
  margin-left: auto;
}

.section-intro.text-center.pb-90px h2 {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 3rem;
}

.section-padding {
  padding: 30px 0;
}

/*---------------*/
/***** Ming *****/
/*---------------*/
figure.effect-ming p a {
  color: #fff;
}

figure.effect-ming p a:hover {
  color: #4f02a4;
}

figure.effect-ming:hover h2 {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  display: none;
}
/************************ Form HTML ********************/
.main_formbg {
  background: #fff;
  border-radius: 50px;
  width: 100%;
  padding: 0.3rem;
  box-shadow: rgb(0 0 0 / 15%) 0px 16px 32px, rgb(0 0 0 / 10%) 0px 3px 8px !important;
}

div.elem-group {
  margin: 1rem 0;
  font-size: 1.4rem;
}

div.elem-group.inlined {
  width: 49%;
  display: inline-block;
  float: left;
  margin-left: 1%;
}

.selectbg {
  width: 49%;
  display: inline-block;
  float: left;
  margin-left: 1%;
}

.selectbg label {
  margin: 0rem 0 0.4rem 0 !important;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.4rem;
  color: #2b2b2b;
}

.elem-group.inlined label {
  margin: 0 0 0.5rem 0 !important;
  font-size: 1.4rem;
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  color: #2b2b2b;
}

.elem-group label {
  margin: 0 0 0.5rem 0;
  text-transform: uppercase;
  font-weight: 700;
  color: #2b2b2b;
}

input,
select,
textarea {
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 1.3rem;
  padding: 1rem 1rem 1rem 1rem;
}

.selectdrop {
  background: #eee;
  padding: 15px 0 15px 15px;
  border-radius: 3px;
  margin: 15px 0 15px 0;
}

input.checkinout {
  padding: 12px !important;
  height: 40px;
}

input:focus,
select:focus,
textarea:focus {
  outline: 1px solid #4f02a4;
}

div.elem-group.inlined input {
  width: 100%;
  display: inline-block;
}

.booking button {
  background: #4f02a4;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  margin-top: 3rem;
  padding: 1rem;
  width: 100%;
  border-radius: 0.3rem;
  text-transform: uppercase;
  font-weight: 700;
}

.booking button:hover {
  background: #4f02a4;
}

/******************** Reccommended *******************/
.imagebg {
  overflow: hidden;
  margin-bottom: 2rem;
}

.imagebg ul {
  margin: 0;
  padding: 0;
}

ul.bottom_text li {
  font-size: 1.4rem;
  list-style: none;
  display: inline;
  color: #2b2b2b;
  padding: 0 1rem 0 0;
}

.search_amini {
  background: #dadada;
  padding-left: 20px;
  padding-right: 20px;
}

.imagebg ul li {
  list-style: none;
  display: inline;
}

.imagebg ul li span {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #2b2b2b;
  font-weight: 700;
}

.imagebg ul li span div.price {
  font-size: 1.3rem;
  color: #777;
  font-weight: 700;
}

.imagebg img {
  width: 100%;
}

.imagebg2 {
  background-color: #000;
  margin-bottom: 0.5rem;
}

.imagebg2 img {
  transition: 300ms;
}

.imagebg2:hover img {
  opacity: 0.4;
}

.buttonbg button {
  background: #4f02a4;
  border: none;
  color: #fff;
  padding: 0.4rem 1rem 0.4rem 1rem;
  margin-top: 0.7rem;
  float: left;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
}
.buttonbg button:hover {
  background: #4f02a4;
}
.pint_table {
  overflow: hidden;
  width: 100%;
  margin-top: 0.5rem;
}
.left_point {
  float: left;
  width: 48%;
}
.right_point {
  float: right;
  width: 48%;
}
.right_point h2 {
  font-size: 1.9rem;
  text-align: right;
  margin-top: 0.3rem;
}
.right_point h2 strong {
  color: #4f02a4;
}
.right_point h2 span {
  font-size: 1.5rem;
}
.left_point ul li {
  list-style: none;
  display: inline;
  font-size: 1.3rem;
}

.left_point ul li i:hover {
  color: #4f02a4;
}

/*---------------*/
/***** Milo *****/
/*---------------*/
figure.effect-milo {
  background: #2e5d5a;
}

figure.effect-milo img {
  max-width: none;
  width: calc(100% + 6rem);
  opacity: 1;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-3rem, 0, 0) scale(1.12);
  transform: translate3d(-3rem, 0, 0) scale(1.12);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-milo:hover img {
  opacity: 0.5;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

figure.effect-milo h2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 1em 1.2em;
}

figure.effect-milo p {
  padding: 0 1rem 0 0;
  width: 50%;
  border-right: 1rem solid #fff;
  text-align: right;
  opacity: 0;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(-4rem, 0, 0);
  transform: translate3d(-4rem, 0, 0);
}

figure.effect-milo:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.full_title {
  float: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.price {
  padding: 0 0 0 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.5rem;
}

.full_title h2 {
  font-size: 1.8rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

ul.bottom_text {
  background: #dadada;
  overflow: hidden;
  width: 100%;
  padding: 0.3rem 0 0.3rem 1.2rem;
  border-radius: 0.3rem;
}

.profile-img img {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
}

/*************************** Testimonial CSS **********************************/
.testimonials {
  padding-bottom: 2.2em;
  text-align: center;
  color: #666;
}

.testimonials h1 {
  color: #2b2b2b;
  font-size: 1.3em;
}

.testimonials .test-body {
  padding: 1em;
}

.testimonials .item {
  background: #fff;
  padding: 5rem 3rem 5rem 3rem;
  border-radius: 0.4rem;
  box-shadow: 0rem 0.5rem 2rem rgba(0, 0, 0, 0.11);
  margin-bottom: 1.5rem;
}

.testimonials img {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
}

.testimonials .name {
  color: #2b2b2b;
  font-size: 1.8rem;
  text-transform: capitalize;
  font-weight: 700;
  padding: 1.8rem 0 0 0;
}

.testimonials .desig {
  font-size: 1.6rem;
  margin: 0 0 3.9rem 0;
  font-weight: 500;
  color: #2b2b2b;
}

.testimonials .share {
  margin: 0 auto;
  width: 5em;
  display: flex;
  justify-content: space-between;
}

.testimonials i {
  padding: 0.5em 0;
  transition: all 0.5s;
  cursor: pointer;
}

.testimonials h2 {
  margin-bottom: 30px;
}

.testimonials i:hover {
  color: #777;
}

.testimonialbg {
  margin: -0.5rem 0 -0.5rem 0;
  /* background: url(../img/testimonial_bg.png) fixed center center; */
  background-size: cover;
  display: none;
}

.item ul {
  margin: 0;
  padding: 0;
  /* padding-right: 50px; */
}

.item ul li {
  list-style: none;
  display: inline-flex;
}

/*=================== custom button rule end ====================*/
.main-panel {
  position: relative;
  display: -ms-flexbox;
  word-wrap: break-word;
}

.m-l-b-fff {
  background: #fff;
}

.m-l-b-p {
  padding: 15px 0 15px 0;
}

.m-l-b-m {
  margin: 15px;
}

.card-default {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}

.card-2 {
  background: #28a745;
  color: #fff;
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
}

.card-raised {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2),
    0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12) !important;
}

.card-shadow {
  border: none;
}

.view-group {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
}

.thumbnail {
  margin-bottom: 30px;
  padding: 0px;
  border-radius: 0px;
}

.item.list-group-item {
  float: none;
  width: 100%;
  background-color: #fff;
  margin-bottom: 30px;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1rem;
  border: 0;
}

.item.list-group-item .img-event {
  float: left;
  width: 30%;
}

.item.list-group-item .list-group-image {
  margin-right: 10px;
}

.item.list-group-item .thumbnail {
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
}

.item.list-group-item .caption {
  float: left;
  width: 70%;
  margin: 0;
}

.item.list-group-item:before,
.item.list-group-item:after {
  display: table;
  content: ' ';
}

.item.list-group-item:after {
  clear: both;
}

.avatar {
  vertical-align: middle;
  width: 13rem;
  height: 13rem;
  border-radius: 50%;
}

.head_avatar {
  vertical-align: middle;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  padding: 0.7rem;
  margin-top: 0.6rem;
}

ul.tophd li {
  list-style: none;
  display: inline;
  float: left;
  padding: 0 16px 0px 0;
}

.sidebar {
  top: 0;
  bottom: 0;
  position: absolute;
}

/**************** Icon Css ***************************/
.i-vertical-align {
  vertical-align: middle;
}

/**************** End Icon Css ***************************/

.main-section {
  margin-left: 250px !important;
  width: 100%;
}

@media (max-width: 767px) {
  .sidebar {
    width: 0px;
  }

  .main-section {
    margin-left: 0px !important;
  }

  .main_formbg {
    background: #fff;
    border-radius: 50px;
    border: 1px solid;
    border-color: #c5c5c5;
    width: 100%;
    padding: 0.3rem;
    box-shadow: rgb(0 0 0 / 15%) 0px 16px 32px, rgb(0 0 0 / 10%) 0px 3px 8px !important;
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.48rem 0.81rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.31rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 44px;
}

.form-control:focus {
  outline: 1px solid #4f02a4;
  border: 1px solid #4f02a4;
}

.form-control:focus,
textarea:focus,
select.form-control:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus,
[type='text'].form-control:focus,
[type='password'].form-control:focus,
[type='email'].form-control:focus,
[type='tel'].form-control:focus,
[contenteditable].form-control:focus {
  outline: 1px solid #4f02a4;
}

.dropdown-toggle::after {
  color: #4f02a4 !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2rem);
}

.profilebd {
  padding: 15px 15px 15px 15px;
  margin: 20px 0 30px 0;
}

.main-panelbg {
  background: #eee;
}

.main-panel.border.main-panelbg p {
  margin: 0;
  padding: 10px 15px 10px 15px !important;
}

li.edit-verifications-list-item p {
  font-size: 14px;
}

label.error,
.error-tag {
  color: red !important;
  font-size: 1.3rem;
}

.margin-top-85 {
  margin-top: 8.5rem;
}

.active-nav {
  border-bottom: 0.3rem solid #4f02a4;
  font-weight: 700;
}

.side-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: #c0c0c0c0;
  border-radius: 15px;
  margin-top: 15px;
  text-align: center;
  font-size: 1.6rem;
}

.active-side {
  background: #4f02a4;
  color: #fff !important;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 15px;
  border: 0px;
}

.active-side a {
  color: #fff;
  font-weight: 700;
}

.room-image-container200 {
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  position: relative;
  object-fit: cover;
}

.display-off {
  display: none;
}

/*calender*/
.calendar-month {
  width: 100%;
  padding: 20px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.calendar-month .current-month-selection {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.calendar-month .current-month-selection h2 {
  padding-top: 4px;
}

.calendar-month .current-month-selection select {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  filter: alpha(opacity=0);
}

.calendar-month .current-month-selection .current-month-arrow {
  font-size: 24px;
  position: relative;
  top: -3px;
  text-rendering: optimizeLegibility;
}

.calendar-month .month-nav {
  color: #565a5c;
  border: 1px solid #ddd;
  width: 46px;
  height: 42px;
  display: inline-block;
}

.calendar-month .month-nav.disabled {
  color: #d7d7d7;
  border-color: #e6e6e6;
  cursor: default;
}

.calendar-month .month-nav.disabled:hover {
  color: #d7d7d7;
}

.calendar-month .month-nav .icon {
  width: 46px;
  height: 42px;
  line-height: 42px;
}

.calendar-month .month-nav:hover {
  color: #9ca299;
  text-decoration: none;
}

.calendar-month .month-nav-next {
  margin-right: 15px;
  margin-left: -1px;
  padding-top: 10px;
}

.calendar-month .month-nav-previous {
  margin-right: 15px;
  margin-left: -1px;
  padding-top: 10px;
}

.calendar-month .spinner-next-to-month-nav {
  position: absolute;
  top: 18px;
  left: 100%;
  width: 35px;
  margin-left: 13px;
  font-size: 0;
  color: transparent;
}

.calendar-month.calendar-placeholder {
  min-height: 456px;
}

.host-calendar-container {
  position: relative;
}

/*calender end*/
.col-md-02 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
  margin-left: -1px;
}

.col-md-02 {
  float: left;
}

.col-md-02 {
  width: 14.333%;
}

.wkText {
  font-size: 1.6rem;
  color: #414141;
  margin-left: 2px;
}

.calender_box {
  border: 1px solid #d4d4d4;
  padding: 2px 5px;
  height: 110px;
  border-bottom: 0;
}

.dTfont {
  position: absolute;
  bottom: 0;
}

.dt-not-available {
  background-color: #dce0e0;
  cursor: not-allowed;
  pointer-events: none;
}

.dt-today .final_day {
  font-weight: bold;
}

.dt-available-with-events {
  background-color: #eeae9c;
}
.margin-top30 {
  margin-top: 30px;
}

.tile {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  float: left;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.header {
  border-bottom: 1px solid #ebeff2;
  padding: 19px 0;
  text-align: center;
  color: #59687f;
  font-size: 600;
  font-size: 19px;
  position: relative;
}

.banner-img {
  padding: 5px 5px 0;
}

.banner-img img {
  width: 100%;
  border-radius: 5px;
}

.dates {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 10px 0px;
  margin: 0px 15px;
  font-size: 16px;
  color: #5aadef;
  font-weight: 600;
  overflow: auto;
  background: #eeeeee54;
}
.dates div {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
  font-weight: normal;
  color: #000;
  font-size: 14px;
}
.dates strong,
.stats strong {
  display: block;
  color: #28a745;
  font-size: 16px;
  font-weight: 700;
}

.stats {
  border-top: 1px solid #ebeff2;
  background: #f7f8fa;
  overflow: auto;
  padding: 15px 0;
  font-size: 16px;
  color: #59687f;
  font-weight: 600;
  border-radius: 0 0 5px 5px;
}
.stats div {
  border-right: 1px solid #ebeff2;
  width: 33.33333%;
  float: left;
  text-align: center;
}

.stats div:nth-of-type(3) {
  border: none;
}

div.footer {
  text-align: right;
  position: relative;
  margin: 20px 5px;
}

div.footer a.Cbtn {
  padding: 10px 25px;
  background-color: #dadada;
  color: #666;
  margin: 10px 2px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  border-radius: 3px;
}

div.footer a.Cbtn-primary {
  background-color: #5aadf2;
  color: #fff;
}

div.footer a.Cbtn-primary:hover {
  background-color: #7dbef5;
}

div.footer a.Cbtn-danger {
  background-color: #fc5a5a;
  color: #fff;
}

div.footer a.Cbtn-danger:hover {
  background-color: #fd7676;
}

.daterangepicker {
  max-width: none;
  z-index: 9999;
  box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 14px 36px 2px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
}

.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 34px;
  width: 45px !important;
  height: 45px !important;
  line-height: 24px !important;
  font-size: 15px !important;
  border-radius: 0px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker td.in-range {
  background-color: #4f02a4 !important;
  border-color: transparent;
  color: #fff;
  border-radius: 0;
  opacity: 0.6;
}

.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker td.end-date,
.daterangepicker td.end-date:hover {
  background-color: #4f02a4 !important;
  color: #fff;
  opacity: 1;
  border: 1px solid #fff !important;
}
td.off.available {
  visibility: hidden;
}

.daterangepicker .drp-calendar {
  max-width: 350px !important;
}

.daterangepicker .ranges ul {
  margin-top: 15px;
}

table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  position: absolute !important;
  top: 8px !important;
  right: 8px;
  display: block !important;
  font-family: 'Font Awesome 5 Free' !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #671bbc !important;
  color: #fff !important;
  border-color: transparent;
}

@media only screen and (max-width: 992px) {
  .calender_box {
    height: 80px;
  }
}

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 480px) {
}

.calenBox {
  margin-top: 30px;
}

@media only screen and (max-width: 479px) {
  .calenBox {
    width: 100%;
    height: 300px;
    overflow: scroll;
    overflow-x: scroll;
  }

  .dTfont {
    position: absolute;
  }
}

@media only screen and (max-width: 414px) {
  .sangvish-button.pl-4.pr-4 {
    margin: 0px !important;
  }

  .pick-btn {
    width: 100% !important;
  }
  ul.tophd li {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 375px) {
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2rem) !important;
  }

  .sangvish-button.pl-4.pr-4 {
    margin: 0px !important;
    padding: 7px 5px 10px 5px !important;
  }

  .pick-btn {
    width: 100% !important;
  }
  ul.tophd li {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 360px) {
  .wkText {
    font-size: 10px;
    color: #414141;
    margin-left: 2px;
  }
}

.StripeElement {
  width: 100% !important;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

@media only screen and (max-width: 320px) {
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2rem) !important;
  }

  .sangvish-button.pl-4.pr-4 {
    margin: 0px !important;
    padding: 7px 5px 10px 5px !important;
  }

  .pick-btn {
    width: 100% !important;
  }
  ul.tophd li {
    padding: 0 !important;
  }
}

@media (max-width: 767px) {
  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    position: relative;

    /*padding-right: 0px;
        padding-left: 0px;*/
  }
}

.price-pro {
  background: #018055;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  top: -20px;
  position: absolute;
  font-size: -31px;
  left: 5px;
  color: #fff;
}

.modal-content {
  border-radius: 1.3rem;
}

input[type='checkbox'] {
  display: inline-block;
  height: 24px !important;
  width: 24px !important;
  text-align: center !important;
  vertical-align: top !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(176, 176, 176) !important;
  background: rgb(255, 255, 255) !important;
  overflow: hidden !important;
  border-radius: 4px !important;
  margin-right: 5px;
}

.ml-25 {
  margin-left: 25px !important;
}

.hidden-pod {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

[data-toggle='collapse'] .fa:before {
  content: '\f139';
}

[data-toggle='collapse'].collapsed .fa:before {
  content: '\f13a';
}

[data-toggle='collapse'] .review-arrow:before {
  content: '\f107';
}

[data-toggle='collapse'].collapsed .review-arrow:before {
  content: '\f105';
}

/******* Padding Section *************/

/* List Grid view */
.inactive-list {
  color: #a2a2a2;
}

.list-bacground {
  background: #efefef;
}

.badge-pill {
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  padding: 10px 9px;
  min-width: 45px;
  position: relative;
  overflow: hidden;
  background: #ffb400;
  background: linear-gradient(140deg, #ffc333 55%, #ffb400 55%, #ffb400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffc333', endColorstr='#ffffb400', GradientType=0);
}

.icon-beach {
  color: #4f02a4;
}

.rating_active {
  color: #4f02a4;
}

.rating {
  color: #2b2b2b;
}

/*star rating*/
.stars {
  min-height: 10px;
  width: 125px;
}

.star-rating-alt {
  /* background: url('../img/alt_star.png') repeat-x scroll left top */
  /* rgba(0, 0, 0, 0); */
  height: 25px;
  list-style: outside none none;
  padding: 0;
  position: relative;
  width: 125px;
}

.star-rating-alt li.current-rating {
  /* background: url('../img/alt_star.png') repeat scroll left center */
  /* rgba(0, 0, 0, 0); */
  display: block;
  height: 25px;
  position: absolute;
  text-indent: -9000px;
  z-index: 1;
}

@media screen and (min-width: 992px) {
  .custom_class_booking_type {
    text-align: center;
  }
}

.ex-image-container {
  width: 100%;
  height: 500px;
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-top: 2rem;
}

.slider-image-container {
  width: 100%;
  height: 400px;
  background-color: #f5f5f5;
  background-size: cover;
  background-position: center;
  position: relative;
}

/*add*/
.blue-color {
  color: #367ea6;
}

.tip {
  width: 0px;
  height: 0px;
  position: absolute;
  background: transparent;
  border: 10px solid #ccc;
}

.tip-left {
  top: 10px;
  left: -21px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
}

.tip-right {
  top: 10px;
  right: -21px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.dialogbox .body {
  position: relative;
  height: auto;
  margin: 0 0 15px 0;
  padding: 5px 10px;
  background-color: #f4f4f4;
  border-radius: 2px;
  border: 1px solid #ccc;
}

.body .message {
  min-height: 30px;
  border-radius: 3px;
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  line-height: 1.5;
  color: #797979;
}

.media-photo-badgeSMS {
  border-radius: 50% !important;
  height: 90px !important;
  width: 90px !important;
}

.profile_photo img {
  border-radius: 50% !important;
  width: 120px;
  height: 120px;
}

@media only screen and (max-width: 479px) {
  .media-photo-badgeSMS {
    border-radius: 50% !important;
    height: 70px !important;
    width: 70px !important;
    margin-top: 20px;
  }
}

.media-photo-badge img {
  width: 80px;
  height: 80px;
  border-radius: 50% !important;
}

#booking-banner.stick {
  margin-top: 70px !important;
  position: fixed !important;
  width: 25%;
  top: 0;
  z-index: 10;
  border-radius: 0 0 0.5em 0.5em;
}

#booking-price.stick {
  position: fixed !important;
  width: 22.5%;
  top: 85px;
  right: 5%;
  z-index: 10;
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
}

.page-link {
  position: relative;
  display: block;
  margin: 6px;
  color: #4f02a4;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.page-link:hover {
  z-index: 3;
  color: #fff !important;
  background-color: #4f02a4;
  border-color: #4f02a4;

  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  -webkit-transform: translateY(-0.25em);
  transform: translateY(-0.25em);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #4f02a4 !important;
  color: #fff !important;
  background-color: #4f02a4 !important;
  border: 1px solid #dee2e6 !important;
}

.paginate_button.previous.disabled:hover {
  background: #fff !important;
}

.paginate_button.next.disabled:hover {
  background: #fff !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  display: inline-block;

  padding: 0.3rem 1em !important;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  cursor: hand;

  color: #4f02a4 !important;
  border: 1px solid #dee2e6 !important;
  margin: 0.5rem;
}

.current {
  background: #4f02a4 !important;
  color: #fff !important;
}

.dataTables_wrapper {
  margin-top: 2.2rem !important;
}

.dataTables_paginate paging_simple_numbers {
  padding: 1.5rem;
}
/*********************** Page Not Found Css *******************************/
#notfound {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 999;
}

.notfound {
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

#notfound .notfound {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h3 {
  font-family: 'Quicksand', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000000;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound .notfound-404 h1 {
  font-family: 'Quicksand', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 170px;
  font-weight: 900;
  margin: 0px;
  color: #4f02a4;
  text-transform: uppercase;
  margin-left: 0;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #000;
}

.notfound h2 {
  font-family: 'Quicksand', sans-serif;
  font-size: 25px;
  font-weight: 400;
  color: #000;
  margin-top: 0px;
  margin-bottom: 25px;
}

.error-reply {
  font-size: 8rem;
  font-weight: 600;
}

.modal.left .modal-dialog,
.modal.right .modal-dialog,
.modal.top .modal-dialog,
.modal.bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
  max-height: calc(100vh);
}

.modal.left.fade .modal-dialog {
  left: -500px;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.show .modal-dialog {
  left: 0;
}

.modal.right.fade .modal-dialog {
  right: -500px;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.modal.left .modal-content,
.modal.right .modal-content,
.modal.top .modal-content,
.modal.bottom .modal-content,
.modal.full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.left .modal-dialog.modal-sm,
.modal.right .modal-dialog.modal-sm {
  width: 300px;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

.mobile-side a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  color: #010101;
  box-sizing: border-box;
  border-bottom: 1px solid #fff;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.4s;
  line-height: 60px;
}

.mobile-side a i {
  margin-right: 10px !important;
  width: 20px;
}

.drop-width {
  width: 150px;
}

.ranges-time li {
  font-size: 13px !important;
  border: 1px solid #fff !important;
  color: #222325 !important;
  padding: 3px 12px !important;
  margin-bottom: 8px !important;
  cursor: pointer !important;
}

.ranges li {
  font-size: 13px !important;
  border: 1px solid #fff !important;
  color: #222325 !important;
  padding: 3px 12px !important;
  margin-bottom: 8px !important;
  cursor: pointer !important;
}
.ranges-time li:hover {
  background: #4f02a4 !important;
  color: #fff !important;
}

.ranges li:hover {
  background: #4f02a4 !important;
  color: #fff !important;
}

.ranges li.active {
  background: #4f02a4 !important;
  border: 1px solid #4f02a4 !important;
  color: #fff !important;
}

.ranges-time li.active {
  background: #4f02a4 !important;
  border: 1px solid #4f02a4 !important;
  color: #fff !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.nav-item > .active {
  color: #4f02a4;
  font-weight: 700;
}

.footer-bg {
  background: #f7f7f7 !important;
}

/* Inbox */

.container-inbox {
  display: flex;
  height: calc(100vh - 17rem);
}

.right-inbox {
  height: calc(100vh - 17rem);
}

sidebar {
  width: 33%;
  min-width: 33%;
  display: flex;
  background: #fff;
  flex-direction: column;
  transition: 500ms all;
  max-height: 100vh;
  z-index: 80;
}

sidebar .logo {
  display: flex;
  margin: 10px 0 0 0;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 3em;
  letter-spacing: 7px;
  border-bottom: 1px solid #ccc;
}

sidebar .list-wrap {
  width: 100%;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 3px;
}

sidebar .list-wrap .list {
  border-bottom: 1px solid #ddd;
  background: #fff;
  display: flex;
  padding: 5px;
  cursor: pointer;
}

sidebar .list-wrap .list:hover,
sidebar .list-wrap .list.active {
  background: #f4f7f9;
}

sidebar .list-wrap .list img {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  margin-right: 1rem;
  border: 1px solid #dee2e6;
}

sidebar .list-wrap .list .info {
  flex: 1 1;
}

sidebar .list-wrap .list .count {
  font-size: 0.75em;
  background: #bde2f7;
  box-shadow: 0 0.5rem 1.5rem -0.5rem rgba(0, 0, 0, 0.7);
  padding: 0.3rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  text-align: center;
  color: #000;
}

.content-inbox {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.content-inbox header {
  height: 7rem;
  background: #f7f7f7;
  display: flex;
  padding: 1rem;
  border-top: 1px solid #ddd;
}

.content-inbox header img {
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  margin-right: 10px;
  border: 1px solid #dee2e6;
}

.content-inbox header .info {
  flex: 1 1;
}

.content-inbox header .info .user {
  font-weight: 700;
}

.content-inbox header .info .time {
  display: flex;
  margin-top: 3px;
  font-size: 0.85em;
}

.content-inbox header .open {
  display: none;
}

.content-inbox header .open a {
  color: #000;
  letter-spacing: 3px;
}

.message-wrap {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow: auto;
  height: calc(100vh - 30rem);
}

.message-wrap::before {
  content: '';
  margin-bottom: auto;
}

.message-wrap .message-list {
  align-self: flex-start;
  max-width: 70%;
}

.message-wrap .message-list.me {
  align-self: flex-end;
}

.message-wrap .message-list.me .msg {
  background: #bde2f7;
  color: #111;
}

.message-wrap .message-list .msg {
  background: #dee2e6;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);
  padding: 10px 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.message-wrap .message-list .time {
  text-align: right;
  color: #999;
  font-size: 0.75em;
}

.message-footer {
  border-top: 1px solid #ddd;
  background: #eee;
  padding: 10px;
  display: flex;
  height: 60px;
}

.message-footer input {
  flex: 1 1;
  padding: 0 20px;
  border-radius: 5px;
}

.container-fluid-90 {
  width: 90% !important;
}

@media only screen and (max-width: 480px), only screen and (max-width: 767px) {
  sidebar {
    position: fixed;
    width: 100%;
    min-width: 100%;
    height: 0vh;
    bottom: 0;
    box-shadow: 0 5px 25px -5px black;
    bottom: 0;
    left: 0;
  }

  sidebar.opened {
    height: calc(100vh - 25rem) !important;
  }

  sidebar .logo {
    display: none;
  }

  sidebar .list-wrap .list .count {
    font-size: 0.75em;
  }

  header .open {
    display: block !important;
  }

  .container-fluid-90 {
    width: 100% !important;
  }
}

.modal-lg {
  max-width: 1000px;
}

.modal-dialog-centered {
  min-height: calc(100vh - 11.5rem);
}

.modal-body {
  max-height: calc(100vh - 12.5rem);
  overflow-y: auto;
}

.drop-down-menu-left {
  left: -40px;
}

.vtooltip {
  position: relative;
  display: inline-block;
}

.vtooltip .vtooltiptext {
  visibility: hidden;
  width: 110px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-left: -60px;
}

.vtooltip .vtooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.vtooltip:hover .vtooltiptext {
  visibility: visible;
}

.map-view-location {
  width: 100%;
  height: 200px;
}

.map-close {
  background: #fff;
  border: 0px;
  padding: 0px;
  position: absolute;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 8px;
  height: 45px;
  width: 45px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  top: 10px;
  left: 15px;
  color: #2b2b2b;
  z-index: 100;
}

.price-btn {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
}

.show-map {
  background: #fff;
  border: 0px;
  padding: 0px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  color: #2b2b2b;
  z-index: 100;
}
.dropdown-menu-location {
  width: 45rem !important;
  border-radius: 15px !important;
  top: 5px !important;
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2),
    0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12) !important;
}

.dropdown-menu-room-type {
  width: 25rem !important;
  border-radius: 15px !important;
  top: 5px !important;
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2),
    0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12) !important;
}

.dropdown-menu-price {
  border-radius: 15px !important;
  top: 5px !important;
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2),
    0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12) !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.pick_date-width {
  width: 230px;
  padding: 0 10px 0 15px;
}

.modal-z-index {
  z-index: 100000000 !important;
}

@media only screen and (max-width: 900px) {
  #booking-banner.stick {
    width: 32% !important;
  }
  #booking-price.stick {
    width: 32% !important;
  }

  .dropdown-menu-location {
    width: 30rem !important;
  }

  .ex-image-container,
  .slider-image-container {
    height: 250px;
  }
}

/**************************** Dashboard Css *********************************/

.widget ul {
  margin: 0px;
  padding: 0;
  overflow: hidden;
}
.widget ul li {
  overflow: hidden;
  font-size: 14px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
}
.sidebar-thumb {
  float: left;
  overflow: hidden;
  margin-right: 15px;
}
.sidebar-thumb img {
  background: #fff;
  border: 1px dashed #e0e0e0;
  padding: 6px;
  height: 75px;
  width: 75px;
  border-radius: 100px;
}
.sidebar-content h5 {
  font-size: 16px;
  cursor: pointer;
  line-height: 18px;
}
.sidebar-content h5 a:hover {
  color: #2996bd;
}

.sidebar-content h5 a {
  color: #202020;
  outline: 0 none;
  text-decoration: none;
  font-weight: bold;
}
.sidebar-meta span {
  color: #2e2e2e;
}
.sidebar-meta span.time {
  margin-right: 10px;
}
/**************************** End Dashboard Css *********************************/

.review-0 ul li {
  list-style: none;
  display: inline;
  width: 50%;
}
a.send-btn {
  z-index: 11;
  position: relative;
  float: right;
  margin: 0px 0 0 -50px;
  width: 50px;
  border-radius: 0 4px 4px 0;
}

.single-load {
  min-height: 200px;
  width: 100%;
  text-align: center;
  opacity: 0.9;
  padding-top: 110px;
}
.loader-img {
  min-height: 200px;
  width: 100%;
  text-align: center;
  opacity: 0.9;
  padding-top: 70px;
}

.not-found {
  width: 100px;
}

.light-box-img {
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.light-box-view {
  text-align: center;
  padding-top: 40px;
  margin-top: 0px;
  color: white;
  height: 130px;
  width: 100%;
  background-color: #8e8f90;
  cursor: pointer;
}

.single-map-w {
  width: 100%;
  height: 400px;
}

.map-view {
  width: 100%;
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.map-property-img {
  height: 150px;
  width: 250px;
  object-fit: cover;
}
.media-cover {
  width: 250px;
}

.map-property-name {
  max-height: 150px;
  max-width: 250px;
}

.dataTables_paginate {
  margin-top: 25px !important;
}

.js-calendar-sync:hover {
  color: #fff !important;
}

.shareButton {
  background-color: #476bb8;
  color: white;
  padding: 3px 10px;
  display: inline-block;
  font-size: 13px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
  transition-duration: 0.4s;
}

.shareButton:hover {
  background-color: #3b5998;
  color: white !important;
}

.view-all {
  background-color: rgb(24, 23, 23);
  color: #fff;
  font-weight: 700;
}
.view-all img {
  opacity: 0.5;
}

.h-110px {
  height: 110px;
  object-fit: cover;
}

.gal-img {
  width: 200px;
}

.price-table-scroll {
  max-height: 500px;
  padding: 0 20px;
}

/* Media query */
@media (min-height: 0px) and (max-height: 480px) {
  .min-height {
    min-height: 480px;
  }
}

@media (min-height: 481px) and (max-height: 720px) {
  .min-height {
    min-height: 600px;
  }
}

@media (min-height: 721px) and (max-height: 850px) {
  .min-height {
    min-height: 790px;
  }
}

@media (min-height: 851px) and (max-height: 1440px) {
  .min-height {
    min-height: 927px;
  }
}

@media (max-width: 1200px) {
  #booking-price.stick {
    width: 30.5%;
  }
}

/* Animation */

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}

.swal-footer {
  text-align: center;
}

progress {
  border: none;
  height: 5px;
  width: 100px;
  margin-bottom: 3px;
  background: rgb(196, 194, 194);
}

progress {
  color: rgb(180, 180, 184);
}

progress::-webkit-progress-value {
  background: #222222;
}

progress::-moz-progress-bar {
  background: rgb(180, 180, 184);
}

progress::-webkit-progress-value {
  background: #222222;
}

progress::-webkit-progress-bar {
  background: rgb(180, 180, 184);
}

@media screen and (max-width: 1100px) and (min-width: 992px) {
  progress {
    width: 55px;
  }
}

@media screen and (max-width: 1150px) and (min-width: 1100px) {
  progress {
    width: 80px;
  }
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #4f02a4 !important;
  background: #4f02a4 !important;
  font-weight: normal;
  color: #fff;
}

.ui-state-active:hover {
  color: #fff !important;
}

.line-height-2-4 {
  line-height: 2.4;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

@media (max-width: 576px) {
  .gal-img {
    width: 100% !important;
  }

  .h-110px {
    height: auto !important;
  }
}
/* new style start here */
#front-search-form label,
#front-search-form1 label {
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0;
  color: #000;
}
#front-search-form .form-control,
#front-search-form1 .form-control {
  font-size: 15px;
  border: 0;
  height: 40px;
  background: transparent;
  margin-top: -5px;
}
#front-search-form .col-md-4,
#front-search-form .col-md-2,
#front-search-form1 .col-md-4,
#front-search-form1 .col-md-2 {
  border-right: 1px solid #ddd;
}
.front-search button {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  float: right;
}
/* header::before {
    content: "";
    height: 140%;
    left: 0px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 0;
    opacity: 0.4;background:#ddd;
}*/
.sv_home_subsec {
  margin-top: 20rem;
}
.sv_home_subsec button {
  box-shadow: 0px 6px 16px rgb(0 0 0 / 12%) !important;
  border: 0;
  background: #fff;
  padding: 15px 40px;
  border-radius: 50px;
}
.sv_home_subsec button a {
  color: #6f019c;
  font-weight: 600;
}
.svcity {
  margin-bottom: 5rem;
}

.hero-banner h1:after,
.sv_header_text:after {
  content: '';
  display: block;
  width: 25px;
  height: 13px;
  border-bottom: 2px solid #fff;
  margin: auto;
}
.recommandedbg .card img {
  border-radius: 10px;
}
.recommandedbg .card {
  box-shadow: none;
}
.header_area.navbar_fixed {
  z-index: 99;
}
.svred {
  color: #1bbc33;
}
.sv_brown {
  color: #b43618;
}
.margin-bottom-zero {
  margin-bottom: 0;
}
.sv_social {
  display: inline-block;
  margin-left: 30px;
}
.sv_social li {
  padding: 0 7px;
}
.sv_user_login button {
  background: #fff;
  border: 0;
  border-radius: 50px;
  padding: 3px 10px;
  border: 1px solid #ddd;
}
.sv_user_login button img {
  margin: 0;
  padding: 0;
  width: 33px;
  height: 33px;
  margin-left: 7px;
}
.sv_user_login button:after {
  display: none;
}
.sv_user_login i {
  margin-left: 7px;
  vertical-align: middle;
}
.sv_user_login {
  padding: 2rem 0 10px;
}
.sv_user_login .dropdown-menu {
  border-radius: 10px;
  left: -120px;
  margin: 0;
  min-width: 20rem;
}
.sv_user_login .dropdown-menu a {
  font-size: 14px;
}
.sv_user_login .dropdown-menu li {
  padding: 7px 10px;
}
.sv_user_login button:focus {
  outline: none;
}
#loginmodel {
  z-index: 9999;
}
.migrateshop_othernav:before {
  display: none;
}
.migrateshop_othernav .navbar .nav .nav-item .nav-link {
  color: #000;
}
#loginmodel .modal-header h3,
#registermodel .modal-header h3 {
  width: 100%;
  font-weight: 600;
}
#loginmodel .modal-header .close,
#registermodel .modal-header .close {
  font-size: 20px;
  opacity: 1;
}
#registermodel {
  z-index: 9999;
}
.migrateshop_othernav .sv_header_text {
  display: none;
}
.sv_third_col {
  margin-top: 5px;
}
.sv_fourth_col {
  margin-top: 1.5rem;
}
.sv_fourth_col_img div {
  height: 170px;
}
.sv_fifth_col {
  width: 25%;
}
.sv_fifth_col img {
  width: 100%;
  height: 195px;
}
.sv_large_size {
  width: 50%;
}
.sv_large_size img {
  height: 400px;
  width: 100%;
}
.sv_fifth_row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 395px;
  margin-top: 2rem;
}
.hide {
  display: none;
}
.position-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.card img {
  border-radius: 10px;
}
#booking-price {
  box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 12px;
  overflow: hidden;
  padding: 17px;
}
#booking-price .single-border {
  border: 1px solid #b5b3b3;
}
#booking-price label,
#booking_table td {
  font-size: 14px;
}
.sv_single_prop .fixed {
  position: fixed;
  top: 85px;
  height: 48px;
  z-index: 10;
  background: #fff;
  width: 100%;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.sv_single_prop .fixed li {
  float: left;
  padding: 10px;
}
.sv_single_prop .fixed li a {
  color: #000;
  font-size: 14px;
}
.sv_single_prop .fixed li a:hover {
  color: #000 !important;
}
.prop-nav li:hover {
  border-bottom: 3px solid #000;
}
.ul-price-fixed li {
  font-weight: 700;
}
#reviews {
  padding: 10% 0 0;
}
.sv_search_page {
  margin-top: 8.5rem;
}
.sv_list li button {
  font-size: 14px; /*border:1px solid rgb(176, 176, 176)*/
}
#properties_show .carousel-indicators li {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.img-event img {
  max-height: 180px;
  min-height: 180px;
  border-radius: 12px;
}
.svleft,
.svright {
  position: absolute;
  top: 50%;
  z-index: 12;
  background: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  border-radius: 50px;
  line-height: 23px;
}
.svleft {
  left: 30px;
}
.svright {
  right: 30px;
}
.svleft i,
.svright i {
  font-size: 12px;
}
.sv_property_title::after {
  content: '';
  border-bottom: 1.5px solid #dddddd;
  width: 35px;
  height: 10px;
  display: block;
}
.sv_search_page #front-search-form .form-control {
  border: 1px solid #eee;
  margin: 0;
  padding: 1px !important;
  height: auto;
}
.sv_start_your_search,
.mob-search {
  border: 1px solid #ddd;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%) !important;
  border-radius: 50px;
  line-height: 35px;
  color: #000;
  text-align: left;
}
.header_area .sv_head_search,
.mob-search {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 50px;
}
.sv_start_your_search button,
.mob-search button {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  float: right;
}
.single_sticky {
  z-index: 9 !important;
}
.margin-top-90 {
  margin-top: 9rem;
}
.margin-top-120 {
  margin-top: 12rem;
}
.margin-top-100 {
  margin-top: 10rem;
}
.yellow_color {
  color: #ffc107;
}
.fb_btn {
  background: #007bff;
  color: #fff;
}
.fb_btn:hover {
  color: #007bff;
  border-color: #007bff;
  background: transparent;
}
.google_btn {
  background: #dc3545;
  color: #fff;
}
.google_btn:hover {
  color: #dc3545;
  border-color: #dc3545;
  background: transparent;
}
.underline {
  text-decoration: underline;
}
#front-search-form1 .form-control {
  width: auto;
}
.svlogin_bg {
  /* background: url('../img/login-bg.jpg'); */
  background-position: 100% 100%;
}
.tryhosting {
  /* background-image: url(./static/influencer.jpg); */
  color: #fff;
  padding: 10rem 5rem;
  border-radius: 15px;
  background-size: cover;
  background-color: #1c1c1c;
  background-repeat: no-repeat;
  background-position: right;
}
.tryhosting h2 {
  font-size: 3rem;
  letter-spacing: 1px;
  color: #fff;
}
.tryhosting p {
  line-height: 25px;
}
.price_fixed {
  position: fixed;
  top: 8.3rem;
  right: 24.6rem;
  background: #c73f22;
  z-index: 10;
  width: 255px;
  border: 0 !important;
  border-radius: 0 !important;
}
.ul-price-fixed {
  width: 32%;
}
.price_fixed .secondary-bg {
  border-radius: 0 !important;
}
.price_fixed .p-3 {
  padding: 10px !important;
}
#per_night,
#per_month {
  float: right;
}
.ul-price-fixed #per_night,
#per_month {
  float: left;
  color: grey !important;
  line-height: 2;
}
.ul-price-fixed li {
  padding: 7px 5px !important;
}
.wishicon i {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, 0.8);
  color: #000;
  color: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
}

.wishicon i {
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  top: 10px;
  right: 20px;
  color: #4f02a4;
  cursor: pointer;
}
.pull-right {
  float: right;
}
.mob-banner {
  display: none;
}
.br-50 {
  border-radius: 50px;
}
.navbar_fixed .btn {
  background-color: #4f02a4;
  color: #fff;
}
.theme-font-color {
  color: #4f02a4;
}
#listMargin p {
  width: 100%;
}
.banner-title {
  font-size: 3rem;
  font-weight: 400;
  color: #fff;
  text-shadow: 3px 0px 0px #403e3e;
}
.mob-banner {
  display: none;
}
.not-found {
  display: none;
}
/*New css*/
@media (max-width: 767px) {
  .daterangepicker {
    z-index: 5 !important;
  }
  .daterangepicker.drop-up,
  .daterangepicker.show-calendar {
    margin-top: 0 !important;
    left: 0px !important;
    right: 0px !important;
    width: 100% !important;
  }
  .daterangepicker.drop-up:after,
  .daterangepicker.drop-up:before,
  .daterangepicker.show-calendar:after,
  .daterangepicker.show-calendar:before {
    border: none !important;
  }
  .tryhosting:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 15px;
    z-index: -1;
  }
  .tryhosting {
    position: relative;
    z-index: -1;
  }
  .hide-filter {
    display: none;
  }
  .mob-back-btn {
    position: absolute;
    left: 10px;
    width: 39px;
    height: 39px;
    box-shadow: rgb(0 0 0 / 8%) 0px 6px 20px !important;
    background: #fff;
    border-radius: 50%;
    padding: 7px 12px;
    margin-top: -5px;
  }
  .migrateshop_othernav .mob-back-btn {
    display: block !important;
  }
  .migrateshop_othernav #front-search-form1 input {
    width: 150px;
  }
  .homenav .navbar {
    background: transparent !important;
    /* background: #fff; */
  }
  .homenav {
    z-index: 20 !important;
  }
  .migrateshop_othernav .mob-logo {
    display: none;
  }
  .mob-logo {
    width: 40px;
    height: 40px;
  }
  .search-no-result {
    width: 300px !important;
    padding: 20px 40px;
  }
  .step-two img,
  .step-three img,
  .step-six img,
  .sv_step_first img,
  .sv_step_three img {
    min-height: 250px !important;
    max-height: 250px !important;
  }
  .sv_step_first div,
  .sv_step_three div {
    top: 28% !important;
  }
  .sv_step_first .text-52 {
    font-size: 2.5rem;
  }
  .mob-pd {
    padding-left: 3rem !important;
  }
  .mlft-0 {
    margin-left: 0 !important;
  }
  .sv-city-pd {
    padding-left: 7px;
    padding-right: 7px;
  }
  .mob-pd-0,
  #calender-dv .col-md-12 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  #up_button,
  #vid_button {
    height: 42px;
  }
  .mob-pd-lft {
    padding-left: 15px !important;
  }
  .calendar-month {
    padding: 0;
  }

  .main-banner {
    min-height: 30rem !important;
  }
  .main-banner img {
    height: 30rem !important;
  }
  /*after login*/
  .svdashboard img.rounded {
    object-fit: cover;
  }
  .db-content {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .main-panel #booking {
    margin-top: 20px;
  }
  #booking .right-inbox {
    height: auto !important;
  }
  .mob-txt-center {
    text-align: center !important;
  }
  .pick_date-width {
    padding: 0 4px 0 2px !important;
  }
  /*after login end*/

  .search-page-flex .sv_list {
    padding-left: 0 !important;
  }
  .search-page-flex .list-inline-item:not(:last-child) {
    margin-right: 0.2rem !important;
  }
  .search-page-flex .sv_list li button {
    padding: 5px !important;
    font-size: 10px;
    padding: 5px !important;
  }
  .sv_start_your_search button,
  .mob-search button {
    width: 28px;
    height: 28px;
    position: absolute;
    right: 5px;
    padding: 4px 4px !important;
  }
  .header_area #front-search-form1,
  .header_area #front-search-form {
    position: absolute;
    width: 60%;
    height: 30px;
    left: 20%;
    background: #f7f7f7;
  }
  .migrateshop_othernav #front-search-form1 {
    width: 60%;
    left: 20%;
  }
  .mob-search {
    top: 10px;
  }

  .search-page-flex .slider.slider-horizontal {
    width: 125px;
    margin-left: 17px;
    margin-right: 17px;
  }
  #front-search-form1 .form-control,
  .mob-search .form-control {
    width: 100%;
    font-size: 12px !important;
  }
  .foot-content .text-right,
  .foot-content .text-14 {
    text-align: center !important;
  }
  .sv_social {
    margin-left: 0;
  }
  .sv_list .dropdown-menu.show .min-w-250 {
    min-width: 80px;
  }
  .map-switch {
    align-items: baseline;
    margin-left: 5px;
  }
  .property-title .pull-right {
    position: absolute;
    z-index: 1;
    right: 7%;
    margin-top: 18px;
  }
  .property-title .no-img-pull-right {
    margin-top: -30px !important;
  }
  .property-title .no-img-pull-right i {
    color: #000;
  }
  .daterangepicker {
    z-index: auto;
  }

  .prop-image1,
  .prop-image2 div,
  .prop-image3 div {
    height: 200px !important;
  }
  .sv_fourth_col_img div {
    height: 104px !important;
  }
  #photos {
    height: 250px !important;
  }
  .sv_three_col {
    flex-direction: column;
    flex-wrap: wrap;
  }
  .sv_third_col div {
    height: 90px !important;
  }
  .sv_three_col .ex-image-container {
    margin-top: 0.4rem;
  }
  #photos .sv_third_col:nth-child(2) div {
    border-radius: 0 10px 0 0;
  }

  .ul-price-fixed {
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 10;
    height: 55px;
  }
  .sv_single_prop .fixed li {
    padding: 5px;
  }

  .sv_single_prop .fixed {
    height: auto;
  }
  #start-sec .operators-page__how__item[data-v-3601cef9]:not(:last-child):after,
  #start-sec .operators-page__how__item[data-v-3601cef9]:before {
    display: none !important;
  }
  #start-sec .operators-page__how__item[data-v-3601cef9] {
    justify-content: normal !important;
    flex-direction: column !important;
    text-align: center;
  }
  .faq-heading {
    padding: 0 25px 0 0 !important;
    font-size: 1.27rem !important;
  }
  .faq-list li {
    margin: 10px 0 !important;
  }
  .join-txt {
    padding-top: 0 !important;
  }
  .host-banner {
    height: 320px !important;
    margin-top: 72px;
  }
  .join_banner_txt {
    padding-top: 30% !important;
  }
  .partner-sec .SectionSteps_stepRoot__mCrpV {
    margin-top: 8%;
  }
  .partner-sec .SectionSteps_stepIconContainer__1kIXG {
    margin-bottom: 4%;
  }
  .join_banner_txt h4 {
    font-size: 2.95rem !important;
  }
  .join_banner_txt h3 {
    font-size: 2.4rem;
  }
  #front-search-field1 {
    margin-left: 0 !important;
  }
  .mob-banner {
    display: block;
    min-height: 40rem;
    background-color: #000;
    min-height: 25rem;
    margin-top: 8rem;
  }
  .sv_home_subsec {
    margin-top: 7rem;
  }
  .desk-banner-content {
    display: none !important;
  }
  .desk-banner-bg {
    background: none !important;
  }
  .mob-form-bg {
    box-shadow: none !important;
    border-radius: 7px;
    margin-top: 2rem;
    padding: 0;
  }
  .mob-banner {
    display: block;
  }
  .mob-form-bg label {
    width: 100%;
    text-align: left;
    margin-bottom: 15px !important;
  }
  .mob-form-bg input,
  .mob-form-bg select {
    border: 1px solid #ced4da !important;
    height: 40px !important;
  }
  .mob-form-bg .col-md-4 {
    border-right: 0 !important;
  }
  .mob-form-bg .btn {
    width: auto !important;
    float: right;
    border-radius: 4px !important;
    padding: 5px 14px !important;
    margin-top: 12px;
    font-size: 14px;
  }
  .svcity .grid figure {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 6%);
    overflow: hidden;
  }
  .svcity .grid figure img {
    float: none !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .svcity figcaption p {
    position: initial !important;
    left: 0 !important;
    top: 0 !important;
    -webkit-transform: none !important;
            transform: none !important;
    line-height: inherit;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  .svcity .effect-ming {
    margin-bottom: 0 !important;
  }
  .tryhosting {
    padding: 7rem 2rem;
  }
  .text-30 {
    font-size: 2.5rem;
  }
  .sv_single_prop .fixed {
    top: 82px;
  }
  .price_fixed {
    top: 17.2rem;
    width: 100%;
    right: 0;
  }
  .svlogin_bg .bg-white,
  .svlogin_bg .bg-white {
    width: 300px;
  }
  .sv_search_page .slider:after {
    left: 38%;
  }
  .sv_search_page .search-page-flex {
    display: block;
    padding: 0px 7px;
  }
  .sv_search_page #showMap + span {
    line-height: 7;
    position: relative;
    top: -14px;
  }
  .sv_fifth_row .sv_large_size img {
    height: 240px !important;
  }
  .sv_fifth_row .sv_fifth_col img {
    height: 115px !important;
  }
  #photos.sv_fifth_row {
    height: 280px !important;
  }
  .footer-bg {
    padding: 5px 10px !important;
  }
  #daterange-btn span {
    font-size: 1.2rem;
    padding-top: 5px;
    padding-right: 5px;
  }

  table.dataTable tfoot th,
  table.dataTable thead th {
    font-size: 1.4rem;
  }
  .intl-tel-input input,
  .intl-tel-input input[type='text'],
  .intl-tel-input input[type='tel'] {
    padding-left: 8.8rem !important;
  }
  #front-search-form1 {
    margin: auto;
  }
  #left_modal .close {
    color: #fff;
    opacity: 1;
    font-weight: 500;
    font-size: 3.7rem;
  }
  .mob-pd-0 {
    padding-left: 3px;
    padding-right: 0;
  }
  .banner-title {
    font-size: 1.5rem;
    line-height: 1.4;
  }
}

/*Join us*/
.join-banner {
  height: 600px;
  min-height: auto !important;
}
.join-banner-bg {
  /* background-image: url('../img/join-banner.jpg'); */
  position: relative;
  z-index: 1;
}
.join_banner_txt {
  padding-top: 20%;
  text-align: left;
  color: #fff;
}
.join_banner_txt h4,
.join_banner_txt h3 {
  font-weight: 600;
}
.join_banner_txt h4 {
  font-size: 60px;
}
.join_banner_txt h3 {
  font-size: 40px;
}
.join_banner_txt .btn {
  padding: 10px 45px;
  font-size: 17px;
  border-radius: 7px;
  margin-top: 20px;
}
.partner-sec {
  padding: 72px 0;
}
.partner-sec h1 {
  margin-bottom: 32px;
  font-size: 30px;
}
.partner-sec i {
  padding: 20px 0;
}
.business-sec {
  background: #f2f2f2;
}
.business-sec .card-1 {
  position: relative;
  border-radius: 1.25rem;
  overflow: hidden;
  background: #e6e6e6 no-repeat 50% / cover;
  height: 350px;
}
.business-sec .card-1:before {
  content: '';
  display: block;
  padding-top: 100%;
}
.img-card-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: normal;
  justify-content: normal;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 32px;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
  color: #fff;
}
.business-sec .details {
  font-size: 14px;
  line-height: 1.4;
}
.business-sec .name {
  margin-bottom: 7px;
}
.faq-sec,
.more-qn-sec {
  padding: 72px 0;
}
#start-sec .operators-page__how__item[data-v-3601cef9] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  position: relative;
}
body {
  counter-reset: how;
}
#start-sec {
  background: #f7f6f6;
}
#start-sec [data-v-3601cef9] {
  font-size: 14px;
  color: #010101;
}
#start-sec .start-content {
  color: #000;
}
#start-sec .operators-page__how__item[data-v-3601cef9]:before {
  content: counter(how);
  counter-increment: how;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: perspective(1px) translate3d(-50%, -50%, 0);
          transform: perspective(1px) translate3d(-50%, -50%, 0);
  border: 1px solid hsl(0deg 4% 22% / 30%);
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.05);
  font: 700 30px Roboto, sans-serif;
}

#start-sec .operators-page__how__text[data-v-3601cef9] {
  width: 210px;
  color: #83a5af;
}
#start-sec .operators-page__how__title[data-v-3601cef9] {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 600;
}


#start-sec
  .operators-page__how__item:first-child
  .operators-page__how__img[data-v-3601cef9],
#start-sec
  .operators-page__how__item:nth-child(2)
  .operators-page__how__img[data-v-3601cef9],
#start-sec
  .operators-page__how__item:nth-child(3)
  .operators-page__how__img[data-v-3601cef9] {
  background-size: contain;
}
#start-sec .operators-page__how__item[data-v-3601cef9]:not(:last-child):after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  height: 60px;
  border: 1px dashed hsl(0deg 2% 12% / 30%);
}
#start-sec .operators-page__how__img[data-v-3601cef9] {
  border-radius: 0.5rem;
  width: 210px;
  height: 160px;
  background: #fff;
}
#start-sec
  .operators-page__how__item
  + .operators-page__how__item[data-v-3601cef9] {
  margin-top: 60px;
}
#start-sec p {
  padding-bottom: 30px;
}
.host-title {
  font-size: 30px;
}
.green-theme-bg {
  background-color: #4f02a4;
}
.green-theme-btn {
  background-color: #4f02a4;
  color: #fff;
  border: 1px solid #4f02a4;
  outline: none;
}
.green-theme-font {
  color: #4f02a4;
}
.green-theme-btn a,
.green-theme-btn a:hover {
  color: #fff !important;
}
.hosting-sec {
  /* background: url(../img/host.jpg); */
  color: #fff;
  padding: 10rem 5rem;
  border-radius: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.host-banner {
  background-repeat: no-repeat;
  background-size: cover;
}
.host-banner-bg {
  /* background-image: url('../img/hosting-banner.jpg'); */
  position: relative;
  z-index: 1;
}

.faq-list {
  list-style: none;
  padding: 0;
}

.faq-list li {
  margin: 30px 0;
  border-bottom: 1px solid rgb(205, 205, 205);
  padding: 20px 10px;
  text-align: left;
}

.faq-list .faq-heading::before {
  content: '+';
  font-size: 40px;
  display: block;
  position: absolute;
  right: 0;
  top: -8px;
  color: #c2c2c2;
}

.faq-list .the-active .faq-heading::before {
  content: '-';
}

.faq-heading {
  position: relative;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.faq-heading:hover {
  color: var(--theme-color);
}

.faq-text {
  display: none;
}

.art-box svg {
  width: 100%;
}
.read {
  color: rgb(100, 100, 100);
  font-size: 16px;
  line-height: 1.5;
  margin-top: 25px;
}
/*single property page*/
.prop-image1 {
  height: 340px;
  object-fit: cover;
  border-radius: 10px;
}
.prop-image2 div,
.prop-image3 div,
.sv_third_col div {
  height: 340px;
  object-fit: cover;
}
.sv_fifth_row .sv_large_size img {
  height: 340px;
  object-fit: cover;
  border-radius: 10px 0 0 10px;
}
#photos {
  height: 400px;
}
#photos div.prop-image2:nth-child(1) div,
.prop-image3 div {
  border-radius: 10px 0 0 10px;
}
#photos div.prop-image2:nth-child(2) div,
#photos .sv_third_col:nth-child(3) div {
  border-radius: 0 10px 10px 0;
}
#photos .sv_fourth_col_img:nth-child(1) div {
  border-radius: 10px 0 0 0;
}
#photos .sv_fourth_col_img:nth-child(2) div {
  border-radius: 0 10px 0 0;
}
#photos .sv_fourth_col_img:nth-child(3) div {
  border-radius: 0 0 0 10px;
}
#photos .sv_fourth_col_img:nth-child(4) div {
  border-radius: 0 0 10px 0;
}
.sv_fifth_row .sv_fifth_col img,
.sv_fifth_row .sv_fifth_col iframe {
  height: 165px;
  object-fit: cover;
}
.sv_fifth_row .sv_fifth_col:nth-child(4) img {
  border-radius: 0 10px 0 0;
}
#photos iframe {
  width: 100%;
}
.sv_fifth_row .sv_fifth_col:nth-child(5) img {
  border-radius: 0 0 10px 0;
}
.property-title .svwishlist:hover,
.share-option:hover,
#show-price:hover {
  background: rgb(247, 247, 247);
  border-radius: 5px;
}
.property-title .svwishlist,
.share-option {
  padding: 5px 15px;
  cursor: pointer;
}
.modal .close {
  outline: 0;
}
#share .close span {
  font-size: 3rem;
}
#share .modal-body {
  padding: 50px 10px;
}

#myTab .nav-link,
#myTab .nav-link.active {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

#languageModalCenter .modal-dialog,
#languageModalCenter .modal-content {
  /* 80% of window height */
  height: 80%;
}

.language-modal {
  /* 100% = dialog height, 120px = header + footer */
  max-height: calc(100% - 120px);
  overflow-y: scroll;
}
#myTab .nav-link.active {
  border-bottom: 2px solid #000;
}

.sv_usermenu a {
  color: #fff;
  display: inline-block;
  margin-right: 10px;
  font-weight: 500;
}
.sv_usermenu .active-sidebar {
  background: #fff;
  color: #4f02a4;
  border: 0 !important;
}
.sv_usermenu a li:hover {
  color: #4f02a4;
  background: #fff;
}
.small-box {
  border-radius: 1.375rem;
  position: relative;
  display: flex;
  margin-bottom: 20px;
  box-shadow: 0 8px 32px rgb(47 60 74 / 1%), 0 8px 16px rgb(47 60 74 / 2%);
  padding: 15px;
}
#video-list-div .video-delete-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.svtable td {
  font-size: 14px;
}
.svtable th {
  font-size: 14px;
}
.border-success {
  border-color: #4f02a4 !important;
}
.text-danger {
  color: #4f02a4 !important;
}
.send-btn:hover {
  color: #fff !important;
}
.customlisting li a {
  border: 1px solid #4f02a4;
  color: #4f02a4;
  font-size: 14px;
}
.customlisting li a:hover {
  background: #4f02a4;
  border: 1px solid;
}
.swal-button:not([disabled]):hover {
  background-color: #4f02a4 !important;
}
.wishlist #closedid {
  position: absolute;
  top: 10px;
  right: 30px;
  color: #671bbc;
  cursor: pointer;
}
.sv_usermenu {
  background: #4f02a4;
}
.sv_usermenu .dropdown button {
  border: 0;
  background: transparent;
  color: #fff;
  font-size: 14px;
}
.sv_usermenu .dropdown-menu a {
  color: #333;
  float: none;
}
.sv_usermenu .dropdown-menu {
  padding: 0;
  width: 150px;
}
.svicon {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  min-width: 50px;
  max-width: 50px;
  border-radius: 50%;
}
.bg-violet {
  background: #796aee !important;
  color: #fff;
}
.bg-green {
  background: #54e69d !important;
  color: #fff;
}
.bg-orange {
  background: #ffc36d !important;
  color: #fff;
}
.sv_usermenu .dropdown {
  display: inline-block;
}
.slider-selection,
.slider-handle {
  background-color: #4f02a4;
}

input:focus,
select:focus {
  outline: none !important;
  box-shadow: none !important;
}

#booking-price #daterange-btn input,
#booking-price select {
  border: 0;
  cursor: pointer;
  height: 25px;
}
#number_of_guests {
  height: 30px !important;
}
#booking-price #daterange-btn {
  border-bottom: 1px solid #b5b3b3;
}
.border-left-chk {
  border-left: 1px solid #b5b3b3;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  height: 230px;
  object-fit: cover;
  width: 100%;
  position: relative;
  z-index: 1;
}
.mob-overlay {
  position: absolute;
  width: 100%;
  height: 230px;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
.book-price #per_night,
.book-price #per_month {
  line-height: 2.2;
}
.book-price-txt {
  font-size: 2.2rem;
  font-weight: 600;
}
#show-price {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1.5rem !important;
  padding: 0.5rem;
}
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
#listing_select option,
#booking_select option,
#trip_select option {
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid #dee2e6;
  font-size: 14px;
}
#listing_select,
#booking_select,
#trip_select {
  border: 0;
  padding: 0;
  border-top: 1px solid #ced4da;
}
#listing_select:focus,
#booking_select:focus,
#trip_select:focus {
  outline: none;
  box-shadow: none;
}
#booking_select option:last-child,
#listing_select option:last-child,
#trip_select option:last-child,
.sv_profile_nav li:last-child {
  border-bottom: 0;
}
.btn-success:hover {
  color: #fff !important;
}
.sv_profile_nav li {
  border-bottom: 1px solid #dee2e6;
  padding: 8px 15px;
}
#profile_update label,
#profile_update .form-control {
  font-size: 14px;
}
.filter-add-value {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ada7a7;
  text-align: center;
  font-size: 2.3rem;
  cursor: pointer;
}
.filter-add-value:hover {
  border: 1px solid #000;
}
.filter-size input {
  width: 80px;
  border: none;
  text-align: center;
}
.footer-fixed-nav {
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99999;
  border-top: 1px solid #dee2e6;
  padding: 10px 0;
}
.footer-fixed-nav ul {
  padding: 0;
  margin: 0;
}
.footer-fixed-nav ul li {
  float: left;
  width: 20%;
  text-align: center;
}
.footer-fixed-nav ul li i {
  color: #000;
}
.footer-fixed-nav ul li a.active-link i {
  color: #4f02a4;
}

.host-overlay,
.join-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.migrateshop_othernav {
  border-bottom: 1px solid #eee;
}
.badge-color {
  background-color: #4f02a4;
  color: #fff;
  border-radius: 50%;
  padding: 0.25em 0.5em;
}
.rounded-10 {
  border-radius: 10px;
}
.svprofile_pic {
  text-align: center;
  margin: auto;
  display: table;
  border-radius: 50%;
}
.svdashboard .fa-shirtsinbulk {
  font-family: 'Font Awesome 5 Brands';
}
.svbadge {
  border: 1px solid #4f02a4;
}
.footer-fixed-nav a .icon-txt {
  font-size: 1rem;
  font-weight: 600;
}

.footer-fixed-nav a:hover i {
  color: #4f02a4 !important;
}
.footer-fixed-nav a:hover .icon-txt {
  color: #222325 !important;
}
.mybooking .badge,
.svtrips .badge {
  position: absolute;
  top: 0px;
  right: 0px;
}
.btn-danger:hover,
.btn-primary:hover {
  color: #fff !important;
}

.contact-textarea {
  min-height: 125px;
}
.user-login-section {
  margin-top: 87px;
}
.user-login {
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  width: 100%;
}
.user-login-bg {
  /* background: url(../img/login-bg.jpg); */
  width: 100%;
  height: 100%;
  padding-top: 64%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.user-login-content {
  padding: 3% 0;
}
.login-list a {
  padding: 16px 30px 14px;
  font-size: 2rem;
  border-bottom: 1px solid #e2e8f0;
}
.welcome-txt {
  font-size: 3rem;
}
.or-section {
  background-color: #fff;
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 1.25rem;
  position: relative;
  margin: 0;
}
.or-sec:after {
  content: '';
  width: 100%;
  border-bottom: 1px solid #cbd5e0;
  position: absolute;
  left: 0;
  top: 55%;
  z-index: 1;
}
.or-sec {
  height: 2rem;
  margin: 1rem 0;
  text-align: center;
  position: relative;
}

.footer-fixed-nav a > i {
  font-size: 2rem;
}
.act-active {
  border-bottom: 2px solid #000 !important;
}

.contact-page-icon img {
  max-width: 100px;
}

.mobile-side li {
  border-bottom: 1px solid #dce0e0;
  line-height: 60px;
}

.dropzone {
  margin: 0 auto 25px auto;
  padding: 50px 0;
  color: #8d9499;
  cursor: pointer;
  border: 2px dashed #c8cbce;
  transition: box-shadow 0.3s, border-color 0.3s;
  overflow: hidden;
}

.svdoc li {
  position: relative;
}
.svdoc i {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/*Banner carousel*/
.carousel-fade .carousel-inner .item {
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.main-banner .carousel-item:after,
.sv_step_first:after,
.sv_step_three:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
}
.selected-lang:hover {
  background: none !important;
}
.language-modal .nav-link {
  padding: 1.2rem;
}

.lang-modal-header {
  border-bottom: none;
}
.property-title .no-img-pull-right {
  margin-top: -12px;
}
.sv_step_first div,
.sv_step_three div {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  color: #fff;
  z-index: 1;
}
.sv_step_first img {
  min-height: 550px;
  max-height: 100%;
  width: 100%;
  position: relative;
  object-fit: cover;
}
.step-two img,
.step-three img,
.step-six img {
  min-height: 570px;
  height: 100%;
}
.sv_step_three img {
  height: 100%;
  width: 100%;
}
.sv_discount {
  color: #1dbf73;
  font-weight: 600;
}
.sv1 {
  display: none;
}

.search-no-result {
  width: 330px !important;
  text-align: left !important;
}
.search-no-res-list {
  list-style-type: disc;
  margin-left: 20px;
}
.search-no-result h4 {
  text-align: left !important;
}

.homenav .mob-back-btn {
  display: none !important;
}

#chk-availability {
  float: right;
  margin-top: 1px;
}
.mcount {
  position: absolute;
  right: 7px;
}

#ui-datepicker-div {
  margin-top: 12%;
}

select {
  -webkit-appearance: none !important;
  appearance: none !important;
  /* background: url('../img/select-arrow.png') !important; */
  background-repeat: no-repeat !important;
  background-position-x: 97% !important;
  background-position-y: 50% !important;
  background-size: 12px !important;
}

#check_availability:hover {
  color: #fff !important;
}
.globe,
.globe:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.addi-guest #price-select-guests_included {
  width: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari only override */
  ::i-block-chrome,
  #listing_select option {
    height: 30px !important;
    text-indent: 12px;
  }
  ::i-block-chrome,
  #booking_select option {
    height: 30px !important;
    text-indent: 12px;
  }
  ::i-block-chrome,
  #trip_select option {
    height: 30px !important;
    text-indent: 12px;
  }
}

@media (min-width: 1025px) {
  .price-card {
    position: absolute;
    right: 0;
  }
}

/*!
 * Bootstrap v4.5.2 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #4f02a4;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #4f02a4;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}
*,
::after,
::before {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #4f02a4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[role='button'] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
}
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: 2.5rem;
}
.h2,
h2 {
  font-size: 2rem;
}
.h3,
h3 {
  font-size: 1.75rem;
}
.h4,
h4 {
  font-size: 1.5rem;
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: '\2014\00A0';
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table-sm td,
.table-sm th {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table-borderless tbody + tbody,
.table-borderless td,
.table-borderless th,
.table-borderless thead th {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > td,
.table-primary > th {
  background-color: #b8daff;
}
.table-primary tbody + tbody,
.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: #7abaff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > td,
.table-secondary > th {
  background-color: #d6d8db;
}
.table-secondary tbody + tbody,
.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: #b3b7bb;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > td,
.table-success > th {
  background-color: #c3e6cb;
}
.table-success tbody + tbody,
.table-success td,
.table-success th,
.table-success thead th {
  border-color: #8fd19e;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > td,
.table-info > th {
  background-color: #bee5eb;
}
.table-info tbody + tbody,
.table-info td,
.table-info th,
.table-info thead th {
  border-color: #86cfda;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > td,
.table-warning > th {
  background-color: #ffeeba;
}
.table-warning tbody + tbody,
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: #ffdf7e;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > td,
.table-danger > th {
  background-color: #f5c6cb;
}
.table-danger tbody + tbody,
.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: #ed969e;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > td,
.table-light > th {
  background-color: #fdfdfe;
}
.table-light tbody + tbody,
.table-light td,
.table-light th,
.table-light thead th {
  border-color: #fbfcfc;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > td,
.table-dark > th {
  background-color: #c6c8ca;
}
.table-dark tbody + tbody,
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #95999c;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
input[type='date'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control,
input[type='time'].form-control {
  -webkit-appearance: none;
  appearance: none;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[multiple],
select.form-control[size] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-valid,
.was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip,
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}
.custom-control-input.is-valid ~ .custom-control-label::before,
.was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #28a745;
}
.custom-file-input.is-valid ~ .custom-file-label,
.was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}
.custom-file-input.is-valid:focus ~ .custom-file-label,
.was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem)
      calc(0.75em + 0.375rem);
}
.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-control-input.is-invalid:checked ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #dc3545;
}
.custom-file-input.is-invalid ~ .custom-file-label,
.was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label,
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .custom-select,
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
}
.btn-primary:hover {
  color: #fff;
  background-color: #4f02a4;
  border: 0;
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #4f02a4;
  border: 0;
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-outline-primary {
  color: #4f02a4;
  border-color: #4f02a4;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #4f02a4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-decoration: none;
}
.btn-link:hover {
  color: rgba(0, 0, 0, 1);
  text-decoration: underline;
}
.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type='button'].btn-block,
input[type='reset'].btn-block,
input[type='submit'].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}
.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 30px;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0.5rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-left {
  right: auto;
  left: 0;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='top'] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #111112c4;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group-vertical > .btn,
.btn-group > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group-vertical > .btn:hover,
.btn-group > .btn:hover {
  z-index: 1;
}
.btn-group-vertical > .btn.active,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn:focus,
.btn-group > .btn.active,
.btn-group > .btn:active,
.btn-group > .btn:focus {
  z-index: 1;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn-group:not(:first-child),
.btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .custom-file,
.input-group > .custom-select,
.input-group > .form-control,
.input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .custom-file + .custom-file,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .form-control,
.input-group > .custom-select + .custom-file,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .form-control,
.input-group > .form-control + .custom-file,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .form-control,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label,
.input-group > .custom-select:focus,
.input-group > .form-control:focus {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .custom-select:not(:last-child),
.input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-select:not(:first-child),
.input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group-append .btn:focus,
.input-group-prepend .btn:focus {
  z-index: 3;
}
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .input-group-text,
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type='checkbox'],
.input-group-text input[type='radio'] {
  margin-top: 0;
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 2px);
}
.input-group-lg > .custom-select,
.input-group-lg > .form-control,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control:not(textarea) {
  height: calc(1.5em + 0.5rem + 2px);
}
.input-group-sm > .custom-select,
.input-group-sm > .form-control,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child),
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child),
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4f02a4;
  background-color: #4f02a4;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label,
.custom-control-input[disabled] ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before,
.custom-control-input[disabled] ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: '';
  background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #4f02a4;
  background-color: #4f02a4;
}
.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-transform 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem);
}
.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:disabled ~ .custom-file-label,
.custom-file-input[disabled] ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4f02a4;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4f02a4;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #4f02a4;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus,
.nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4f02a4;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:focus,
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
  color: #fff;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
  text-align: justify;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}
.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header,
  .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer,
  .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header,
  .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer,
  .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item {
  display: flex;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: '/';
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #4f02a4;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:focus,
a.badge:hover {
  text-decoration: none;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #4f02a4;
}
a.badge-primary:focus,
a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary.focus,
a.badge-primary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus,
a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary.focus,
a.badge-secondary:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:focus,
a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success.focus,
a.badge-success:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:focus,
a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info.focus,
a.badge-info:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:focus,
a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning.focus,
a.badge-warning:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:focus,
a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger.focus,
a.badge-danger:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:focus,
a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light.focus,
a.badge-light:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:focus,
a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark.focus,
a.badge-dark:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4f02a4;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:focus,
.list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #4f02a4;
  border-color: #4f02a4;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}
.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:focus,
.list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:focus,
.list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:focus,
.list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:focus,
.list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:focus,
.list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:focus,
.list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:focus,
.list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:focus,
.list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}
a.close.disabled {
  pointer-events: none;
}
.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}
.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-body {
  padding: 0.75rem;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
  transform: scale(1.02);
}
.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-footer,
.modal-dialog-scrollable .modal-header {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: -webkit-min-content;
  height: min-content;
  content: '';
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: -webkit-min-content;
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-auto[x-placement^='top'],
.bs-tooltip-top {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow,
.bs-tooltip-top .arrow {
  bottom: 0;
}
.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-auto[x-placement^='right'],
.bs-tooltip-right {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-auto[x-placement^='bottom'],
.bs-tooltip-bottom {
  padding: 0.4rem 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow,
.bs-tooltip-bottom .arrow {
  top: 0;
}
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-auto[x-placement^='left'],
.bs-tooltip-left {
  padding: 0 0.4rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow,
.bs-tooltip-left .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::after,
.popover .arrow::before {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}
.bs-popover-auto[x-placement^='top'],
.bs-popover-top {
  margin-bottom: 0.5rem;
}
.bs-popover-auto[x-placement^='top'] > .arrow,
.bs-popover-top > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^='top'] > .arrow::before,
.bs-popover-top > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='top'] > .arrow::after,
.bs-popover-top > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}
.bs-popover-auto[x-placement^='right'],
.bs-popover-right {
  margin-left: 0.5rem;
}
.bs-popover-auto[x-placement^='right'] > .arrow,
.bs-popover-right > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^='right'] > .arrow::before,
.bs-popover-right > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='right'] > .arrow::after,
.bs-popover-right > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}
.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 0.5rem;
}
.bs-popover-auto[x-placement^='bottom'] > .arrow,
.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-auto[x-placement^='bottom'] > .arrow::before,
.bs-popover-bottom > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='bottom'] > .arrow::after,
.bs-popover-bottom > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-auto[x-placement^='bottom'] .popover-header::before,
.bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-auto[x-placement^='left'],
.bs-popover-left {
  margin-right: 0.5rem;
}
.bs-popover-auto[x-placement^='left'] > .arrow,
.bs-popover-left > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-auto[x-placement^='left'] > .arrow::before,
.bs-popover-left > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-auto[x-placement^='left'] > .arrow::after,
.bs-popover-left > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-right,
.carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.active.carousel-item-left,
.carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50%/100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}
.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #4f02a4 !important;
}
a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:focus,
a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #4f02a4 !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded-sm {
  border-radius: 0.2rem !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-lg {
  border-radius: 0.3rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: '';
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: '';
}
.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}
.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}
.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}
.text-justify {
  text-align: justify !important;
}
.text-wrap {
  white-space: normal !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-weight-bolder {
  font-weight: bolder !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #4f02a4 !important;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #0056b3 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:focus,
a.text-secondary:hover {
  color: #494f54 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:focus,
a.text-success:hover {
  color: #19692c !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:focus,
a.text-info:hover {
  color: #0f6674 !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:focus,
a.text-warning:hover {
  color: #ba8b00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:focus,
a.text-danger:hover {
  color: #a71d2a !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:focus,
a.text-light:hover {
  color: #cbd3da !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:focus,
a.text-dark:hover {
  color: #121416 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-decoration-none {
  text-decoration: none !important;
}
.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}
.text-reset {
  color: inherit !important;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  ::after,
  ::before {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  blockquote,
  pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  img,
  tr {
    page-break-inside: avoid;
  }
  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered td,
  .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark tbody + tbody,
  .table-dark td,
  .table-dark th,
  .table-dark thead th {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/* TODO: either remove global colors at all or update all used colors to use them */

:root {
  font-size: 14px;
  --main-text-color: rgba(0, 0, 0, 0.6);
  --main-text-hover: rgba(0, 0, 0, 1);
  --main-text-active: rgba(255, 255, 255, 1);
  --main-text-active-background: rgba(37, 34, 49, 0.9);
  --label-text-color: rgba(51, 51, 51, 0.87);
  --hightlight-text-color: #363b3d;
  --info-text-color: #939ea8;
  --additional-info-color: #566879;
  --cta-color: #007db5;
  --cta-color-active: #00aff5;
  --icon-color: #4281d1;
  --link-color: #007db5;
  --button-color: #2d74cc;
  --button-color-light: rgba(0, 0, 0, 0);
  --button-color-light-hover: #eeeeee;
  --border-color: #e9eaec;
  --input-border-color: #e6e6e6;
  --select-color: #56697a;
  --popover-text-color: #5d687a;
  --background-dark-color: #fcfcfc;
  --background-light-color: #ffffff;

  --rangepicker-weekday-caption: #333333;
  --rangepicker-cell-end: #4f02a4;
  --rangepicker-cell-end-text: rgba(255, 255, 255, 0.8);
  --rangepicker-cell-span: #a593c8;
  --rangepicker-cell-disabled: #eeeeee;
  --rangepicker-cell-disabled-text: #b2b2b2;
}

html:focus-within {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  color: rgba(0, 0, 0, 0.6);
  color: var(--main-text-color);
  margin: 0;
  font-family: Roboto, sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 0.27px;
}

h1 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 96px;
  line-height: 96px;
  font-weight: 300;
  text-align: left;
  letter-spacing: -1.5px;
}

h2 {
  margin-top: 16px;
  margin-bottom: 32px;
  opacity: 1;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 60px;
  line-height: 56px;
  font-weight: 300;
  letter-spacing: -0.5px;
}

h3 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(51, 51, 51, 0.87);
  font-size: 48px;
  line-height: 40px;
  font-weight: 400;
}

h4 {
  max-width: 770px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(73, 73, 73, 0.87);
  font-size: 34px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: 0.25px;
}

h5 {
  margin-top: 8px;
  margin-bottom: 16px;
  font-family: Roboto, sans-serif;
  color: rgba(73, 73, 73, 0.87);
  font-size: 24px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -1px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: 1;
  font-family: Roboto, sans-serif;
  color: #494949;
  font-size: 20px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-transform: none;
}

p {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 28px;
}

a {
  display: inline;
  color: #007db5;
  line-height: 24px;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  margin-top: 0px;
  margin-bottom: 8px;
  padding-left: 36px;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-family: Roboto, sans-serif;
  color: #333;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}

blockquote {
  margin-bottom: 16px;
  padding: 16px;
  border-left: 4px solid #00aff5;
  font-family: Montserrat, sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.modal-open .modal {
  padding: 0 !important;
}

.gallery-figcaption
  .gallery-figcaption--thumbnails
  .caption-thumbnails
  ul.thumbnails-list
  li
  button.thumbnail-button {
  border: 0;
}

.header_area .navbar-brand {
  margin-right: 0 !important;
}

.material_select_hiddenSelect__2Agso {
  display: none !important;
}
.material_select_hiddenSelect__1wQ__ {
  display: none !important;
}

.material_select_hiddenSelect__1wQ__ {
  display: none !important;
}
.br-50 {
  border-radius: 50px !important;
}

@media (max-width: 991px) {
  .header_area .navbar {
    background: transparent !important;
  }

  .navbar-toggler {
    background: transparent !important;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 64px;
    line-height: 60px;
    font-weight: 400;
  }
  h2 {
    font-size: 52px;
    line-height: 30px;
  }
  h3 {
    font-size: 32px;
    line-height: 32px;
  }
  h4 {
    font-size: 28px;
    line-height: 28px;
  }
}

@media screen and (max-width: 600px) {
  /* .CalendarMonthGrid */
  /* .CalendarMonthGrid_1 */
  /* .CalendarMonthGrid__horizontal  */
  .DayPicker_portal__horizontal_3,
  .DayPicker__withBorder_24 {
    margin-top: -300px !important;
    margin-left: -160px !important;
  }

  .DayPicker_portal__horizontal_3 div:first-child {
    /* margin-top: -350px !important; */
    /* margin-left: -150px !important; */
    width: 310px !important;
    padding-right: 50px;
  }

  /* .DayPicker_weekHeaders */
  .DayPicker_weekHeaders__horizontal_2 div:nth-child(2) {
    margin-left: 500px !important;
  }

  /* .DayPicker_portal__horizontal_3 div:first-child */

  .DayPicker_transitionContainer__horizontal_2 {
    width: 300px !important;
    height: 800px !important;
  }

  .DayPicker_transitionContainer__horizontal_2 div {
    width: 300px !important;
  }

  .CalendarMonthGrid__horizontal_2 {
    display: flex !important;
    flex-direction: column !important;
  }
}

